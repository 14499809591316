import React from 'react';
import t, {getCurrentLangValue} from "../../../utils/translate";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import {getDateForDateTime, getStringForDateTime} from "../../../utils/dateTimeHelper";
import {Icon} from "semantic-ui-react";

export default function DateTimeFormInput({name, value, column, onChangeFieldValue, disabled}) {
    let currentLangValue = getCurrentLangValue();
    if(currentLangValue === 'ru')
        registerLocale("ru", ru);
    else
        registerLocale("en", en);
    
    let date = value.split(' ')[0];
    let time = value.split(' ')[1];
    
    return <div className={'date-picker-container'}>
        <DatePicker
        placeholderText={column.example}
        disabled={disabled}
        isClearable={!disabled}
        showTimeSelect
        dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy HH:mm" : "MMMM d, yyyy h:mm aa"}
        timeFormat={currentLangValue === 'ru' ? "HH:mm" : "h:mm aa"}
        timeCaption={t('timeHeader')}
        locale={currentLangValue}
        selected={value === null || value === undefined || value === '' ?  null : getDateForDateTime(value)}
        onChange={date => {
            if(date == null){
                onChangeFieldValue(name, null);
            }
            else{
                let s = getStringForDateTime(date);
                onChangeFieldValue(name, s);
            }
        }}
    />
        <Icon name={'calendar alternate outline'} />
    </div>;
}