import React, {useEffect, useState} from 'react';
import api from "../../../../../../../../utils/api";
import {Button, Form, FormDropdown, Modal} from "semantic-ui-react";
import {toast} from "react-toastify";

export default function PermissionChangerModal({page, open, setOpen, onChange}) {
    const [roles, setRoles] = useState([])
    const [viewPermissions, setViewPermissions] = useState([])
    const [editPermissions, setEditPermissions] = useState([])
    
    useEffect(()=>{
        if(open){
            api()
                .getPermissionsForKnowledgeFolder(page.id)
                .then(resp => {
                    setViewPermissions(resp.data.view)
                    setEditPermissions(resp.data.edit)
                    setRoles(resp.data.roles.map(role => {return {value: role.roleId, text: role.roleNameRu, key: role.roleId}}))
                })
            
        }
    }, [open])
    
    const save = () => {
        api()
            .setPermissionsForKnowledgeFolder(page.id, viewPermissions, editPermissions)
            .then(resp => {
                setOpen(false)
                toast.info('Новые ограничения на папку установлены!')
                onChange()
            })
    }
    return <Modal
        open={open}
        onClose={() => setOpen(false)}
        size={"mini"}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
    >
        <Modal.Header>Разрешения на папку {page.name}</Modal.Header>
        <Modal.Content>
            <Form>
                <FormDropdown 
                    label={'Просмотр'} 
                    value={viewPermissions} 
                    onChange={(e, data)=>{setViewPermissions(data.value)}} 
                    options={roles} fluid multiple selection/>
                <FormDropdown 
                    label={'Редактирование'} 
                    value={editPermissions}
                    onChange={(e, data)=>{setEditPermissions(data.value)}}
                    options={roles} fluid multiple selection/>
            </Form>
            {JSON.stringify()}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Отмена
            </Button>
            <Button onClick={() => {setOpen(false);save()}} primary>
                Сохранить
            </Button>
        </Modal.Actions>
    </Modal>;
}