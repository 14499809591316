import React from "react";
import appConfig from "./appConfig";

export function calculateExpression(exp, dictionaryItemMetaDescription, formData, data) {
    let initExp = exp;
    for (var i = 0;i < appConfig().enums?.length ?? 0; i++){
        for (var j = 0;j < appConfig().enums[i].values.length; j++){
            exp = exp.replaceAll(`"${appConfig().enums[i].nameSingle}"."${appConfig().enums[i].values[j].nameSingle}"`, `"${appConfig().enums[i].values[j].nameSingle}"`);
        }
    }
    
    if(window.user !== undefined)
    {
        //debugger;
        for (var i in window.user) {
            let valueForReplace = window.user[i]
            if(JSON.stringify(valueForReplace) == '{}')
                valueForReplace = 'false'
            exp = exp.replaceAll(`##user.${i}##`, (valueForReplace == null ? '' : valueForReplace).toString());
        }
    }
    let id = null;
    let formDataId = formData !== undefined ? formData.filter(fd => fd.name === 'Id')[0]?.value ?? null : null;
    let dataId = data !== undefined && data !== null && data.Id !== undefined ? data.Id : null;
    id = formDataId !== null ? formDataId : dataId !== null ? dataId : null;
    exp = exp.replaceAll('"Id"', id !== undefined && id !== null  ? ("'" + id + "'") : 'null');

    const getValue = (value) => {
        if(value != undefined && value != null && value.id != undefined && value.id != null)
            return value.id
        return value
    } 
    if(data !== undefined)
        for(let a in data) {
            exp = exp.replaceAll("\"" + a + "\"", (data[a] == null ? 'null' : (data[a] === true || data[a] === false) ? data[a].toString().toLowerCase() : "\"" + getValue(data[a]) + "\""));
        }
    
    if(dictionaryItemMetaDescription !== undefined)
    dictionaryItemMetaDescription.columns.map(x =>
        {
            var fdi = formData.filter(fd => fd.name === x.name)[0];
            let value = fdi?.value;
            
            if(x.type == 'innerTable' && value != undefined) {
                value = JSON.stringify(value);
                //value = value.replaceAll('"', '\\"');
                exp = exp.replaceAll("\"" + x.name + "\"", (value == null ? 'null' : value));
            }else{
                if(value !== undefined && value !== null && value.replaceAll !== undefined)
                    value = value.replaceAll('"', '\\"');
                
                exp = exp.replaceAll("\"" + x.name + "\"", (value == null ? 'null' : (value === true || value === false) ? value.toString().toLowerCase() : "\"" + getValue(value) + "\""));
            }
            
            //exp = exp.replaceAll(x.name, value ?? 'null');
        }
    );
    

    let result = false;

    try {
        result = eval(exp);
    }catch (e) {
        console.log('calculateExpression ERROR');
        console.log(e);
    }
    console.log("--------------");
    console.log("initExp");
    console.log(initExp);
    console.log("exp");
    console.log(exp);
    console.log("result");
    console.log(result);
    return result;
}