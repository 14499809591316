import {
    Button, ButtonGroup,
    Checkbox,
    Dropdown,
    Form,
    FormField,
    Grid, GridColumn,
    GridRow,
    Icon,
    Input, Message,
    Popup, Radio
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import './FilterForm.css'
import t, {getCurrentLangValue} from "../../../../utils/translate";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
    getDateForDate,
    getStringForDate,
    isDateString
} from "../../../../utils/dateTimeHelper";
import moment from "moment/moment";
import api from "../../../../utils/api";
import NumberInput from "semantic-ui-react-numberinput";
import appConfig from "../../../../utils/appConfig";

export function FilterForm({column, 
                                         dictionary,
                                         view, 
                                         globalFilterColumn,
                                         currentSort,
                                         setCurrentSort,
                                         openedFilter,
                                         setOpenedFilter,
                                         currentFilter,
                                         setCurrentFilter
                                     }) {
    let [dateSelectType, setDateSelectType] = useState('between-date');
    let [textSelectType, setTextSelectType] = useState('contains');
    let [forDateSelectType2, setForDateSelectType2] = useState('date-select');
    let [forDateSelectTypeForBetween, setForDateSelectTypeForBetween] = useState('dates-select');
    let currentLangValue = getCurrentLangValue();
    let value = '';
    let canEmpty = '';
    let type = null;
    if(currentFilter.filter(x=>x.name === column.name).length > 0) {
        value = currentFilter.filter(x=>x.name === column.name)[0].value;
        canEmpty = currentFilter.filter(x=>x.name === column.name)[0].canEmpty;
        type = currentFilter.filter(x=>x.name === column.name)[0].type;
    }
    let [tempValue, setTempValue] = useState(value);
    let [tempCanEmpty, setTempCanEmpty] = useState(canEmpty);
    let [optionsForLinkToDictionary, setOptionsForLinkToDictionary] = useState([]);
    let innerForm = null;
    useEffect(()=>{
        if(column.name === 'CreateAt' || column.type === 'dateTime'){
            if(value !== '' && value.replace('<->', '') !== ''){
                if(value.indexOf('<->') !== -1){
                    setDateSelectType('between-date');
                    setForDateSelectTypeForBetween('dates-select');
                }else{
                    switch (value) {
                        case "last-week":
                            setDateSelectType('between-date');
                            setForDateSelectTypeForBetween('last-week');
                            break;
                        case "this-week":
                            setDateSelectType('between-date');
                            setForDateSelectTypeForBetween('this-week');
                            break;
                        case "next-week":
                            setDateSelectType('between-date');
                            setForDateSelectTypeForBetween('next-week');
                            break;

                        case "yesterday":
                            setDateSelectType('target-date');
                            setForDateSelectType2('yesterday');
                            break;
                        case "today":
                            setDateSelectType('target-date');
                            setForDateSelectType2('today');
                            break;
                        case "tomorrow":
                            setDateSelectType('target-date');
                            setForDateSelectType2('tomorrow');
                            break;
                        default:
                            setDateSelectType('target-date');
                            setForDateSelectType2('date-select');
                            break;
                    }
                }
            }
        }
        
        if(openedFilter && column.type === 'linkToDictionary') {
            api()
                .getFieldValuesForView(dictionary.strictNameMany, 0, [], view.viewName, '', '', column.name)
                .then(resp=>{
                    setOptionsForLinkToDictionary(resp.rows.map(x=> ({
                        value: x.id,
                        text: x.name,
                    })));
                });
        }
    }, [openedFilter]);
    if(column.name == 'Id')
        innerForm = <Form>
            <FormField>
                <Input value={tempValue} onChange={(e)=>{
                    setTempValue(e.target.value);
                }} placeholder={t('Identifier')} icon={'search'} />
            </FormField>
        </Form>;

    let checkboxCanEmpty = <Checkbox
        label={t('not_set')}
        checked={tempCanEmpty}
        onChange={()=>{
            setTempCanEmpty(!tempCanEmpty);
        }}
    />;
    let tags = [
        '-contains-',
        '-not-contains-',
        '-starts-with-',
        '-ends-with-',
        '-equal-',
    ];
    let getTagFromTempValue = (text)=>{
        for (let i = 0; i < tags.length; i++){
            if(text.startsWith(tags[i]))
                return tags[i];
        }
        return '-contains-';
    }
    let getTextFromTempValue = (text)=>{
        for (let i = 0; i < tags.length; i++){
            if(text.startsWith(tags[i]))
                text = text.replace(tags[i], '');
        }
        return text;
    };
    
    if(column.type === 'linkToDictionary'){
        let dictionaryType = appConfig().dictionaries.filter(x=>x.strictNameMany === column.linkTo)[0];
        let valForArray = JSON.parse(tempValue == '' ? '[]' : tempValue);
        if(valForArray.length > 0)
            valForArray = valForArray.map(x=> {
                if (x.id !== undefined && x.id !== null && x.id !== '')
                    return x.id;
                return x;
            });
        
        innerForm = <Form style={{maxWidth: '330px', minWidth: '330px'}}>
            <FormField>
                <Dropdown
                    placeholder={t('select_##DICTIONARY_NAME##_from_list').replace('##DICTIONARY_NAME##', 
                        currentLangValue == 'ru' ? dictionaryType.ruNameMany : dictionaryType.nameMany)}
                    multiple
                    selection
                    search
                    clearable
                    noResultsMessage={t('No results found.')}
                    options={optionsForLinkToDictionary}
                    onChange={(e, data) => {
                        console.log("!!! data.value");
                        console.log(data.value);
                        if(e.stopPropagation !== undefined) {e.stopPropagation();}
                        setTempValue(data.value.length > 0 ? JSON.stringify(data.value) : '');
                    }}
                    value={valForArray}
                />
            </FormField>
            {checkboxCanEmpty}
        </Form>;
    }
    if(column.type === 'linkToEnum'){
        let enumType = appConfig().enums.filter(x=>x.nameSingle === column.linkTo)[0]
        innerForm = <Form>
            {enumType.values.map(val => {
                let checked = tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1;
                let onChange = (e, data) => {
                    if (data.checked) {
                        if (tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1) {
                            let localTemp = tempValue;
                            localTemp = JSON.parse(localTemp);
                            localTemp = localTemp.filter(x => x !== val.nameSingle);
                            if (localTemp.length === 0) {
                                localTemp = null;
                            } else {
                                localTemp = JSON.stringify(localTemp);
                            }
                            setTempValue(localTemp);
                        } else {
                            if (tempValue === undefined || tempValue === null || tempValue === '') {
                                setTempValue(JSON.stringify([val.nameSingle]));
                            } else {
                                let arr = JSON.parse(tempValue);
                                arr.push(val.nameSingle);
                                setTempValue(JSON.stringify(arr));
                            }
                        }
                    } else {
                        if (tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1) {
                            let localTemp = tempValue;
                            localTemp = JSON.parse(localTemp);
                            localTemp = localTemp.filter(x => x !== val.nameSingle);
                            if (localTemp.length === 0) {
                                localTemp = '';
                            } else {
                                localTemp = JSON.stringify(localTemp);
                            }
                            setTempValue(localTemp);
                        } else {
                            setTempValue(tempValue);
                        }
                    }
                };
                return <div style={{marginTop: '10px'}}>
                    <div style={{position: 'relative'}}
                         onClick={() => {
                             onChange({}, {checked: !checked});
                         }}
                    >
                        <Checkbox
                            //label={}
                            checked={checked}
                            onChange={onChange}
                        />
                        <label
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                display: 'inline-block',
                                verticalAlign: 'top',
                                marginLeft: '10px'
                            }}>{(currentLangValue === 'ru' ? val.ruNameSingle : val.nameSingle)} {val.color !== undefined ?
                            <Icon name={'circle'} color={val.color}/> : ''}</label>
                    </div>
                </div>;
            })}
            <hr/>
            {checkboxCanEmpty}
        </Form>;
    }
    if(column.type === 'arrayLinkToEnum') {
        let enumType = appConfig().enums.filter(x=>x.nameSingle === column.linkTo)[0]
        innerForm = <Form>
            <ButtonGroup size={"mini"}>
                <Popup 
                    content={'Искомые строки должны содержать все выбранные значения и возможно другие'} 
                    trigger={<Button active={true}>Содержит</Button>} basic inverted/>
                <Popup
                    content={'Искомые строки должны содержать только выбранные значения'}
                    trigger={<Button active={false}>Совпадает</Button>} basic inverted/>
                <Popup
                    content={'Искомые строки должны содержать одно из выбранных значения'}
                    trigger={<Button active={false}>Один из</Button>} basic inverted/>
            </ButtonGroup>
            {enumType.values.map(val => {
                let checked = tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1;
                let onChange = (e, data) => {
                    if (data.checked) {
                        if (tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1) {
                            let localTemp = tempValue;
                            localTemp = JSON.parse(localTemp);
                            localTemp = localTemp.filter(x => x !== val.nameSingle);
                            if (localTemp.length === 0) {
                                localTemp = null;
                            } else {
                                localTemp = JSON.stringify(localTemp);
                            }
                            setTempValue(localTemp);
                        } else {
                            if (tempValue === undefined || tempValue === null || tempValue === '') {
                                setTempValue(JSON.stringify([val.nameSingle]));
                            } else {
                                let arr = JSON.parse(tempValue);
                                arr.push(val.nameSingle);
                                setTempValue(JSON.stringify(arr));
                            }
                        }
                    } else {
                        if (tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue.indexOf(`"${val.nameSingle}"`) !== -1) {
                            let localTemp = tempValue;
                            localTemp = JSON.parse(localTemp);
                            localTemp = localTemp.filter(x => x !== val.nameSingle);
                            if (localTemp.length === 0) {
                                localTemp = '';
                            } else {
                                localTemp = JSON.stringify(localTemp);
                            }
                            setTempValue(localTemp);
                        } else {
                            setTempValue(tempValue);
                        }
                    }
                };
                return <div style={{marginTop: '10px'}}>
                    <div style={{position: 'relative'}}
                         onClick={() => {
                             onChange({}, {checked: !checked});
                         }}
                    >
                        <Checkbox
                            //label={}
                            checked={checked}
                            onChange={onChange}
                        />
                        <label
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                display: 'inline-block',
                                verticalAlign: 'top',
                                marginLeft: '10px'
                            }}>{(currentLangValue === 'ru' ? val.ruNameSingle : val.nameSingle)} {val.color !== undefined ?
                            <Icon name={'circle'} color={val.color}/> : ''}</label>
                    </div>
                </div>;
            })}
            <hr/>
            {checkboxCanEmpty}
        </Form>;
    }
    if(column.type === 'bool'){
        const onChange = (e, { value })=>{
            setTempValue(value)
        }
        const getValueFromTempValue = (tempValue, val) => {
            if(tempValue !== undefined && tempValue !== null && tempValue.indexOf !== undefined && tempValue !== ''){
                return tempValue === val
            }
            return false
        }
        innerForm = <Form>
            <FormField>
                {currentLangValue === 'ru' ? 'Выберите значение поля для поиска' : 'Select the value of the field to search for'}:
            </FormField>
            <FormField>
                <Radio
                    label={currentLangValue === 'ru' ? 'Установлено' : 'Is set'}
                    name={column.name + 'radioGroup'}
                    value='true'
                    checked={getValueFromTempValue(tempValue, 'true')}
                    onChange={onChange}
                />
            </FormField>
            <FormField>
                <Radio
                    label={currentLangValue === 'ru' ? 'Не установлено' : 'Is not set'}
                    name={column.name + 'radioGroup'}
                    value='false'
                    checked={getValueFromTempValue(tempValue, 'false')}
                    onChange={onChange}
                />
            </FormField>
        </Form>
    }
    if(column.type === 'string'|| column.type === 'text'){
        innerForm = <Form>
            <div>
                <FormField>
                    <ButtonGroup size={'mini'}>
                        <Button active={tempValue.startsWith('-contains-') || tempValue === ''} 
                                onClick={()=>{setTempValue('-contains-' + getTextFromTempValue(tempValue));}}>{t('Contains')}</Button>
                        <Button active={tempValue.startsWith('-not-contains-')}
                                onClick={()=>{setTempValue('-not-contains-' + getTextFromTempValue(tempValue));}}>{t('Does not contain')}</Button>
                    </ButtonGroup>
                </FormField>
                <FormField>
                    <ButtonGroup size={'mini'}>
                        <Button active={tempValue.startsWith('-starts-with-')}
                                onClick={()=>{setTempValue('-starts-with-' + getTextFromTempValue(tempValue));}}>{t('Starts with')}</Button>
                        <Button active={tempValue.startsWith('-ends-with-')}
                                onClick={()=>{setTempValue('-ends-with-' + getTextFromTempValue(tempValue));}}>{t('Ends with')}</Button>
                    </ButtonGroup>
                </FormField>
                <FormField>
                    <ButtonGroup size={'mini'}>
                        <Button active={tempValue.startsWith('-equal-')}
                                onClick={()=>{setTempValue('-equal-' + getTextFromTempValue(tempValue));}}>{t('Equal')}</Button>
                    </ButtonGroup>
                </FormField>
                <FormField>
                    <Input
                        value={getTextFromTempValue(tempValue)}
                        onChange={(e)=>{
                            setTempValue(getTagFromTempValue(tempValue) + e.target.value);
                        }}
                        placeholder={t('Enter a search phrase')}
                        icon={'search'} />
                </FormField>
                {checkboxCanEmpty}
            </div>
        </Form>;
    }
    if(column.name === 'CreateAt' || column.type === 'dateTime'){

        innerForm = <Form>
            <div style={{minWidth: column.name !== 'CreateAt' ? '367px' : 'auto'}}>
                <ButtonGroup size={'mini'}>
                    <Button active={dateSelectType === 'between-date'} onClick={()=>{setDateSelectType('between-date'); setTempValue(null);}}>{t('Between dates')}</Button>
                    <Button active={dateSelectType === 'target-date'} onClick={()=>{setDateSelectType('target-date'); setTempValue(null);}}>{t('Exact date')}</Button>
                </ButtonGroup>
                {column.name !== 'CreateAt' ? <div className={'checkbox-can-empty-for-date-time'}>{checkboxCanEmpty}</div> : ''}
            </div>
            {dateSelectType === 'between-date' ? <div  style={{marginTop: '10px'}}>
                <ButtonGroup size={'mini'}>
                    <Button active={forDateSelectTypeForBetween === 'dates-select'} onClick={()=>{setForDateSelectTypeForBetween('dates-select'); setTempValue(null);}}>{t('many_Date selection')}</Button>
                    <Button active={forDateSelectTypeForBetween === 'last-week'} onClick={()=>{setForDateSelectTypeForBetween('last-week'); setTempValue('last-week');}}>{t('Last week')}</Button>
                    <Button active={forDateSelectTypeForBetween === 'this-week'} onClick={()=>{setForDateSelectTypeForBetween('this-week'); setTempValue('this-week');}}>{t('This week')}</Button>
                    <Button active={forDateSelectTypeForBetween === 'next-week'} onClick={()=>{setForDateSelectTypeForBetween('next-week'); setTempValue('next-week');}}>{t('Next week')}</Button>
                </ButtonGroup>
            </div> : ''}
            {dateSelectType === 'target-date' ? <div  style={{marginTop: '10px'}}>
                <ButtonGroup size={'mini'}>
                    <Button active={forDateSelectType2 === 'date-select'} onClick={()=>{setForDateSelectType2('date-select'); setTempValue(null);}}>{t('Date Selection')}</Button>
                    <Button active={forDateSelectType2 === 'yesterday'} onClick={()=>{setForDateSelectType2('yesterday'); setTempValue('yesterday');}}>{t('Yesterday')}</Button>
                    <Button active={forDateSelectType2 === 'today'} onClick={()=>{setForDateSelectType2('today'); setTempValue('today');}}>{t('Today')}</Button>
                    <Button active={forDateSelectType2 === 'tomorrow'} onClick={()=>{setForDateSelectType2('tomorrow'); setTempValue('tomorrow');}}>{t('Tomorrow')}</Button>
                </ButtonGroup>
            </div> : ''}

            {dateSelectType === 'between-date' && forDateSelectTypeForBetween === 'last-week' ? (()=>{
                let forDate = moment()
                    .add(-7, 'day');
                var startOfWeek = forDate.startOf('week').toDate();
                    var endOfWeek   = forDate.endOf('week').toDate();
                    return <Message>
                        <p>с {startOfWeek.toLocaleDateString()} по {endOfWeek.toLocaleDateString()}</p>
                    </Message>;
                })() : ''}

            {dateSelectType === 'between-date' && forDateSelectTypeForBetween === 'this-week' ? (()=>{
                let forDate = moment();
                var startOfWeek = forDate.startOf('week').toDate();
                var endOfWeek   = forDate.endOf('week').toDate();
                return <Message>
                    <p>с {startOfWeek.toLocaleDateString()} по {endOfWeek.toLocaleDateString()}</p>
                </Message>;
            })() : ''}

            {dateSelectType === 'between-date' && forDateSelectTypeForBetween === 'next-week' ? (()=>{
                let forDate = moment()
                    .add(7, 'day');
                var startOfWeek = forDate.startOf('week').toDate();
                var endOfWeek   = forDate.endOf('week').toDate();
                return <Message>
                    <p>{t('since_the')} {startOfWeek.toLocaleDateString()} {t('until')} {endOfWeek.toLocaleDateString()}</p>
                </Message>;
            })() : ''}
            
            {dateSelectType === 'between-date' && forDateSelectTypeForBetween === 'dates-select' ? <Grid style={{marginTop: 'auto'}}>
                <GridRow>
                        <GridColumn width={8} style={{position: 'relative'}}>
                            <Form.Field>
                                <label>{t('From')}</label>
                                <DatePicker
                                    isClearable
                                    showWeekNumbers
                                    fixedHeight
                                    showYearDropdown
                                    showMonthDropdown
                                    dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
                                    timeCaption={t('timeHeader')}
                                    locale={currentLangValue}
                                    selected={tempValue === null || tempValue === undefined || tempValue === '' || (tempValue.replace !== undefined && tempValue.replace('<->', '').trim() === '') || !isDateString(tempValue) ?  null :
                                        getDateForDate(tempValue !== null && tempValue !== undefined && tempValue !== '' && 
                                        tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[0] : tempValue)}
                                    onChange={date => {
                                        if(date == null){
                                            if(tempValue.indexOf('<->') != -1 && tempValue.split('<->')[1] != '')
                                                setTempValue('<->' + tempValue.split('<->')[1]);
                                            else
                                                setTempValue(null);
                                        }
                                        else{
                                            let s = getStringForDate(date);
                                            setTempValue(s + '<->' + (tempValue !== null && tempValue !== undefined && tempValue !== '' && tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[1] : ''));
                                        }
                                    }}
                                />
                            </Form.Field>
                            
                            {(tempValue !== undefined && tempValue != null && (tempValue.startsWith('<->') || tempValue.endsWith('<->')) && tempValue.length > 3) ? 
                                <div className={'date-switcher-container'}>
                                {tempValue.startsWith('<->') && tempValue.length > 3 ? <Icon name={'arrow left'} 
                                                                                             onClick={()=>{
                                                                                                 setTempValue(
                                                                                                     tempValue
                                                                                                         .replace('<->', '') 
                                                                                                     + '<->')}}/> : ''}
                                {tempValue.endsWith('<->') && tempValue.length > 3 ? <Icon name={'arrow right'}
                                                                                           onClick={()=>{
                                                                                               setTempValue(
                                                                                                   '<->' + tempValue
                                                                                                       .replace('<->', ''))}}/> : ''}
                            </div> : ''}
                            </GridColumn>
                        <GridColumn width={8}>
                            <Form.Field>
                            <label>{t('To')}</label>
                            <DatePicker
                                isClearable
                                showWeekNumbers
                                fixedHeight
                                showYearDropdown
                                showMonthDropdown
                                dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
                                timeCaption={t('timeHeader')}
                                locale={currentLangValue}
                                selected={tempValue === null || tempValue === undefined || tempValue === '' || !isDateString(tempValue) ?  null :
                                    getDateForDate(tempValue !== null && tempValue !== undefined && tempValue !== '' &&
                                    tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[1] : tempValue)}
                                onChange={date => {
                                    if(date == null){
                                        if(tempValue.indexOf('<->') != -1 && tempValue.split('<->')[0] != '')
                                            setTempValue(tempValue.split('<->')[0] + '<->');
                                        else
                                        setTempValue(null);
                                    }
                                    else{
                                        let s = getStringForDate(date);
                                        setTempValue((tempValue !== null && tempValue !== undefined && tempValue !== '' && tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[0] : '') + '<->' + s);
                                    }
                                }}
                            />
                        </Form.Field>
                        </GridColumn>
                </GridRow>
            </Grid> : ''}
            {dateSelectType === 'target-date' && forDateSelectType2 === 'date-select' ? <Grid style={{marginTop: 'auto'}}>
                <GridRow>
                    <GridColumn width={16}><Form.Field>
                        <label>{t('Select date')}</label>
                        <DatePicker
                            isClearable
                            showWeekNumbers
                            fixedHeight
                            showYearDropdown
                            showMonthDropdown
                            dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
                            timeCaption={t('timeHeader')}
                            locale={currentLangValue}
                            selected={tempValue === null || tempValue === undefined || tempValue === '' || !isDateString(tempValue) ?  null :
                                getDateForDate(tempValue !== null && tempValue !== undefined && tempValue !== '' &&
                                tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[0] : tempValue)}
                            onChange={date => {
                                if(date == null){
                                    setTempValue(null);
                                }
                                else{
                                    let s = getStringForDate(date);
                                    setTempValue(s);
                                }
                            }}
                        /></Form.Field>
                    </GridColumn>
                </GridRow>
            </Grid> : ''}

            {dateSelectType === 'target-date' && forDateSelectType2 === 'yesterday' ? (()=>{
                let forDate = moment().add(-1, 'day').toDate();
                return <Message>
                    <p>{forDate.toLocaleDateString()}</p>
                </Message>;
            })() : ''}
            {dateSelectType === 'target-date' && forDateSelectType2 === 'today' ? (()=>{
                let forDate = moment().toDate();
                return <Message>
                    <p>{forDate.toLocaleDateString()}</p>
                </Message>;
            })() : ''}
            {dateSelectType === 'target-date' && forDateSelectType2 === 'tomorrow' ? (()=>{
                let forDate = moment().add(1, 'day').toDate();
                return <Message>
                    <p>{forDate.toLocaleDateString()}</p>
                </Message>;
            })() : ''}
        </Form>;
    }
    
    if(column.type === 'number'|| column.type === 'decimal' || column.type === 'int'){
        innerForm = <Form>
            <div>
                <Grid style={{marginBottom: '5px'}}>
                    <GridRow>
                        <GridColumn width={8} style={{position: 'relative'}}>
                            <Form.Field>
                                <NumberInput
                                    allowEmptyValue={true}
                                    buttonPlacement="right"
                                    value={(()=>{
                                        let newVar = tempValue === null || tempValue === undefined || tempValue === '' || (tempValue.replace !== undefined && tempValue.replace('<->', '').trim() === '') ?  null :
                                            (tempValue !== null && tempValue !== undefined && tempValue !== '' &&
                                            tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[0] : tempValue);
                                        return newVar;
                                    })()}
                                    placeholder={t('From')}
                                    onChange={date => {
                                        if(date == null){
                                            if(tempValue.indexOf('<->') != -1 && tempValue.split('<->')[1] != '')
                                                setTempValue('<->' + tempValue.split('<->')[1]);
                                            else
                                                setTempValue(null);
                                        }
                                        else{
                                            let s = date.toString();
                                            setTempValue(s + '<->' + (tempValue !== null && tempValue !== undefined && tempValue !== '' && tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[1] : ''));
                                        }
                                    }} />
                            </Form.Field>

                            {(tempValue !== undefined && tempValue != null && (tempValue.startsWith('<->') || tempValue.endsWith('<->')) && tempValue.length > 3) ?
                                <div className={'date-switcher-container'} style={{top: '7px'}}>
                                    {tempValue.startsWith('<->') && tempValue.length > 3 ? <Icon name={'arrow left'}
                                                                                                 onClick={()=>{
                                                                                                     setTempValue(
                                                                                                         tempValue
                                                                                                             .replace('<->', '')
                                                                                                         + '<->')}}/> : ''}
                                    {tempValue.endsWith('<->') && tempValue.length > 3 ? <Icon name={'arrow right'}
                                                                                               onClick={()=>{
                                                                                                   setTempValue(
                                                                                                       '<->' + tempValue
                                                                                                           .replace('<->', ''))}}/> : ''}
                                </div> : ''}
                        </GridColumn>
                        <GridColumn width={8}>
                            <Form.Field>
                                <NumberInput
                                    allowEmptyValue={true}
                                    buttonPlacement="right"
                                    value={(()=>{
                                        let newVar = tempValue === null || tempValue === undefined || tempValue === '' || (tempValue.replace !== undefined && tempValue.replace('<->', '').trim() === '') ?  null :
                                            (tempValue !== null && tempValue !== undefined && tempValue !== '' &&
                                            tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[1] : tempValue);
                                        return newVar;
                                    })()}
                                    placeholder={t('To')}
                                    onChange={date => {
                                        if(date == null){
                                            if(tempValue.indexOf('<->') != -1 && tempValue.split('<->')[0] != '')
                                                setTempValue(tempValue.split('<->')[0] + '<->');
                                            else
                                                setTempValue(null);
                                        }
                                        else{
                                            let s = date.toString();
                                            setTempValue((tempValue !== null && tempValue !== undefined && tempValue !== '' && tempValue.indexOf('<->') !== -1 ? tempValue.split('<->')[0] : '') + '<->' + s);
                                        }
                                    }} />
                            </Form.Field>
                        </GridColumn>
                    </GridRow>
                </Grid>
                {checkboxCanEmpty}
            </div>
        </Form>;
    }
    if(innerForm == null)
        innerForm = <Form>
            <FormField>
                <Input
                    value={tempValue} 
                    onChange={(e)=>{
                        setTempValue(e.target.value);
                    }}    
                    placeholder={t('Enter a search phrase')} 
                    icon={'search'} />
            </FormField>
            {checkboxCanEmpty}
        </Form>;
    let canClearFilter = (value !== undefined && value !== null && value !== '' && value !== []) ||
        (canEmpty !== undefined && canEmpty !== null && canEmpty);
    return <div className={'column-filter-form'}>
        {innerForm}
        <Button onClick={() => {
            let temp = currentFilter.map(x=>({
                name: x.name,
                value: x.value,
                canEmpty: x.canEmpty,
                type: x.type,
            }));

            let actuallyIsEmpty = (tempValue === undefined || tempValue == null || tempValue === '') && 
                (tempCanEmpty === undefined || tempCanEmpty == null || !tempCanEmpty);
            if(temp.filter(x=>x.name === column.name).length > 0) {
                let filterElement = temp.filter(x=>x.name === column.name)[0];
                if(actuallyIsEmpty){
                    temp = temp.filter(x=>x.name !== column.name);
                }else{
                    filterElement.value = tempValue;
                    filterElement.canEmpty = tempCanEmpty;
                }
            }else{
                if(actuallyIsEmpty){

                }else{
                    temp.push({
                        name: column.name,
                        value: tempValue,
                        canEmpty: tempCanEmpty,
                        type: 'eq'
                    });
                }
            }
            setCurrentFilter(temp);
        }}
                disabled={((value == tempValue || ((value == undefined || value == null || value == '' || getTextFromTempValue(value) == '') && (tempValue == undefined || tempValue == null || tempValue == ''|| getTextFromTempValue(tempValue) == ''))) && canEmpty == tempCanEmpty)}
                style={{marginTop: '15px'}}
        >{t('Apply')}</Button>

        {canClearFilter ? <Popup content={t('Reset the filter for this field')}
                         trigger={<Button onClick={() => {
            let temp = currentFilter.map(x=>({
                name: x.name,
                value: x.value,
                canEmpty: x.canEmpty,
                type: x.type,
            }));

            if(temp.filter(x=>x.name === column.name).length > 0) {
                temp = temp.filter(x=>x.name !== column.name);
            }
            setCurrentFilter(temp);
        }}
         color={'red'}
         disabled={!(canClearFilter)}
         style={{marginTop: '15px'}}
         icon={'filter'}
        />} /> : ''}
    </div>;
}