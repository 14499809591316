import React from 'react';
import './NotFoundPage.css'
import {Container, Icon} from "semantic-ui-react";

export default function NotFoundPage({}) {
    
    return <Container text style={{paddingTop: '100px'}}>
        <div style={{textAlign: 'center', marginBottom: '50px'}}>
            <Icon name={'frown outline'} size={'massive'}/>
            <h2>Эта страница не найдена</h2>
        </div>
        <p>К сожалению, мы не смогли найти страницу, которую вы искали. Возможно, она была удалена, перемещена или вы ввели неверный адрес.</p>
        <p>Не волнуйтесь! Вот несколько вариантов, что делать дальше:</p>
        <ul>
            <li>Проверьте URL на наличие опечаток.</li>
            <li>Вернитесь на <a href={'/knowledgeBase'}>главную страницу</a> и попробуйте найти нужную информацию оттуда.</li>
            <li>Используйте поиск по базе знаний, чтобы найти нужный вам материал.</li>
            <li>Если у вас есть вопросы, не стесняйтесь обратиться к вашему коллеге или администратору системы.</li>
        </ul>
        <p>Спасибо за ваше терпение! Мы стремимся сделать нашу базу знаний более удобной и доступной для всех.</p>
    </Container>;
}