import React from 'react';
import './Intro.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const Intro = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'intro'}>Введение</Header>
        <p>
            Фоновые задачи — это скрипты, написанные на Python, которые выполняются в рамках корпоративного приложения для автоматизации различных процессов. Эти задачи могут запускаться в ответ на определенные события, такие как нажатие кнопок, создание или удаление объектов, а также по расписанию (таймеру). Фоновые задачи имеют доступ к специфическим переменным и объектам приложения, что позволяет им взаимодействовать с основной логикой приложения.
        </p>
        <p>Фоновые задачи доступны для просмостра и редактирвоания <b>Администратору</b> через меню <b>Системные настройки</b>. Администратор может создавать фоновые задачи указывая код задачи контекст её выполнения.</p>
        <CodeExample title={'Простая фоновая задача с выводом'}
                     code={'print(\'Hello, world!\')'}/>
    </div>;
}

export default Intro