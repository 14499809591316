import t from "./translate";

export default function langs() {
    return [
        {
            text: t('Russian'),
            value: "ru",
            flag: "ru"
        },
        {
            text: t('English'),
            value: "en",
            flag: "gb"
        }
    ];
}