import React, {useEffect, useRef, useState} from 'react';
import './KnowledgeBasePage.scss'
import {
    Breadcrumb, BreadcrumbDivider, BreadcrumbSection,
    Button, Divider, Grid, Header, Icon, Input, Loader, Message, Popup
} from "semantic-ui-react";
import api from "../../../../utils/api";
import KnowledgeBasePageEditor from "./components/KnowledgeBasePageEditor/KnowledgeBasePageEditor";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import ViewFolderContent from "./components/ViewFolderContent/ViewFolderContent";
import {DeleteKnowledgeBasePageModal} from "../DeleteKnowledgeBasePageModal/DeleteKnowledgeBasePageModal";
import Comments from "./components/Comments/Comments";
import HistoryModal from "./components/HistoryModal/HistoryModal";
import KnowledgeBasePageMarkdownView from "../KnowledgeBasePageMarkdownView/KnowledgeBasePageMarkdownView";
import ViewRelativeTime from "../../../../shared/components/ViewRelativeTime/ViewRelativeTime";
import getBackedUrl from "../../../../utils/getBackedUrl";
import appConfig from "../../../../utils/appConfig";
import IconPickerFormInput from "../../../../shared/fieldTypes/iconPicker/IconPickerFormInput";
import DownloadPdfBtn from "../DownloadPdfBtn/DownloadPdfBtn";

export default function KnowledgeBasePage({pageName, setLastPageModifyTime}) {
    const [isNotFound, setIsNotFound] = useState(false)
    const [page, setPage] = useState(null)
    const [editedPage, setEditedPage] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [openHistoryModal, setOpenHistoryModal] = useState(false)
    const targetRefForDownloadPdf = useRef();
    const history = useHistory();
    
    const loadCurrentPage = ()=>{
        setIsLoading(true)
        api()
            .getKnowledgeBasePageContent(pageName)
            .then(resp => {
                setIsNotFound(resp.data == null)
                setPage(resp.data)
                setIsEditMode(false)
                setIsLoading(false)
            })
    };
    useEffect(loadCurrentPage, [pageName])
    const save = () => {
        api()
            .saveKnowledgeBasePageContent(pageName, editedPage.content, editedPage.title, editedPage.icon)
            .then(resp=>{
                setIsEditMode(false)
                loadCurrentPage()
                setLastPageModifyTime(new Date())
            })
    }
    
    const deleteThisPage = () => {
        api()
            .deleteKnowledgePage(pageName)
            .then(resp=>{
                if(resp.errorMessage === '' || resp.errorMessage == null)
                    history.push('/knowledgeBase')
                else{
                    toast.error(resp.error)
                }
            })
    }
    
    const addToContentSubscribers = () => {
        api()
            .addToSubscribers(page.id, 'content')
            .then(resp=>{
                loadCurrentPage()
                toast.info('Вы подписались на уведомления о новых публикациях этой старнице')
            })
    }
    
    const removeFromContentSubscribers = () => {
        api()
            .removeFromSubscribers(page.id, 'content')
            .then(resp=>{
                loadCurrentPage()
                toast.info('Вы отписались от уведомлений о новых публикаций этой старнице')
            })
    }
    
    if(isLoading)
        return <Loader active/>
    
    if(isNotFound)
        return <NotFoundPage />

    if(page == null)
        return <Loader active/>
    
    return <div>
        {isEditMode ? <div>
            <div className={'header'} style={{position: 'relative'}}>
                {!page.isFolder ? <IconPickerFormInput
                    value={editedPage.icon ?? 'file'}
                    onChangeFieldValue={(name, val)=>{setEditedPage({...editedPage, icon: val})}}
                    style={{display: 'inline'}}
                /> : ''}
                <Input style={{width: `calc(100% - ${355 - (page.isFolder ? 50 : 0)}px)`}} value={editedPage.title} size={'big'} onChange={e=>setEditedPage({...editedPage, title: e.target.value})} />
                <div style={{position: 'absolute', right: '0px', top: '0px'}}>
                    <Button size={'big'} basic onClick={()=>{setIsEditMode(false)}}>Отменить</Button>
                    <Button size={'big'} primary onClick={save} disabled={(editedPage.title === page.title && editedPage.content === page.content && editedPage.icon === page.icon)}>Сохранить</Button>
                </div>
            </div>
            <Divider />
            {page.isFolder ? <ViewFolderContent pageName={pageName} /> : <div>
                <KnowledgeBasePageEditor page={editedPage} setPage={setEditedPage} />
            </div>}
        </div> : <div className={'page-view'}>
            <div className={'header'}>
                {page.isFolder ? <Icon size={'big'} name={'folder outline'}/> : ''}
                {!page.isFolder && page.icon != null && page.icon != '' ? <Icon size={'big'} name={page.icon}/> : ''}
                <span>
                    <Header as='h1' style={{display: 'inline-block', marginBottom: '0px', verticalAlign: 'middle'}}>{page.title}</Header>
                    {appConfig().info.isKnowledgeBaseAdministrator ? <div style={{display: 'inline-block', marginLeft: '15px'}}>
                        <Popup
                            basic
                            inverted
                            content={'Редактировать эту страницу'}
                            trigger={<Button
                                icon
                                primary
                                size={'mini'}
                                onClick={() => {
                                    setIsEditMode(true)
                                    setEditedPage(page)
                                }}
                            ><Icon name={'pencil'}/></Button>} />
                        {page.canDelete ? <DeleteKnowledgeBasePageModal as={'btn'} onConfirm={deleteThisPage}/> : ''}
                    </div> : ''}
                </span>
                {page.path.length > 0 ? <div className={'path-explorer'}>
                    <Breadcrumb>
                        {page.path.map((pathItem, index) =>
                            <>{index !== 0 ? <BreadcrumbDivider icon='right chevron' /> : ''}<BreadcrumbSection onClick={e => {
                                e.stopPropagation()
                                e.preventDefault()
                                history.push(`/knowledgeBase/${pathItem.url}`)
                            }} link>{pathItem.title}</BreadcrumbSection></> )}
                    </Breadcrumb>
                </div> : ''}
                {page.historyIsAvailable ? <Popup
                    basic
                    inverted
                    content={'Показать историю изменений'}
                    trigger={<Icon
                        className={'history-btn'}
                        name={'history'}
                        size='large'
                        color='blue'
                        onClick={()=>{setOpenHistoryModal(true)}}
                    />} /> : ''}
                {page.contentSubscribers.length > 0 ? <Popup
                    basic
                    inverted
                    content={<div className={'popup-subscribers-container'}>
                        На обновление этой страницы подписаны:
                        <ul>
                            {page.contentSubscribers.map(subscriber => <li className={'subscriber-item'}>{subscriber.name}</li>)}
                        </ul>
                    </div>}
                    trigger={<div className={'subscribers-container'}>
                        {page.contentSubscribers.map(subscriber => <div className={'subscriber-item'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${subscriber.id}")`}}></div>)}
                        {page.userIsContentSubscribers ? <Button onClick={removeFromContentSubscribers} primary icon circular size={'mini'}>Отписаться</Button> : <Button onClick={addToContentSubscribers} primary icon circular size={'mini'}><Icon name={'plus'}/></Button>}
                    </div>} /> : <div className={'subscribers-container'}><Button onClick={addToContentSubscribers} primary icon circular size={'mini'}>Подписаться</Button></div>}
                <div className={'updater-info'}>
                    <div className={'user'}>
                        <div className={'avatar'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${page.lastUpdaterId}")`}}></div>
                        {page.lastUpdaterName}
                    </div>
                    <ViewRelativeTime dateTime={page.updateAt}/>
                </div>
            </div>
            <Divider />
            {page.isFolder ? <ViewFolderContent pageName={pageName} /> : <div>  
                {(page.content ?? '') == '' ? <Message>Эта страница пока пуста</Message> :
                    <div style={{position: 'relative'}}>
                        <DownloadPdfBtn targetRef={targetRefForDownloadPdf} name={page.title}/>
                        <div ref={targetRefForDownloadPdf}>
                            <KnowledgeBasePageMarkdownView content={page.content ?? ''} />
                        </div>
                    </div>
                }
                
                {page.prevPage != null || page.nextPage != null ? <Grid className={'navigation-on-siblings-page-container'}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {page.prevPage != null ? <h4><a onClick={()=>{history.push(`/knowledgeBase/${page.prevPage.url}`)}}><Icon name={'arrow left'}/> {page.prevPage.title}</a></h4> : ''}
                        </Grid.Column>
                        <Grid.Column width={8} style={{textAlign: 'right'}}>
                            {page.nextPage != null ? <h4><a onClick={()=>{history.push(`/knowledgeBase/${page.nextPage.url}`)}}>{page.nextPage.title} <Icon name={'arrow right'}/></a></h4> : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid> : ''}
                
                <Comments pageId={page.id}/>
                <HistoryModal page={page} open={openHistoryModal} setOpen={setOpenHistoryModal}/>
            </div>}
        </div>}
    </div>;
}