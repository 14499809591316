import React from "react";

let numericFormater = (x)=>{ if(x<10){return '0' + x.toString();}else{return x.toString();}}

export function getDateForDateTime(value){
    let date = value.split(' ')[0];
    let time = value.split(' ')[1];
    let hours = parseInt(time.split(':')[0]);
    let minutes = parseInt(time.split(':')[1]);
    let seconds = parseInt(time.split(':')[2]);
    let day = date.split('.')[0];
    let month = date.split('.')[1];
    let year = date.split('.')[2];
    return new Date(`${year}-${month}-${day}T${numericFormater(hours)}:${numericFormater(minutes)}:${numericFormater(seconds)}`);
}

export function getStringForDateTime(date){
    return `${numericFormater(date.getDate())}.${numericFormater(date.getMonth()+1)}.${numericFormater(date.getFullYear())} ${numericFormater(date.getHours())}:${numericFormater(date.getMinutes())}:${numericFormater(date.getSeconds())}`;
}


export function getDateForDate(value){
    if(value == '') return null;
    let date = value;
    let day = date.split('.')[0];
    let month = date.split('.')[1];
    let year = date.split('.')[2];
    let date1 = new Date(`${year}-${month}-${day}`);
    return date1;
}

export function getStringForDate(date){
    if(date == '' || date == null) return '';
    return `${numericFormater(date.getDate())}.${numericFormater(date.getMonth()+1)}.${numericFormater(date.getFullYear())}`;
}

export function isDateString(stringDate){
    let arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '<', '-', '>', '.']
    return stringDate.split('').every(x => arr.some(arrItem => arrItem === x));
    
}