export function saveAuth(identity) {
    if (identity){
        localStorage.removeItem("GlobalFilterValue")
        localStorage.setItem("Identity", JSON.stringify(identity));
    }
}

export function logout() {
    localStorage.removeItem("Identity");
    localStorage.removeItem("GlobalFilterValue")
}

export function getName() {
    let identity = JSON.parse(localStorage.getItem("Identity"));

    if (identity) {
        return identity.userName;
    }

    return null;
}

export function getId() {
    let identity = JSON.parse(localStorage.getItem("Identity"));

    if (identity) {
        return identity.userId;
    }

    return null;
}

export function getPermissions() {
    let identity = JSON.parse(localStorage.getItem("Identity"));

    if (identity) {
        return identity.permissions;
    }

    return null;
}

export function getRole() {
    let identity = JSON.parse(localStorage.getItem("Identity"));

    if (identity) {
        return identity.userRole;
    }

    return null;
}

export function isLogged() {
    let identity = localStorage.getItem("Identity");
    if (identity) {
        return true;
    } else {
        return false;
    }
}

export function getToken() {
    let identity = JSON.parse(localStorage.getItem("Identity"));

    if (identity) {
        return identity.accessToken;
    }
    return null;
}

export function setCurrentUserData(userData) {
    if (userData) {
        localStorage.setItem("userData", userData);
    }
}