import React, {useEffect, useState} from 'react';
import './ViewFolderContent.scss'
import {Button, Icon, Loader} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import {useHistory} from "react-router-dom";
import CreatePageOrFolderModal from "../../../CreatePageOrFolderModal/CreatePageOrFolderModal";
import appConfig from "../../../../../../utils/appConfig";

export default function ViewFolderContent({pageName}) {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [openCreatePageOrFolderModal, setOpenCreatePageOrFolderModal] = useState(false)
    const history = useHistory();
    
    useEffect(() => {
        setIsLoading(true)
        api()
            .getKnowledgeBaseMenuItems(pageName)
            .then(resp=>{
                setItems(resp)
                setIsLoading(false)
            })
    }, [pageName])
    
    if(isLoading) return <Loader active /> 
    
    return <div className={'knowledge-base-folder-content-items'}>
        {items.length == 0 ? <div>Эта папка пуста</div> : ''}
        {items.map(item => <div className={'item'} onClick={()=>{
            history.push(`/knowledgeBase/${item.url}`)        
        }}>{item.isFolder ? <Icon name={'folder outline'}/> : ''} {item.name}</div>)}

        {appConfig().info.isKnowledgeBaseAdministrator ? <>
            <div style={{textAlign: 'center'}}>
                <Button
                    icon
                    primary
                    size='tiny'
                    style={{marginTop: '10px'}}
                    onClick={()=>{setOpenCreatePageOrFolderModal(true)}}
                ><Icon name='plus' /> Создать</Button>
            </div>
            <CreatePageOrFolderModal
                directory={pageName}
                open={openCreatePageOrFolderModal}
                setOpen={setOpenCreatePageOrFolderModal}
                onCreate={url => {history.push(`/knowledgeBase/${url}`)}}
            />
        </> : ''}
    </div>;
}