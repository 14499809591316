import React from 'react';
import './KnowledgeBasePageEditor.css'
import MdEditor from "react-markdown-editor-lite";
import {useWindowSize} from "../../../../../../utils/windowSize";
import MyImageUpload from "./components/MyImageUpload/MyImageUpload";
import KnowledgeBaseLinkInsert from "./components/KnowledgeBaseLinkInsert/KnowledgeBaseLinkInsert";
import UploadDocumentPlugin from "./components/UploadDocumentPlugin/UploadDocumentPlugin";
import KnowledgeBasePageMarkdownView from "../../../KnowledgeBasePageMarkdownView/KnowledgeBasePageMarkdownView";

MyImageUpload.defaultConfig = {}
MyImageUpload.align = 'left';
MyImageUpload.pluginName = 'myImageUpload';

MdEditor.use(MyImageUpload, {});

KnowledgeBaseLinkInsert.defaultConfig = {}
KnowledgeBaseLinkInsert.align = 'left';
KnowledgeBaseLinkInsert.pluginName = 'knowledgeBaseLinkInsert';

MdEditor.use(KnowledgeBaseLinkInsert, {});

UploadDocumentPlugin.defaultConfig = {}
UploadDocumentPlugin.align = 'left';
UploadDocumentPlugin.pluginName = 'uploadDocument';

MdEditor.use(UploadDocumentPlugin, {});

export default function KnowledgeBasePageEditor({page, setPage}) {
    const [width, height] = useWindowSize();
    return <MdEditor
        value={page.content?.toString() ?? ''}
        onChange={e => {
            setPage({...page, content: e.text})
        }}
        renderHTML={text => {return <KnowledgeBasePageMarkdownView content={text ?? ''} />}}
        style={{ height: `${height - 150}px` }}
        allowPasteImage={false}
        imageAccept={true}
        view={false}
        placeholder={''}
        canView={{
            html: false,
            fullScreen: false,
        }}
        plugins={[  'header',
            'font-bold',
            'font-italic',
            'list-unordered',
            'list-ordered',
            'block-quote',
            'block-wrap',
            'block-code-inline',
            'block-code-block',
            'table',
            'myImageUpload',
            'knowledgeBaseLinkInsert',
            'uploadDocument',
            'link',
            'clear',
            'logger',
            'tab-insert'
        ]}
    />;
}