import {Button, Dropdown, Grid, Icon, Message, Popup} from "semantic-ui-react";
import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import './ReportEditor.css'
import ReportViewer from "../../../pages/ReportViewer/ReportViewer";
import {FieldSelectorForReport} from "./components/FieldSelectorForReport/FieldSelectorForReport";
import {FieldsPartViewerForReport} from "./components/FieldsPartViewerForReport/FieldsPartViewerForReport";
import {useWindowSize} from "../../../utils/windowSize";
import reportColumnTypes from "../../../utils/columnTypes";
import md5 from "md5";
import getBackedUrl from "../../../utils/getBackedUrl";
import appConfig from "../../../utils/appConfig";

const backendUrl = getBackedUrl()

export function ReportEditor(props) {
    
    let [isElementFocused, setIsElementFocused] = useState(false);
    let [focusedPageIndex, setFocusedPageIndex] = useState(0);
    let [focusedElementIndex, setFocusedElementIndex] = useState(null);
    let [focusedElementType, setFocusedElementType] = useState(null);
    let [isDictionaryViewerFocused, setIsDictionaryViewerFocused] = useState(false);
    let [columnNameForFocused, setColumnNameForFocused] = useState(null);
    let [lastSelectedField, setLastSelectedField] = useState(null);
    let [hoveredFieldValue, setHoveredFieldValue] = useState(null);
    let [toolbarIsOpen, setToolbarIsOpen] = useState(true);
    let [isViewElementBorder, setIsViewElementBorder] = useState(true);
    let [enumColors, setEnumColors] = useState([]);
    let [currentUserViewerId, setCurrentUserViewerId] = useState(null);
    let [projectUsers, setProjectUsers] = useState([]);
    let reportTypes = reportColumnTypes();
    
    const [windowWidth, windowHeight] = useWindowSize();
    
    const setFormData = (formData) => {
        if(formData.filter(x => x.name === 'Id').length > 0)
            props.onChange({target: {value: md5(JSON.stringify(formData.filter(x => x.name == 'Pages')))}})
        props.setFormData(formData)
    } 
    
    useEffect(()=>{
        let token = JSON.parse(localStorage.Identity).accessToken || null;
        fetch(`${backendUrl}/api/getEnumColorsForReportConstructor?projectId=${localStorage.getItem('GlobalFilterValue')}&token=${token}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        }).then((resp)=>{
            if (resp.ok) {
                return resp.json().then(function (json) {
                    setEnumColors(json);
                });
            } else {
                throw new Error('Fetch error');
            }
        });
    }, []);
    
    let elementTypes = appConfig().enums.filter(x=>x.nameSingle === 'ReportElementType')[0].values;

    let changeFocusedElementType = (elementType)=>{
        props.formData.filter(x=>x.name === "Pages")[0].value[focusedPageIndex].Elements[focusedElementIndex].ElementType = elementType.nameSingle;
        setFormData(props.formData);
        setFocusedElementType(elementType.nameSingle);
    };
    let addToPage = (elementType)=>{
        let formData = props.formData.map(x=>x);
        let newElementIndex = formData.filter(x=>x.name === "Pages")[0].value[focusedPageIndex].Elements.length;
        //debugger;
        let newElement = {
            ElementType: elementType.nameSingle,
            PositionX:0,
            PositionY:0,
            PositionZ:0,
            Width:20,
            Height:20,
            IsConfigured: elementType.nameSingle === "Shapes"
        };

        let preselectedColumns = appConfig().dictionaries.filter(x=>x.strictNameMany === 'ReportElements')[0]
            .columns.filter(x=>x.preselected);
        
        if(preselectedColumns.length > 0){
            for (var i = 0; i < preselectedColumns.length; i++) {
                let newVar;
                if(preselectedColumns[i].type === reportTypes.bool)
                    newVar = preselectedColumns[i].type === reportTypes.bool;

                if(preselectedColumns[i].type === reportTypes.linkToEnum){
                    let enumForCol = appConfig().enums.filter(x=>x.nameSingle === preselectedColumns[i].linkTo)[0];
                    newVar = enumForCol.values[0].nameSingle;
                }
                newElement[preselectedColumns[i].name] = newVar;
            }
        }
        
        formData.filter(x=>x.name === "Pages")[0].value[focusedPageIndex].Elements.push(newElement);

        setFormData(formData);
        setFocusedElementIndex(newElementIndex);
        setIsElementFocused(true);
        setFocusedElementType(elementType.nameSingle);
    };
    
    useEffect(()=>{
        if(props.formData.filter(x=>x.name === "Pages").length === 0) {
            let formData = props.formData.map(x=>x);
            formData.push({
                name: 'Pages',
                value: [{ RuName: 'Обзор',  EnName: '', Elements: []}]
            });
            setFormData(formData);
        }
        if(projectUsers.length === 0){
            let token = JSON.parse(localStorage.Identity).accessToken || null;

            fetch(backendUrl + '/api/fromReportConstructor/getValue/projectUsers', {
                method: 'Post',
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify({
                    Token: token,
                    ProjectId: localStorage.getItem('GlobalFilterValue'),
                })
            }).then(function (response) {
                    if (response.ok) {
                        return response.text().then(function (text) {
                            return ({text: text, response: response});
                        });
                    } else {
                        throw new Error('Fetch error');
                    }
                }
            ).then(function (a) {
                if (a.response.status === 401) {
                    localStorage.clear()
                    window.location = '/login';
                }
                if (!a.response.ok) {
                    return Promise.reject(a.text);
                }
                return a.text;
            })
                .then(function (resp) {
                    return JSON.parse(resp)
                }).then(resp=>{
                setProjectUsers(resp.map(x=> {return {key: x.id, value: x.id, text: x.name};}));
                setCurrentUserViewerId(resp.filter(x=>x.name === 'Administrator')[0].id);
            });
        }
    }, []);
    let uncapitalizeFirstLetter = (text) => {
        return text.charAt(0).toLowerCase() + text.slice(1);
    };

    return <div className={'report-editor-container'}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={(windowWidth > 1600 ? 12 : 11)}>
                        {currentUserViewerId == null ? '' : 
                        <ReportViewer 
                            isEdit={true}
                            isViewElementBorder={isViewElementBorder}
                            pages={props.formData.filter(x=>x.name === 'Pages')[0]?.value.map(x=>{ return {ruName : x.RuName, elements: x.Elements.map(e=>{
                                let a = {};

                                for (let fieldName in e)
                                    a[uncapitalizeFirstLetter(fieldName)] = e[fieldName];
                                
                                a.type = e.ElementType;
                                a.x = e.PositionX;
                                a.y= e.PositionY;
                                a.z= e.PositionZ;
                                a.w= e.Width;
                                a.h= e.Height;
                                return a;})};}) ?? []}
                            dataColors={props.formData.filter(x=>x.name === 'DataColors')[0]?.value.map(x=>{return {dataValue: x.DataValue, color: x.Color};}) ?? []}
                            enumColors={enumColors}
                            getData={(pageIndex, elementIndex, filtersValues)=>{
                                let token = JSON.parse(localStorage.Identity).accessToken || null;

                                let prepareElement = (element, typeName) => {
                                    let elementClone = {};
                                    let dictionary = appConfig()
                                        .dictionaries.filter(x=>x.nameSingle === typeName)[0];
                                    
                                    for (let colIndex in dictionary.columns) {
                                        let col = dictionary.columns[colIndex];
                                        if(element[col.name] === undefined || element[col.name] == null || element[col.name] === '')
                                            continue;
                                        switch (col.type){
                                            case 'linkToEnum':
                                                elementClone[col.name] = appConfig()
                                                    .enums
                                                    .filter(x=>x.nameSingle === col.linkTo)[0]
                                                    .values
                                                    .filter(x=> x.nameSingle === element[col.name])[0]?.index ?? null;
                                                break;
                                            case 'linkToDictionary':
                                                elementClone[col.name] = element[col.name].id;
                                                break;
                                                
                                            default:
                                                elementClone[col.name] = element[col.name];
                                        }

                                    }
                                    return elementClone;
                                };
                                
                                return fetch(backendUrl + '/api/fromReportConstructor/getValue', {
                                    method: 'Post',
                                    headers: {
                                        "Content-Type": "application/json; charset=utf-8"
                                    },
                                    body: JSON.stringify({
                                        Token: token,
                                        CurrentUserViewerId: currentUserViewerId,
                                        ProjectId: localStorage.getItem('GlobalFilterValue'),
                                        Element: prepareElement(props.formData.filter(x=>x.name === 'Pages')[0]?.value[pageIndex].Elements[elementIndex], 'ReportElement'),
                                        FiltersValues: filtersValues,
                                        DictionaryFilterSources: (props.formData.filter(x=>x.name === "DictionaryFilters")[0]?.value ?? []).map(x => {return prepareElement(x, 'ReportDictionaryFilter');})
                                    })
                                }).then(function (response) {
                                        if (response.ok) {
                                            return response.text().then(function (text) {
                                                return ({text: text, response: response});
                                            });
                                        } else {
                                            throw new Error('Fetch error');
                                        }
                                    }
                                ).then(function (a) {
                                    if (a.response.status === 401) {
                                        localStorage.clear()
                                        window.location = '/login';
                                    }
                                    if (!a.response.ok) {
                                        return Promise.reject(a.text);
                                    }
                                    return a.text;
                                })
                                    .then(function (resp) {
                                        return JSON.parse(resp)
                                    })
                            }}
                            onAddPage={(name)=>{

                                let formData = props.formData.map(x => x);

                                formData.filter(x=>x.name === "Pages")[0].value.push({
                                    RuName: name,
                                    EnName: '',
                                    Elements: []
                                });
                                setFocusedPageIndex(formData.filter(x=>x.name === "Pages")[0].value.length - 1);
                                setIsElementFocused(false);
                                setFocusedElementIndex(null);
                                setFormData(formData);
                            }}
                            onChangePage={(pageIndex, newPageName)=>{
                                let formData = props.formData.map(x=>x);
                                let page = formData.filter(x=>x.name === "Pages")[0].value[pageIndex];
                                page.RuName = newPageName;
                                setFormData(formData);
                            }}
                            onRemovePage={(pageIndex)=>{
                                let formData = props.formData.map(x=>x);
                                let pages = formData.filter(x=>x.name === "Pages")[0].value;
                                let page = pages[pageIndex];
                                formData.filter(x=>x.name === "Pages")[0].value = pages.filter(x=>x.RuName !== page.RuName);
                                setIsElementFocused(false);
                                setFocusedElementIndex(null);
                                setFormData(formData);
                            }}
                            onRemoveElement={(currentPage, elementIndex) => {
                                let formData = props.formData.map(x=>x);
                                let page = formData.filter(x=>x.name === "Pages")[0].value[currentPage];
                                
                                page.Elements = page.Elements.filter((x,index)=> {return index !== (elementIndex + 0)});
                                setIsElementFocused(false);
                                setFocusedElementIndex(null);
                                //setFocusedElementType(type);
                                setFormData(formData);
                            }}
                            onElementFocus={(pageIndex, elementIndex) => {
                                setFocusedPageIndex(pageIndex);
                                setFocusedElementIndex(elementIndex);
                                let page = props.formData.filter(x=>x.name === "Pages")[0].value[pageIndex];
                                let type = page.Elements[elementIndex].ElementType;
                                setFocusedElementType(type);
                                setIsElementFocused(true);
                            }}
                            onPageFocus={(pageIndex)=>{
                                setFocusedPageIndex(pageIndex);
                                setIsElementFocused(false);
                                setFocusedElementIndex(null);
                            }}
                            onSetElementPosition={(pageIndex, elementIndex, x, y, w, h)=>{
                                let formData = props.formData.map(x=>x);
                                let element = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements[elementIndex];
                                element.PositionX = x;
                                element.PositionY = y;
                                element.Width = w;
                                element.Height = h;
                                setFormData(formData);
                            }}
                            onCloneElement={(pageIndex, elementIndex, targetPageIndex) => {
                                let formData = props.formData.map(x=>x);
                                let element = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements[elementIndex];
                                let newElement = JSON.parse(JSON.stringify(element));
                                newElement.PositionX = element.PositionX  + 10;
                                newElement.PositionY = element.PositionY + 10;
                                if(targetPageIndex === undefined){
                                    targetPageIndex = pageIndex;
                                    newElement.PositionX = element.PositionX  + 10;
                                    newElement.PositionY = element.PositionY + 10;
                                }else{
                                    newElement.PositionX = 0;
                                    newElement.PositionY = 0;
                                }
                                let elements = formData.filter(x=>x.name === "Pages")[0].value[targetPageIndex].Elements.map(x=>x);
                                elements.push(newElement);
                                formData.filter(x=>x.name === "Pages")[0].value[targetPageIndex].Elements = elements;
                                setFormData(formData);
                                setFocusedElementIndex(elements.length - 1);
                                setFocusedPageIndex(targetPageIndex);
                            }}
                            onMoveElementToFront={(pageIndex, elementIndex) => {
                                let formData = props.formData.map(x=>x);
                                let element = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements[elementIndex];
                                let elements = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements.map(x=>x);
                                formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements = elements.map((x,i)=>{
                                    if(i === elementIndex) {
                                        return JSON.parse(JSON.stringify(elements[elementIndex + 1])) ; 
                                    }
                                    if(i === (elementIndex + 1)) {
                                        return JSON.parse(JSON.stringify(element));
                                    }
                                    return JSON.parse(JSON.stringify(x));
                                });
                                setFocusedElementIndex(elementIndex + 1);
                                setFormData(formData);
                            }}
                            onMoveElementToBack={(pageIndex, elementIndex) => {
                                let formData = props.formData.map(x=>x);
                                let element = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements[elementIndex];
                                let elements = formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements.map(x=>x);
                                formData.filter(x=>x.name === "Pages")[0].value[pageIndex].Elements = elements.map((x,i)=>{
                                    if(i === elementIndex) {
                                        return JSON.parse(JSON.stringify(elements[elementIndex - 1])) ;
                                    }
                                    if(i === (elementIndex - 1)) {
                                        return JSON.parse(JSON.stringify(element));
                                    }
                                    return JSON.parse(JSON.stringify(x));
                                });
                                setFocusedElementIndex(elementIndex - 1);
                                setFormData(formData);
                            }}
                            onMovePageLeft={(pageIndex) => {
                                let formData = props.formData.map(x=>x);
                                let page = formData.filter(x=>x.name === "Pages")[0].value[pageIndex];
                                let pages = formData.filter(x=>x.name === "Pages")[0].value;
                                formData.filter(x=>x.name === "Pages")[0].value = pages.map((x, index)=> {
                                    if(index === pageIndex) {
                                        return pages[pageIndex - 1] ;
                                    }
                                    if(index === (pageIndex - 1)) {
                                        return page;
                                    }
                                    return x;                                    
                                });
                                setFocusedPageIndex(pageIndex - 1);
                                setFormData(formData);
                            }}
                            onMovePageRight={(pageIndex) => {
                                let formData = props.formData.map(x=>x);
                                let page = formData.filter(x=>x.name === "Pages")[0].value[pageIndex];
                                let pages = formData.filter(x=>x.name === "Pages")[0].value;
                                formData.filter(x=>x.name === "Pages")[0].value = pages.map((x, index)=> {
                                    if(index === pageIndex) {
                                        return pages[pageIndex + 1] ;
                                    }
                                    if(index === (pageIndex + 1)) {
                                        return page;
                                    }
                                    return x;
                                });
                                setFocusedPageIndex(pageIndex + 1);
                                setFormData(formData);
                            }}
                            pageIndexForFocusedElement={focusedPageIndex}
                            elementIndexForFocusedElement={focusedElementIndex}
                            width={(windowWidth > 1600 ? 1323 : 950)}
                        />}
                        {toolbarIsOpen ? 
                            <div className={'toolbar-container'}>
                                <Popup basic content={'Показать или скрыть обводки в режиме редактирования'}  trigger={<Button active={isViewElementBorder} size={"mini"} icon={'table'} onClick={()=>{setIsViewElementBorder(!isViewElementBorder)}}/>}/>
                                <Popup basic content={'Под кем отображать отчёт'}  trigger={<Icon name={'eye'}/>}/>
                                <Dropdown size={'mini'} selection value={currentUserViewerId} options={projectUsers} onChange={(e, data)=>{
                                    setCurrentUserViewerId(data);
                                }}/>
                                <Popup basic content={'Скрыть панель инструментов'}  trigger={<Icon name={'chevron down'} onClick={()=>{
                                    setToolbarIsOpen(false);
                                }}/>}/>
                            </div> : 
                            <div className={'toolbar_opener'}>
                                <Popup basic content={'Показать панель инструментов'}  trigger={<Icon name={'chevron up'} onClick={()=>{
                                    setToolbarIsOpen(true);
                                }}/>}/>
                                
                            </div>}
                    </Grid.Column>
                    <Grid.Column width={(windowWidth > 1600 ? 2 : 3)} style={{borderRight: '1px solid #DEDEDF'}}>
                        {elementTypes
                            .map(elementType => <Popup
                                trigger={<div 
                                    className={'report-editor-element-type-item' + (isElementFocused && focusedElementType === elementType.nameSingle ? ' focused' : '')} 
                                    onClick={()=>{isElementFocused ? changeFocusedElementType(elementType) : addToPage(elementType);}}>
                                    <img alt={elementType.ruNameSingle} src={elementType.imageUrlForLabel}/>
                                </div>}
                                content={elementType.ruNameSingle}
                                inverted
                            />)}
                        {!isElementFocused ? <Message>Выберите тип элемента для вставки на страницу</Message> : ''}
                        {isElementFocused ? <FieldsPartViewerForReport 
                            formData={props.formData ?? []}
                            setFormData={setFormData}
                            currentPageIndex={focusedPageIndex}
                            currentElementIndex={focusedElementIndex}
                            currentElementType={focusedElementType}
                            onStartSelectField={(columnName)=>{
                                setIsDictionaryViewerFocused(true);
                                setColumnNameForFocused(columnName);
                            }}
                            columnNameForFocused={columnNameForFocused}
                            selectedField={lastSelectedField}
                            onStopSelectedField={()=>{
                                setIsDictionaryViewerFocused(false);
                                setColumnNameForFocused(null);
                                setLastSelectedField(null);
                            }}
                            setHoveredFieldValue={setHoveredFieldValue}
                        /> : ''}
                    </Grid.Column>
                    <Grid.Column width={2} className={'report-editor-dictionary-viewer' + (isDictionaryViewerFocused ? ' focused' : '')}>
                        <FieldSelectorForReport 
                            isFocused={isDictionaryViewerFocused}
                            formData={props.formData ?? []}
                            setFormData={setFormData}
                            onSelectField={(dictionary, field)=>{
                                //setIsDictionaryViewerFocused(false);
                                //debugger;
                                setLastSelectedField({dictionary, field});
                            }}
                            height={(windowWidth > 1600 ? 734 : 534)}
                            hoveredFieldValue={hoveredFieldValue}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        
        
    </div>;
}

ReportEditor.propTypes = {
    value: PropTypes.any,
    formData: PropTypes.any,
    setFormData: PropTypes.any,
    onChange: PropTypes.func,
    column: PropTypes.any,
    parentData: PropTypes.any,
    single: PropTypes.bool
};