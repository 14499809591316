import React, {useEffect, useState} from 'react';
import './loginByToken.css'
import {
    Loader,
} from "semantic-ui-react";
import * as AuthHelper from "../../utils/authHelper";
import t, {getCurrentLangValue} from "../../utils/translate";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import md5 from "md5";
import appConfig, {setAppConfig} from "../../utils/appConfig";

export default function LoginByToken() {
    let {token} = useParams();
    let [isErrorToken, setIsErrorToken] = useState(false);
    useEffect(()=>{
        api().loginByToken(token)
            .then(resp => {
                if(resp.data !== undefined && resp.data.accessToken !== undefined){
                    AuthHelper.saveAuth(resp.data)
                    
                    api()
                        .getConfiguration()
                        .then(configuration=>{
                            api()
                                .getById("Users", AuthHelper.getId())
                                .then(user=>{
                                    setAppConfig(configuration)
                                    window.user = user;

                                    let views = appConfig()
                                        .views;

                                    if(views.length === 0)
                                    {
                                        setIsErrorToken(true);
                                        return;
                                    }
                                    let pages = appConfig().info.introductionPages;

                                    if(pages.length > 0 && localStorage.getItem(('Introduction-' + md5(JSON.stringify(pages)) + '_' + getCurrentLangValue() + '_' + window.user.Id)) === null) {
                                        window.location = '/Introduction';
                                        return;
                                    }
                                    let singleName =  appConfig()
                                        .dictionaries.filter(x=>x.strictNameMany === views[0].dictionaryStrictName)[0].nameSingle;
                                    window.location = '/' + (singleName + 'View' + views[0].viewName);
                                });
                        });
                }
                else{
                    setIsErrorToken(true);
                }
            })
    },[]);

    return (<div className={'login-by-token-page'}>
        {isErrorToken ? <p>{t('errorLoginToken')}</p> : <div className={'login-by-token-page-loader-wrapper'}><Loader active inline size='massive'>{t('Loading')}</Loader></div>}
    </div>);
}