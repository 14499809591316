import React from 'react';
import './PageMover.scss'
import {
    Icon, Popup
} from "semantic-ui-react";
import api from "../../../../../../utils/api";

export default function PageMover({page, maxIndex, onMove}) {
    const changePosition = (e, newIndex) => {
        e.stopPropagation()
        e.preventDefault()
        api()
            .setPositionKnowledgeBasePage(page.id, newIndex(page.index))
            .then(resp => {
                onMove()
            })
    }
    return <div className={'page-mover-container'}>
        {page.index != 0 ? <Popup
            basic
            inverted
            content={'Выше'}
            trigger={<Icon
                onClick={e=>{changePosition(e, i => i - 1)}}
                name={'arrow up'}
            />} /> : ''}
        {page.index != maxIndex ? <Popup
            basic
            inverted
            content={'Ниже'}
            trigger={<Icon
                onClick={e=>{changePosition(e, i => i + 1)}}
                name={'arrow down'}
            />} /> : ''}
    </div>;
}