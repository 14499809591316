import React, {useEffect, useState} from 'react';
import './CreateChannelModal.css'
import {
    Button,
    Dropdown,
    Form,
    FormField, Icon, Label,
    Modal
} from "semantic-ui-react";
import {getCurrentLangValue} from "../../../../utils/translate";
import api from "../../../../utils/api";
import {toast} from "react-toastify";
import getBackedUrl from "../../../../utils/getBackedUrl";

export default function CreateChannelModal({open, setOpen}) {
    let currentLangValue = getCurrentLangValue();
    let [availableMembers, setAvailableMembers] = useState([]);
    let [name, setName] = useState('');
    let [description, setDescription] = useState('');
    let [members, setMembers] = useState([]);
    
    useEffect(()=>{
        api()
            .getAvailableMembersForChat()
            .then(resp=>{
                setAvailableMembers(resp.map(x=> ({
                    key: x.id, 
                    text: x.name, 
                    value: x.id,
                    image: { avatar: true, src: `${getBackedUrl()}/api/avatar/byUser/${x.id}` },
                })));
            });
    }, []);
    
    const createChannel = () => {
        api()
            .createChannel(name, members, description)
                .then(resp=>{
                    if(resp.errorMessage == null)
                        setOpen(false);
                    else
                        toast.error(resp.errorMessage);
                });
    };

    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        size={"mini"}
    >
        <Modal.Header>Новый чат</Modal.Header>
        <Modal.Content>
            <Form>
                {availableMembers.length > 0 ? <FormField>
                    <label>Участники</label>
                    <Dropdown className={'users-dropdown'}
                        renderLabel={item => <Label className={'selected-member'}> 
                            <img src={item.image.src} className={'avatar'}/> 
                            <span className={'text'}>{item.text}</span> <Icon name={'close'} onClick={()=>{setMembers(members.filter(x=>x !== item.value))}}/> 
                        </Label>}
                        placeholder='Укажите участников'
                        fluid
                        multiple
                        selection
                        options={availableMembers}
                        value={members}
                        onChange={(e, data)=>{setMembers(data.value)}}
                    />
                </FormField> : ''}
                {members.length > 1 ? <Form.Input label='Название' placeholder='Введите название...'
                                                  value={name} onChange={e=>{setName(e.target.value)}} /> : ''}
                <Form.TextArea label='Сообщение' placeholder='Введите сообщение...'  
                               value={description} onChange={e=>{setDescription(e.target.value)}} />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Отмена
            </Button>
            <Button
                content={"Создать"}
                labelPosition='right'
                icon='checkmark'
                onClick={createChannel}
                positive
            />
        </Modal.Actions>
    </Modal>);
}