import React, {useContext, useEffect, useRef, useState} from 'react';
import './Chat.scss'
import {
    Button,
    Dropdown,
    Form,
    Grid, GridColumn,
    GridRow,
    Icon, Image,
    Label,
    Message, Popup, Search,
    Visibility
} from "semantic-ui-react";
import {useWindowSize} from "../../utils/windowSize";
import TextareaAutosize from "react-textarea-autosize";
import {getElementsFromFormattedText} from "../../utils/textHelper";
import EmojiPicker from 'emoji-picker-react';
import api from "../../utils/api";
import CreateChannelModal from "./components/CreateChannelModal/CreateChannelModal";
import {ConfigurationContext} from "../../utils/configurationContext";
import moment from "moment";
import DeleteChannelModal from "./components/DeleteChannelModal/DeleteChannelModal";
import getBackedUrl from "../../utils/getBackedUrl";
import appConfig from "../../utils/appConfig";

function removeEmojis(string) {
    if(string == null)
        return ""
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return string.replace(regex, "");
}

function isOnlyEmoji(string) {
    return !removeEmojis(string).length;
}

function getCaret(el) {
    if (el !== undefined && el.selectionStart) {
        return el.selectionStart;
    } else if (document.selection) {
        el.focus();
        var r = document.selection.createRange();
        if (r == null) {
            return 0;
        }
        var re = el.createTextRange(), rc = re.duplicate();
        re.moveToBookmark(r.getBookmark());
        rc.setEndPoint('EndToStart', re);
        return rc.text.length;
    }
    return 0;
}

function FileAttachInput({onAttach}) {
    let fileInputRef = useRef();    
    return <div className={'file-attach-btn-container'}>
        <Icon name={'paperclip'} size={"large"} onClick={() => fileInputRef.current.click()} />
        <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={(e) => { return api().uploadFile(e.target.files[0]).then((resp)=> {
                onAttach(resp);
                e.target.value = null
            })}}/>
    </div>;
}

export default function Chat({onNeedUpdateIndicators}) {
    const context = useContext(ConfigurationContext);
    const [width, height] = useWindowSize();
    let [rowHeightNumber, setRowHeightNumber] = useState(0);
    let [messageInput, setMessageInput] = useState('');
    let [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    let [openCreateChannelModal, setOpenCreateChannelModal] = useState(false);
    let [openDeleteChannelModal, setOpenDeleteChannelModal] = useState(false);
    let [selectedChannelId, setSelectedChannelId] = useState(null);
    let [selectedChannelHeader, setSelectedChannelHeader] = useState(null);
    let [selectedChannelMembers, setSelectedChannelMembers] = useState(null);
    let [selectedChannelMemberIds, setSelectedChannelMemberIds] = useState(null);
    let [selectedChannelOwned, setSelectedChannelOwned] = useState(false);
    let [selectedChannelAdditionalInfo, setSelectedChannelAdditionalInfo] = useState(null);
    let [chats, setChats] = useState([]);
    let [messages, setMessages] = useState([]);
    let [files, setFiles] = useState([]);

    function loadChannels() {
        api()
            .getChannels()
            .then(channels => {
                setChats(channels.map(c => ({
                    id: c.id,
                    title: c.name,
                    img: '',
                    text: c.message,
                    messages: c.unreadCount,
                    from: c.from,
                    fromMe: c.fromMe,
                    lastMessageCreateAt: moment.utc(c.lastMessageCreateAt).from(new Date( moment.utc().format() )),
                    members: c.members,
                    memberIds: c.memberIds,
                    owned: c.owned,
                    additionalInfo: c.additionalInfo,
                    //isActiveChat: true
                })));
            });
    }

    useEffect(()=>{
        loadChannels();
    }, []);

    useEffect(()=>{
        loadChannels();
        //debugger;
        if(context.lastMessage != null && context.lastMessage.channelId === selectedChannelId){
            loadMessages(selectedChannelId);
        }
    },[context]);
    
    
    let scrollToBottom = () => {
        setTimeout(()=>{
            var chatBody = document.getElementById('chat-body');
            chatBody.scrollTop = chatBody.scrollHeight + 1000;
        }, 100);
    }
    
    let send = () => {
        setIsEmojiPickerOpen(false)
        messages.push({
            id: '1',
            title: 'Вы',
            message: messageInput,
            fromMe: true,
            sendOn: moment(new Date()).format('hh:mm'),
            files: files
        });
        setMessages(messages);
        api()
            .sendMessage(selectedChannelId, messageInput, files.map(x=> {return {name: x.name, fileId: x.id}}))
            .then(resp=>{
                loadChannels();
                setFiles([])
            })
        ;
        setMessageInput('');
        scrollToBottom();
    }

    function loadMessages(chatId) {
        api()
            .getMessages(chatId)
            .then(resp => {
                let now = new Date();
                setMessages(resp.map(m => {
                    let createAt = moment(new Date(new Date(m.createAt).getTime() - new Date().getTimezoneOffset() * 60000));
                    let isToDay = now.toLocaleDateString() === createAt.toDate().toLocaleDateString();
                    return {
                        id: m.id,
                        title: m.from,
                        message: m.message,
                        fromMe: m.fromMe,
                        //sendOn: moment.utc(m.createAt).from(new Date( moment.utc().format() )),
                        sendOn: createAt.format((isToDay ? '' : 'DD:MM:yyyy ') + 'hh:mm'),
                        files: m.files,
                    };
                }));
                scrollToBottom();
            });
    }

    const getChatHeaderAdditionalInfo = (selectedChannelAdditionalInfo) => {
        let type = selectedChannelAdditionalInfo.split(':')[0];
        let chatId = selectedChannelAdditionalInfo.split(':')[1];
        let accountSource = selectedChannelAdditionalInfo.split(':')[2];
        let userAccount = selectedChannelAdditionalInfo.split(':')[3];
        return <Popup 
                basic 
                position={"bottom center"} 
                inverted 
                content={`Источник телеграм бот ${accountSource}`} 
                trigger={<Icon 
                    style={{cursor: 'pointer'}}
                    onClick={()=>{window.open(`https://t.me/${userAccount}`, '_blank')}} 
                    name={type}/>} />;
    }
    
    const getFileElements = (message) => {
        const getFile = (file) => {
            if(file.name == null)
                return ''
            if(file.name.includes('.png') || 
                file.name.includes('.jpg') || 
                file.name.includes('.jpeg') || 
                file.name.includes('.bmp') ){
                return <img style={{width: '100%'}} src={`${getBackedUrl()}/api/file/image/${file.id}`}/>
            }

            return <div className={'file-attach-in-message'} onClick={()=> {window.open(`${getBackedUrl()}/api/chat/download/${file.id}?fileName=${file.name}`, '_blank')}}><Icon name={'download'}/> {file.name}</div>
        }
        return message.files.length > 0 ? 
            <>{message.files.map(getFile)}</> 
            : ''
    }

    return (<div className={'chat-page-container'  + (appConfig().info.leftMenu ? ' with-left-menu' : '')}>
        <Grid id={'chat'}>
            <GridRow>
                <GridColumn className={'chat-list-container'} width={5}>
                    <div className={'chat-list-header'}><h1>Чаты</h1><Button circular icon='plus' onClick={()=>{setOpenCreateChannelModal(true)}}/></div>
                    <div className={'chat-list-search'}><Search className={'search'} input={{ icon: 'search', iconPosition: 'left', placeholder: 'Поиск по чатам' }}/></div>
                    <div className={'chat-list'} id={'chat-list'} style={{height: height - 153 + 'px'}}>
                        {chats.map(chat => {
                            return <div className={'chat-item' + (chat.id === selectedChannelId ? ' active': '')} onClick={()=>{
                                setSelectedChannelId(chat.id);
                                setSelectedChannelHeader(chat.title);
                                setSelectedChannelOwned(chat.owned);
                                setSelectedChannelAdditionalInfo(chat.additionalInfo == "" || chat.additionalInfo == null ? null : chat.additionalInfo);
                                if(chat.members.length > 1){
                                    let a = chat.members.reduce((a,b) => { return a + ', ' + b;});
                                    setSelectedChannelMembers(a);
                                }else{
                                    setSelectedChannelMembers('')
                                }
                                setSelectedChannelMemberIds(chat.memberIds)
                                loadMessages(chat.id);
                            }}>
                                <div className={'chat-item-img-container'}>
                                    <Image src={chat.memberIds.length > 1 ? '/img/group.svg' : `${getBackedUrl()}/api/avatar/byUser/${chat.memberIds[0]}`} size='tiny' circular />
                                </div>
                                <div className={'chat-item-body'}>
                                    <div className={'chat-item-title'}>{chat.title}</div>
                                    <div className={'chat-item-text'}>{chat.fromMe ? <b>Вы: </b> : (chat.from == '' ? '' : <b>{`${chat.from}: `}</b>)}{chat.text}</div>
                                    <div className={'chat-item-time'}>{chat.lastMessageCreateAt}</div>
                                </div>
                                <div className={'chat-item-right-container'}>
                                    {chat.messages !== 0 ? <Label>{chat.messages}</Label> : ''}
                                </div>
                            </div>;
                        })}
                    </div>
                </GridColumn>
                <GridColumn className={'chat-container'} width={11}>
                    {selectedChannelId == null ? <div className={'need-select-chat-message'}>Выберите чат или создайте новый</div> : <>
                        <div className={'chat-header'}>
                            <div className={'chat-header-img-container'}>
                                <Image src={selectedChannelMemberIds.length > 1 ? '/img/group.svg' : `${getBackedUrl()}/api/avatar/byUser/${selectedChannelMemberIds[0]}`} size='tiny' circular />
                            </div>
                            <div className={'chat-header-body'}>
                                <div className={'chat-header-title'}>{selectedChannelHeader} {selectedChannelAdditionalInfo != null ? getChatHeaderAdditionalInfo(selectedChannelAdditionalInfo) : ''}</div>
                                <div className={'chat-header-text'}>{selectedChannelMembers}</div>
                            </div>
                            {selectedChannelOwned ? <div className={'chat-action'}>
                                <Dropdown icon={<Icon name={'ellipsis vertical'} size={'big'} color={'grey'}/>} direction={'left'}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={()=>{setOpenDeleteChannelModal(true)}}><Icon name={'trash'}/>Удалить</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> : ''}
                        </div>
                        <div id={'chat-body'} className={'chat-body'} style={{height: height - (((files.length == 0 ? 130 : 164)) + (rowHeightNumber)) + 'px'}} onClick={()=>{setIsEmojiPickerOpen(false)}}>
                            {messages.map((message, messageIndex) => {
                                let maxLenght= message.message == null ? 0 : Math.max(message.message.split('\n').map(line=>line.length));
                                let width = maxLenght * 12;
                                if(!message.fromMe){
                                    let titleWidth = message.title.length * 13;
                                    if(titleWidth > width)
                                        width = titleWidth;
                                }
                                let sendOnWidth = message.sendOn.length * 13;
                                if(sendOnWidth > width)
                                    width = sendOnWidth;
                                return <div className={'message-container' + (message.fromMe ? ' from-me' : '')}>
                                    <Message style={{width: width}}>
                                        {!message.fromMe ? <Message.Header>{messageIndex != 0 && messages[messageIndex - 1].title == message.title ? '' : message.title}</Message.Header> : ''}
                                        {getFileElements(message)}
                                        <p style={{fontSize: isOnlyEmoji(message.message)? 'xx-large':''}}>{getElementsFromFormattedText(message.message)}</p>
                                        <div className={'time-description'}>{message.sendOn} {message.fromMe ? <img alt={''} src={'/img/check-full.svg'}/> : ''}</div>
                                    </Message>
                                    {messageIndex != 0 && messages[messageIndex - 1].title == message.title ? '' : <div className={'threeangle'}><div className={'threeangle-inner'}></div></div>}
                                    {message.fromMe ? <Message  style={{width: width}} className={'fake'}>
                                        {getFileElements(message)}
                                        <p style={{fontSize: isOnlyEmoji(message.message)? 'xx-large':''}}>{getElementsFromFormattedText(message.message)}</p>
                                        <div className={'time-description'}>{message.sendOn}</div>
                                    </Message>: ''}
                                    <Visibility style={{height: '1px', display: 'contents'}}
                                                onUpdate={(e, { calculations })=>{
                                                    if(message.id !== '1'){
                                                        api()
                                                            .setSeen(selectedChannelId, message.id)
                                                            .then(()=>{
                                                                loadChannels()
                                                                onNeedUpdateIndicators()
                                                            });
                                                    }
                                                }}>

                                    </Visibility>
                                </div>;})}
                        </div>
                        {isEmojiPickerOpen ? <div className={'emoji-picker-container'}>
                            <EmojiPicker
                                disableSearchBar
                                disableSkinTonePicker
                                native
                                groupNames={{
                                    smileys_people: 'Люди',
                                    animals_nature: 'Природа',
                                    food_drink: 'Еда',
                                    travel_places: 'Путешествие',
                                    activities: 'Игры',
                                    objects: 'Объекты',
                                    symbols: 'Знаки',
                                    flags: 'Флаги',
                                    recently_used: 'Недавние',
                                }}
                                onEmojiClick={(event, emojiObject)=>{ setMessageInput(messageInput + emojiObject.emoji) }}
                                //skinTone={SKIN_TONE_MEDIUM_DARK} 
                            />
                        </div> : ''}

                        <div className={'chat-message-input-block'}>
                            {files.length > 0 ? <div className={'chat-message-input-block-attachments'}>
                                {files.map(file => <div className={'file-attach'}>{file.name} <Icon onClick={() => {setFiles(files.filter(x=>x.id !== file.id))}} name={'close'}/></div>)}
                            </div> : ''}
                            <Form>
                                <Icon name={'smile outline'} size={"large"} onClick={()=> {setIsEmojiPickerOpen(!isEmojiPickerOpen);}}/>
                                <FileAttachInput onAttach={file => {setFiles([...files, file])}} />
                                <Form.Field>
                                    <TextareaAutosize
                                        id={'my-message'}
                                        placeholder={'Начните печатать ваше сообщение'}
                                        onKeyDown={(event)=>{
                                            if(event.ctrlKey === false && event.key === 'Enter'){
                                                event.preventDefault();
                                                send();
                                            }
                                            if(event.ctrlKey === true && event.key === 'Enter'){
                                                let caret = getCaret(document.getElementById('my-message'));
                                                let first = messageInput.substring(0, caret);
                                                let second = messageInput.substring(caret);
                                                setMessageInput(first + '\n' + second);
                                            }
                                        }}
                                        value={messageInput}
                                        onChange={(event => setMessageInput(event.target.value))}
                                        style={{resize: 'none'}}
                                        rows={1}
                                        onHeightChange={(rowHeightNumber) => { setRowHeightNumber(rowHeightNumber)}}
                                        onFocus={()=>{setIsEmojiPickerOpen(false)}}
                                    />
                                </Form.Field>
                                <Button onClick={send}>Отправить</Button>
                            </Form>
                        </div>                    
                    </>}
                </GridColumn>
            </GridRow>
        </Grid>
        {openCreateChannelModal ? <CreateChannelModal
            open={openCreateChannelModal}
            setOpen={(val)=>{
                setOpenCreateChannelModal(val);
                loadChannels();
            }}
        /> : ''}
        {openDeleteChannelModal ? <DeleteChannelModal
            id={selectedChannelId}
            open={openDeleteChannelModal}
            setOpen={(val)=>{
                setOpenDeleteChannelModal(val);
                loadChannels();
            }}
            onDelete={()=>{setSelectedChannelId(null);}}
        /> : ''}
    </div>);
}