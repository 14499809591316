import React, {useState} from 'react';
import t, {getCurrentLangValue} from "../../../utils/translate";
import {SketchPicker} from "react-color";
import './ColorPicker.css'
import {Button, Icon} from "semantic-ui-react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";

export default function ColorPicker({name, value, disabled, column, onChange}) {
    let [displayColorPicker, setDisplayColorPicker] = useState(false);
    let [preselectedValue, setPreselectedValue] = useState(false);
    return <div className={'color-picker-container'}>
        <div className={'color-picker-value-input'} style={{backgroundColor: `${value}`}} onClick={ () =>{
            if(!disabled)
                setDisplayColorPicker(!displayColorPicker);
        } }>
            {value === undefined || value === null || value === '' ? <img src={'https://static.tildacdn.com/tild6462-3132-4762-b361-383932363566/Empty.svg'} /> : ''}
            {value !== undefined && value !== null && value !== '' ? <div className={'color-picker-value-input-actions-container'}>
                    {disabled ? '' : <Icon name={'remove'} onClick={(e)=>{
                        e.stopPropagation();
                        onChange(null);
                    }}/>}
                <CopyToClipboard onCopy={()=>{
                    toast.info(getCurrentLangValue() == 'ru' ? 'Цвет скопирован в буфер обмена!' : 'The color is copied to the clipboard!');
                }} text={value.replace('#', '').toLowerCase()}>
                    <Icon name={'copy'} onClick={(e)=>{
                        e.stopPropagation();
                    }}/>
                </CopyToClipboard>
                
            </div> : ''}
        </div>
        {displayColorPicker ? <div className={'color-picker-popover'}>
            <div className={'color-picker-cover'}>
                <SketchPicker disableAlpha color={ preselectedValue } onChange={ (colorValue)=>{
                        setPreselectedValue(colorValue.hex);
                    }
                } />
                <div className={'color-picker-action-block'}>
                    <Button size={'mini'} onClick={()=>{setDisplayColorPicker(false);}} content={t('Cancel')}/>
                    <Button onClick={()=>{
                        onChange(preselectedValue);
                        setDisplayColorPicker(false);
                    }} 
                            size={'mini'}
                            primary 
                            content={t('Apply')}/>
                </div>
            </div>
        </div> : ''}
    </div>;
}