import React, {useState} from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";
import FolderViewerForInsertLink from "./components/FolderViewerForInsertLink/FolderViewerForInsertLink";

export default function KnowledgeBaseLinkInsert(props) {
    const [open, setOpen] = useState(false)
    
    return <span
        onClick={()=>{setOpen(true)}}
        className="button button-type-image"
        title="Insert Link"
        style={{position: 'relative'}}>
            <Icon name={'book'} style={{margin: '0'}}></Icon>
        <Modal
                open={open}
                onClose={() => setOpen(false)}
                size={"mini"}
                onClick={e=>{
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
            <Modal.Header>Выберите страницу или папку</Modal.Header>
            <Modal.Content>
                <FolderViewerForInsertLink
                    directory={'/'}
                    onSelect={page=>{
                        props.editor.insertText(`[${page.title}](${window.location.origin}/knowledgeBase/${page.url})`)
                        setOpen(false)
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)} basic>
                    Отмена
                </Button>
            </Modal.Actions>
        </Modal>
    </span>;
}