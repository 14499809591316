import React, {useEffect, useRef, useState} from 'react'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import {Icon, Input, Popup} from "semantic-ui-react";
import TextareaAutosize from 'react-textarea-autosize';
import {Ref} from "@stardust-ui/react-component-ref";
import t from "../../utils/translate";
import MdEditor from "react-markdown-editor-lite";
import {getElementsFromFormattedText} from "../../utils/textHelper";

const RecognitionInput = ({id, value, onChange, onChangePosition, placeholder, onFocus, onBlur, disabled = false, as='input', icon}) => {
    let input = useRef();
    let [startPosition, setStartPosition] = useState(0);
    let [endPosition, setEndPosition] = useState(0);
    useEffect(()=>{
        const interval = setInterval(() => {
            if(input.current !== undefined && 
                input.current !== null && 
                (startPosition !== input.current.selectionStart || endPosition !== input.current.selectionEnd) && 
                input.current === document.activeElement){
                
                setStartPosition(input.current.selectionStart);
                setEndPosition(input.current.selectionEnd);
                if(onChangePosition !== undefined)
                    onChangePosition({start: input.current.selectionStart, end: input.current.selectionEnd});
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    
    const { transcript, resetTranscript } = useSpeechRecognition();
    let [isRec, setIsRec] = useState(false)
    
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        alert('browserSupportsSpeechRecognition')
        return null
    }

    let capitalizeFirstLetter = (string) => {
        if(string == undefined || string == null) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let popup = <Popup
        on='click'
        //pinned
        open={isRec}
        position='top center'
        content={<div className={'microphone-popup-content'}>
            {((transcript === undefined || transcript === null || transcript === '') ? <span style={{opacity: '0.5'}}>{t('speakClearlyAndLoudly')}</span> : '')}{capitalizeFirstLetter(transcript)}
            <div className={'microphone-popup-content-buttons'}>
                <Icon
                    size='large'
                    disabled={(transcript === undefined || transcript === null || transcript === '')}
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        let before = value.substring(0, startPosition);
                        let after = value.substring(endPosition);
                        onChange({target: {value: before + capitalizeFirstLetter(transcript) + after}, text: before + capitalizeFirstLetter(transcript) + after});
                        setIsRec(false);
                    }}
                    color={"green"}
                    name={"check"}/>

                <Icon
                    size='large'
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        SpeechRecognition.startListening();
                    }}
                    color={"black"}
                    name={"refresh"}/>

                <Icon
                    size='large'
                    onClick={() => {
                        SpeechRecognition.stopListening();
                        setIsRec(false);
                    }}
                    color={"red"}
                    name={"close"}/>
            </div>
        </div>}
        trigger={<Icon
            onClick={() => {
                if(isRec)
                    SpeechRecognition.stopListening();
                else
                    SpeechRecognition.startListening();

                setIsRec(!isRec);
            }}
            className={'microphone-in-input'}
            color={isRec ? "red" : "grey"}
            name={isRec ? "circle thin" : "microphone"}/>} />;
    
    switch (as) {
        case 'input':
            return (<div style={{position: 'relative'}}><Input
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    iconPosition={icon == undefined || icon == null || icon == '' ? null : 'left'}
                    icon={icon == undefined || icon == null || icon == '' ? null : icon}
                    placeholder={placeholder}/>
                    {popup}</div>
            )

        case 'textArea':
            return (<div style={{position: 'relative'}}><Ref innerRef={input}><TextareaAutosize
                    id={id}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    spellcheck="false"
                    autocorrect="off"
                    onBlur={onBlur}/></Ref>
                    {popup}</div>
            )

        case 'markdowndEditor':
            return (<div style={{position: 'relative'}} className={'md-editor-container'}><MdEditor
                    ref={node => { if(node != null){input = node.nodeMdText}}}
                    value={value?.toString() ?? ''}
                    onChange={onChange}
                    renderHTML={text => {return getElementsFromFormattedText(text)}}
                    style={{ height: '200px' }}
                    allowPasteImage={false}
                    imageAccept={false}
                    view={false}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    canView={{
                        html: false,
                        fullScreen: false,
                    }}
                    plugins={[  'header',
                        'font-bold',
                        'font-italic',
                        'list-unordered',
                        'list-ordered',
                        'block-quote',
                        'block-wrap',
                        'block-code-inline',
                        'block-code-block',
                        'table',
                        'link',
                        'clear',
                        'logger',
                        'tab-insert'
                    ]}
                />
                    {popup}</div>
            )

    }
}
export default RecognitionInput