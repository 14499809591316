import React, { useEffect, useRef } from 'react';
import { useReport } from 'powerbi-report-component';
import './PowerBiReportViewer.css'

const PowerBiReportViewer = ({ accessToken, embedUrl, embedId }) => {
    const reportRef = useRef(null);
    const [report, setEmbed] = useReport();
    
    const myReportConfig = {
        embedType: 'report',
        tokenType: 'Embed',
        accessToken: accessToken,
        embedUrl: embedUrl,
        embedId: embedId,
        reportMode: "View", // "Edit"
        permissions: "View", // "All" (when using "Edit" mode)
        //reportMode: "Edit", // "Edit"
        //permissions: "All", // "All" (when using "Edit" mode)
        extraSettings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
            background: 1
        }
    };

    // important
    useEffect(() => {
        // call inside useEffect so the we have the reportRef (reference available)
        setEmbed(reportRef, myReportConfig);
    }, []);


    return (
        <div className="powerbi-container" /*style={{background: '#DDEBF6'}}*/>
            <div className="report" ref={reportRef} />
        </div>
    );
};

export default PowerBiReportViewer;