import React, {useState} from 'react';
import {
    Button,
    Icon,
    Modal,
    ModalContent,
    ModalHeader, Popup
} from 'semantic-ui-react'
import t, {getCurrentLangValue} from '../../../utils/translate'
import './LeftMenu.scss'
import {getElementsFromFormattedText} from "../../../utils/textHelper";
import appConfig from "../../../utils/appConfig";
import {calculateExpression} from "../../../utils/expressionHelper";
import { useHistory } from "react-router-dom";
import MenuIndicator from "../MenuIndicator/menuIndicator";
import AdministratorMenuItem from "../AdministratorMenuItem/administratorMenuItem";
import * as AuthHelper from "../../../utils/authHelper";

export default function LeftMenu({
                                     projectName, 
                                     views, 
                                     pages, 
                                     userMenu, 
                                     globalFilterElement, 
                                     globalFilterValues, 
                                     indicators,
                                     setDictionaryItemEditModalData,
                                     setDictionaryItemEditModalOpen,
                                     globalFilterIsFillOrNotExist}) {
    const history = useHistory();
    const configuration = appConfig()
    let location = window.location.pathname.replace('/', '');
    let initialState = location ?? configuration.dictionaries[0].strictNameMany;
    let [activeItem, setActiveItem] = useState(initialState);
    let userRole = AuthHelper.getRole();
    
    let globalFilter = configuration.info.globalFilter == null ? null : configuration.info.globalFilter.split('-')[0];
    let globalFilterViewName = configuration.info.globalFilter == null ? null : configuration.info.globalFilter.split('-')[1];
    
    let globalFilterDictionary;
    let globalFilterView;
    if(globalFilter !== null) {
        globalFilterDictionary = configuration
            .dictionaries
            .filter(x => x.nameSingle === globalFilter)[0];
        globalFilterView = globalFilterDictionary.views.filter(x => x.viewName === globalFilterViewName)[0]

    }
    
    const renderItem = (popupTitle, onClick, viewIf, iconName, isActive, filterForIndicators) => {
        if(!viewIf)
            return ''
        let indicatorCount = []
        if(filterForIndicators != null)
            indicatorCount = indicators.filter(filterForIndicators);
        return <Popup
            content={popupTitle}
            trigger={<div  className={'menu-item'+ (isActive ? ' active' : '')}
                           onClick={onClick}
            >
                <Icon
                    name={iconName}
                    size={"large"}
                />
                {indicatorCount.length > 0 ?
                    <MenuIndicator indicators={indicatorCount} /> : ''}
            </div>}
            basic
            inverted
            closeOnTriggerClick={false}
            position={"right center"}
        />
    }
    
    return <div className={'left-menu'}>
        <div className={'menu-item logo' + (configuration.info.miniLogoUrl != undefined && configuration.info.miniLogoUrl != null && configuration.info.miniLogoUrl != '' ? ' image' : '')}>
            {configuration.info.miniLogoUrl != undefined && configuration.info.miniLogoUrl != null && configuration.info.miniLogoUrl != '' ? 
                <img src={configuration.info.miniLogoUrl}/> 
                : projectName.substring(0, 1) }
        </div>
        {globalFilterIsFillOrNotExist ? pages.map(page => {
            let iconName = ''
            let url = ''
            let indicatorCount = []
            if(page.type == 0 ||page.type == 1 ||page.type == 2){
                let view = views.filter(x=>(x.strictNameMany + '.' +x.name) === page.name)[0]
                if (view !== undefined){
                    iconName = view.icon !== undefined && view.icon !== '' ? view.icon :'star';
                    url = `/${view.url}`;
                } 
                else
                    iconName = 'star'
                indicatorCount = indicators.filter(x=>x.view === view.name && x.dictionary === view.strictNameMany);
            }else{
                iconName = page.icon !== undefined && page.icon !== '' ? page.icon : 'star';
                if(page.type == 3)
                    url = `/iframe-${page.name}`
                if(page.type == 4){
                    let report = (appConfig().reports ?? []).filter(x=>(x.strictName) === page.name)[0];
                    url = `/report-${report.strictName}`
                }
                if(page.type == 5) {
                    let powerBiReport = (appConfig().powerBiReports ?? []).filter(x=>(x.name) === page.name)[0];
                    url = `/powerBi-${powerBiReport.name}`
                }
                if(page.type == 6)
                    url = `/pdf-${page.name}`
            }
            return <Popup
                content={(getCurrentLangValue() === 'ru' ? page.ruName: page.enName)}
                trigger={<div 
                    className={'menu-item' + (url.indexOf(activeItem) > -1 ? ' active' : '')}
                    onClick={()=>{
                        history.push(url);
                        setActiveItem(url);
                    }}
                >
                    <Icon
                        name={iconName}
                        size={"large"}
                    />
                    {indicatorCount.length > 0 ?
                        <MenuIndicator indicators={indicatorCount} /> : ''}
                </div>}
                basic
                inverted
                closeOnTriggerClick={false}
                position={"right center"}
            />
        }) : ''}
        <div className={'bottom'}>
            { userRole === 'Administrator' ? <div className={'menu-item admin-tools'}>
                <AdministratorMenuItem
                    setActiveItem={(val) => {setActiveItem(val); }}
                    isActive={activeItem === 'NotificationViewForAdministrator'}
                />
            </div> : ''}
            {renderItem(t("knowledgebase_menu_link_title"), ()=>{
                history.push('/knowledgeBase');
                setActiveItem('/knowledgeBase');
            }, configuration.info.haveKnowledgeBase, 'book', '/knowledgeBase'.indexOf(activeItem) > -1, null)}
            {renderItem(t("meets_menu_link_title"), ()=>{
                history.push('/meets');
                setActiveItem('/meets');
            }, configuration.info.haveMeets, 'video camera', '/meets'.indexOf(activeItem) > -1, null)}
            {renderItem(t("email_menu_link_title"), ()=>{
                history.push('/emailClient');
                setActiveItem('/emailClient');
            }, configuration.info.haveEmailClient, 'mail', '/emailClient'.indexOf(activeItem) > -1, null)}
            
            {renderItem(t("calendar_menu_link_title"), ()=>{
                history.push('/calendar');
                setActiveItem('/calendar');
            }, configuration.info.haveCalendar, 'calendar alternate outline', '/calendar'.indexOf(activeItem) > -1, x => x.isCalendar)}
            {renderItem(t("chat_menu_link_title"), ()=>{
                history.push('/chat');
                setActiveItem('/chat');
            }, configuration.info.haveChat, 'chat', '/chat'.indexOf(activeItem) > -1, x => x.isChat)}
            {renderItem(t("help_menu_link_title"), ()=>{
                window.open(configuration.info.helpResource, '_blank')
            }, configuration.info.helpResource !== '' && configuration.info.helpResource !== undefined && configuration.info.helpResource !== null, "question circle outline")}

            {globalFilterElement != null && globalFilterElement != undefined && globalFilterDictionary != null ? <div className={'menu-item global-filter'}>
                {globalFilterElement}
                {globalFilterElement !== null &&
                globalFilterValues.length === 0 ? <Popup
                    content={`${t('Create')} ${t('NameSingle_' + globalFilterDictionary.nameSingle)}`}
                    inverted
                    basic
                    position={"right center"}
                    trigger={<Button
                        compact
                        icon={globalFilterView.icon !== undefined && globalFilterView.icon !== '' ? globalFilterView.icon :'star'}
                        color='blue'
                        onClick={e => {
                            e.stopPropagation();
                            setDictionaryItemEditModalData(undefined);
                            setDictionaryItemEditModalOpen(true);
                        }}/>}
                />: ''}
            </div>: ''}
            <div className={'menu-item profile'}>
                {userMenu}
            </div>
        </div>
    </div>;
}