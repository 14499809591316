import React, {useEffect, useState} from 'react';
import {Loader} from "semantic-ui-react";
import Chart from "react-apexcharts";

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){

        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);

        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];

        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);

        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

const Indicator = ({
                       getData,
                       pageIndex,
                       elementIndex,
                       element,
                       width,
                       height,
                       onePercentInPixelForWidth,
                       onePercentInPixelForHeight,
                       filtersValues
                   }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);

    useEffect(() => {
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setLabels(values.map(x=>x[0]));
                setSeries(values.map(x=>parseFloat(x[1]) ));
                setIsLoading(false);
            });
        setIsLoading(false);
    }, []);

    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <Chart
            options={{
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.4,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 50, 53, 91]
                    },
                },
                labels: ['Average Results'],
            }}
            series={[76]}
            type="radialBar"
            width={width}
        />}
    </div>);
}

export default Indicator