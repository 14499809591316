import React, {useEffect, useRef, useState} from 'react';
import './ProgressFormInput.scss'
import {Button, Progress} from "semantic-ui-react";
import useDynamicState from "../../../utils/useDynamicState";

function getInitialState(value) {
    return parseFloat((value == undefined || value == null || value == '{}' || value == '' ? '{}' : value) == '{}' ? 0 : value);
}

export default function ProgressFormInput({name, value, placeholder, column, onChangeFieldValue, readOnly}) {
    const [minusClicked, setMinusClicked, minusClickedRef] = useDynamicState(false)
    const [plusClicked, setPlusClicked, plusClickedRef] = useDynamicState(false)
    const [lastStartChangeTime, setLastStartChangeTime, lastStartChangeTimeRef] = useDynamicState(null)
    const [val, setVal, valRef] = useDynamicState(getInitialState(value))
    const progressElementRef = useRef()
    
    useEffect(()=>{
        let interval = setInterval(()=>{
            if(minusClickedRef.current || plusClickedRef.current) {
                let newValue;
                let step = 0.5;
                if(lastStartChangeTimeRef.current != null &&
                    (new Date().getTime() - lastStartChangeTimeRef.current.getTime()) > 1000){
                    step = ((new Date().getTime() - lastStartChangeTimeRef.current.getTime()) / 200) * step
                    step = Math.round(step / 0.25) * 0.25
                }
                if(minusClickedRef.current)
                    newValue = valRef.current - step;
                
                if(plusClickedRef.current)
                    newValue = valRef.current + step;
                
                if(newValue > 100)
                    newValue = 100
                if(newValue < 0)
                    newValue = 0
                onChangeFieldValue(name, newValue)
                setVal(newValue)

                if(lastStartChangeTimeRef.current == null)
                    setLastStartChangeTime(new Date())
            } else {
                setLastStartChangeTime(null)
            }
        }, 100);
        return () => {clearInterval(interval)}
    }, [])
    
    useEffect(()=>{
        setVal(getInitialState(value))
    }, [value])
    
    return <div style={{display: 'flex', alignItems: 'center'}}>
        {readOnly ? '' : <Button
            style={{height: '24.5px', width: '24.5px', padding: 0}}
            onMouseDown={()=>{setMinusClicked(true)}}
            onMouseLeave={()=>{setMinusClicked(false)}}
            onMouseUp={()=>{setMinusClicked(false)}}
        >-</Button>}
        <div
            style={{
                width: readOnly ? '100%' : '90%',
            }}
            ref={progressElementRef}>

            <Progress
                style={{
                    margin: 0,
                    cursor: readOnly ? 'default' : 'pointer'
                }}
                percent={val}
                onClick={e => {
                    if(readOnly)
                        return
                    let rect = e.target.getBoundingClientRect();
                    let x = e.clientX - rect.left; //x position within the element.
                    let y = e.clientY - rect.top;  //y position within the element.
                    let a = progressElementRef.current
                    let width = a.clientWidth

                    let number = Math.round((x / width) * 100);
                    onChangeFieldValue(name, number)
                }}
                indicating
                progress />
        </div>
        {readOnly ? '' : <Button
            style={{height: '24.5px', width: '24.5px', marginLeft: '0.25em', marginRight: 0, padding: 0}}
            onMouseDown={()=>{setPlusClicked(true)}}
            onMouseLeave={()=>{setPlusClicked(false)}}
            onMouseUp={()=>{setPlusClicked(false)}}
        >+</Button>}
    </div> 
}