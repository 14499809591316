import {
    Dropdown,
    Icon
} from "semantic-ui-react";
import t from "../../../utils/translate";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import './administratorMenuItem.css'
import api from "../../../utils/api";
import {ActionButton} from "../../../shared/components/ActionButton";
import ParametersModal from "./components/parametersModal";
import appConfig from "../../../utils/appConfig";

export default function AdministratorMenuItem({setActiveItem, isActive}) {
    let [isOpenSystemParameters, setIsOpenSystemParameters] = useState(false);
    let [saveIsLoading, setSaveIsLoading] = useState(false);
    let [tasks, setTasks] = useState(null);
    let [parameters, setParameters] = useState([]);
    const configuration = appConfig();

    useEffect(()=>{
        api().getAllTasks().then(data=> {
            setTasks(data);
        });

        api()
            .getParameters()
            .then(getParametersResp=> {
                setParameters(getParametersResp.metadata);
            })
    }, []);
    return <><Dropdown icon={'cogs'} item className={('settings-menu-item ' + (isActive ? 'active' : ''))}>
        <Dropdown.Menu direction={configuration.info.leftMenu ? 'right' : 'left'}>
            <Dropdown.Item as={Link} to={`/NotificationViewForAdministrator`} onClick={() => {setActiveItem('NotificationViewForAdministrator')}}><Icon name={'bell outline'} /> {t("admin_menu_system_events")}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={Link} to={`/RobotTaskViewForAdministrator`} onClick={() => {setActiveItem('RobotTaskViewForAdministrator')}}><Icon name={'python'} /> {t("admin_menu_background_tasks")}</Dropdown.Item>
            <Dropdown.Divider />
            <>
                {tasks == null ?
                    <Dropdown.Item disabled={true}><p style={{width: '130px', whiteSpace: 'normal', fontSize: 'smaller'}}>{t('admin_menu_no_recurring_tasks_available')}</p></Dropdown.Item> :
                    <Dropdown
                        text={<><Icon name={'clock outline'} /> {t("admin_menu_tasks")}</>}
                        pointing='left'
                        className='link item public-pages-menu-item' direction={configuration.info.leftMenu ? 'right' : 'left'}>
                        <Dropdown.Menu>
                            {tasks.map(task =>
                            <ActionButton
                                for={'task'}
                                as={'dropdown'}
                                action={task}
                                afterInvoke={()=>{}}
                            />
                            )}
                        </Dropdown.Menu>
                    </Dropdown>}
            </>
            {appConfig().publicForms.length > 0 ? <>
                <Dropdown.Divider />
                <Dropdown
                    text={<><Icon name={'external'} /> {t("admin_menu_public_pages")}</>}
                    pointing='left'
                    className='link item public-pages-menu-item' direction={configuration.info.leftMenu ? 'right' : 'left'}>
                    <Dropdown.Menu>
                        {appConfig().publicForms.map(f=> <Dropdown.Item onClick={()=>{window.open(f.url, "_blank")}} icon={'star outline'} text={f.nameRu} />)}
                    </Dropdown.Menu>
                </Dropdown>
            </> : ''}
            {appConfig().parameters.length > 0 ? <>
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={(e) => {e.stopPropagation();setIsOpenSystemParameters(true);}}>
                    <Icon name={'sliders'} /> {t("admin_menu_parameters")}
                </Dropdown.Item>
            </> : ''}
        </Dropdown.Menu>
    </Dropdown>
    <ParametersModal isOpen={isOpenSystemParameters} setIsOpen={setIsOpenSystemParameters} />
    </>;
}