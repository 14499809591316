import React from 'react';
import {
    Label
} from 'semantic-ui-react';
import t from "../../../utils/translate";
import './licenseInfoAlert.css'
import appConfig from "../../../utils/appConfig";

export default function LicenseInfoAlert() {
    let show = appConfig().info.isDemo; 
    let timeLimit = appConfig().info.timeLimit;
    
    if(timeLimit !== undefined && show){
        
        let date = timeLimit.split('T')[0];
        
        let day = date.split('-')[2];
        let month = date.split('-')[1];
        let year = date.split('-')[0];
        let dateTime = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        let diff = new Date().getTime() - dateTime.getTime();
        let days = Math.ceil(diff / (1000 * 3600 * 24));
        
        if(days < -15) 
            return <></>;
        
        return (show ?
                <div className={'license-info-alert'}>
                    <img alt={'Report.ms'} src={'https://static.tildacdn.com/tild3930-6162-4566-b034-316564366631/companyLogo.svg'}/>
                    <p>{t('LicenseInfoMsg')} <b>{`${day}.${month}.${year}`}</b>. <Label as='a' basic target={'_blank'} href={"https://report.ms/prices"}>
                        {t('LicenseInfoBtn')}
                    </Label></p>
                </div> : ''
        );
    }
    return <></>;
    
}