import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import api from "./utils/api";
import * as AuthHelper from "./utils/authHelper";
import appConfig, {setAppConfig} from "./utils/appConfig";
import {getCurrentLangValue} from "./utils/translate";

let getConfigurationPromise;

let setRefresh = (error)=>{
    setTimeout(()=>{ window.location = window.location;}, 2000);
    console.log('Fetch error message: ', error.message);
};

try {
    getConfigurationPromise = api()
        .getConfiguration();
} catch (error) {
    setRefresh(error);
}
if(getConfigurationPromise != null){
    getConfigurationPromise
        .then(configuration => {
            if(configuration.errorMessage !== undefined){
                setRefresh({message: configuration.errorMessage});
                return;
            }
            setAppConfig(configuration)

            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = appConfig().info.favIconUrl;
            document.getElementsByTagName('head')[0].appendChild(link);
            document.title = getCurrentLangValue() === 'ru' ? appConfig().info.nameRu : appConfig().info.nameEn;
        })
        .then(()=>{
            if(appConfig() !== undefined){
                let userId = AuthHelper.getId();
                if(userId !== undefined && userId !== null){
                    return api().getById("Users", userId).then(user=> {window.user = user;})
                }
            }
        })
        .then(()=> {
            if(appConfig() !== undefined){
                ReactDOM.render(
                    <App />,
                    document.getElementById('root')
                );
            }
        })
}