import * as PropTypes from "prop-types";
import {Button, Dropdown, Icon, Loader, Message} from "semantic-ui-react";
import t from "../utils/translate";
import {ActionButton} from "./components/ActionButton";
import React, {useState} from "react";
import api from "../utils/api";
import {DeleteDictionaryItem} from "./components/DeleteDictionaryItem";
import {toast} from "react-toastify";

export function ActionInvokerForGrid(props) {
    let [actions, setActions] = useState([]);
    let [canDelete, setCanDelete] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    let [isLoaded, setIsLoaded] = useState(false);
    
    let dropdown = <Dropdown item icon='ellipsis horizontal' floating direction='left' className={'actions-for-' + props.id}>
        <Dropdown.Menu>
            {actions.length === 0 && !canDelete ? <Message
                warning
                header={t('No available')}
                content={t('There are no actions available for this unit.')}
            /> : ''}
            {actions.length > 0 ? <Dropdown.Header content={t('actions_lbl_name')} /> : ''}
            {actions.length > 0 ? <Dropdown.Divider /> : ''}
            {actions.map(button => {
                
                return <ActionButton
                    for={'action'}
                    as={'dropdown'}
                    id={props.id}
                    action={button}
                    dictionaryStrictNameMany={props.dictionaryStrictNameMany}
                    afterInvoke={()=>{setIsLoaded(false);props.afterInvoke();}}
                />
            })}
            {canDelete ? <>
                {actions.length > 0 ? <Dropdown.Divider /> : ''}
                <DeleteDictionaryItem as={'dropdown'} onConfirm={()=>{
                    api()
                        .deleteDictionaryItem(props.dictionaryStrictNameMany, props.id)
                        .then(resp => {
                            toast.success(resp.successMessage);
                            props.afterInvoke();
                        });
                }}/>
            </>: ''}
        </Dropdown.Menu>
    </Dropdown>;
    let icon = <Icon name={'ellipsis horizontal'} onClick={(e) => {
        setIsLoading(true);
        api()
            .getActionsForId(props.dictionaryStrictNameMany, props.id)
            .then(resp => {
                let actionsFromServer = resp.actions.filter(x=>!x.hideInRowActions);
                setActions(actionsFromServer);
                setCanDelete(resp.canDelete);
                setIsLoading(false);
                setIsLoaded(true);
                if(actionsFromServer.length == 0 && !resp.canDelete)
                    toast.info(t('actions_lbl_name_for_no_available'));
                setTimeout(()=>{
                    function eventFire(el, etype){
                        if (el.fireEvent) {
                            el.fireEvent('on' + etype);
                        } else {
                            var evObj = document.createEvent('Events');
                            evObj.initEvent(etype, true, false);
                            el.dispatchEvent(evObj);
                        }
                    }

                    let classNames = 'actions-for-' + props.id;
                    //alert(classNames);
                    eventFire(document.getElementsByClassName(classNames)[0], 'click');
                }, 100);
            });
        e.stopPropagation();
    }}/>;
    
    if(isLoading)
        return <Loader active inline size={"mini"}/>;
    return isLoaded ?  dropdown : icon;
}

ActionInvokerForGrid.propTypes = {
    id: PropTypes.any,
    dictionaryStrictNameMany: PropTypes.any,
    afterInvoke: PropTypes.func
};