import api from "../../utils/api";
import reportColumnTypes from "../../utils/columnTypes"
import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Dropdown,
    Form,
    Grid,
    Icon,
    Message,
    Modal,
    Popup,
    Tab,
    Table
} from "semantic-ui-react";
import {toast} from "react-toastify";
import t, {getCurrentLangValue} from "../../utils/translate";
import columnValueGetter from "../../utils/columnValueGetter";
import {defineIfOneInnerTableInColumn, getMenuItemName} from "../../utils/TabHelper";
import hash from 'object-hash';
import {DeleteDictionaryItem} from "./DeleteDictionaryItem";
import {getElementsFromFormattedText, getForSelectRestriction} from "../../utils/textHelper";
import resolveColumnToElement from "../../utils/resolveColumnToElement";
import {ActionButton} from "./ActionButton";
import {calculateExpression} from "../../utils/expressionHelper";
import {ActionInvokerForGrid} from "../ActionInvokerForGrid";
import {ConfigurationContext} from "../../utils/configurationContext";
import appConfig from "../../utils/appConfig";
import './DictionaryItemEdit.scss'
import Comments from "./Comments/Comments";



export default function DictionaryItemEdit({children, 
                                               dictionaryItemMetaDescription, 
                                               data,
                                               initFormData = [],
                                               onSaveEvent,
                                               onActionEvent,
                                               onDeleteEvent,
                                               overrideSave = null, 
                                               isOpenProp = false, 
                                               isOpenPropSetter = null,
                                               hiddenFields = [],
                                               prefillFields = [],
                                               view = null,
                                               parentData
                                           }) {
    
    let [isOpen, setIsOpen] = useState(isOpenProp);
    let [formData, setFormData] = useState([]);
    let [formDataBeforeEdit, setFormDataBeforeEdit] = useState([]);
    let [changedFields, setChangedFields] = useState([]);
    let [actions, setActions] = useState([]);
    let [dictionaryItemsForSelects, setDictionaryItemsForSelects] = useState([]);
    let [enumItemsForSelects, setEnumItemsForSelects] = useState([]);
    let [itemActions, setItemActions] = useState([]);
    let [saveIsLoading, setSaveIsLoading] = useState(false);
    let [canDelete, setCanDelete] = useState(false);
    let [errors, setErrors] = useState([]);
    let [isReadyForRender, setIsReadyForRender] = useState(false);
    const context = useContext(ConfigurationContext);
    let [lastTimeResiveTostFromServer, setLastTimeResiveTostFromServer] = useState(null);
    let [isOpenComments, setIsOpenComments] = useState(false);
    let [focusColumn, setFocusColumn] = useState(null);
    
    let reportTypes = reportColumnTypes();

    let dictionaryItemSingleName = 'NameSingle_' + dictionaryItemMetaDescription.nameSingle;
    dictionaryItemSingleName = view !== null && view.nameSingle !== null ? 'ViewNameSingle_' + view.nameSingle :  dictionaryItemSingleName;

    let columns = dictionaryItemMetaDescription.columns
        .filter(col => !hiddenFields.includes(col.name));
    //debugger;
    let columnsWithoutHiddenAndGlobalFiler = columns
        .filter(col => {
            if(appConfig().info.globalFilter !== null && (parentData == null || parentData === undefined)) {
                if(localStorage.getItem('GlobalFilterValue') !== null){
                    let filterDictionary = appConfig()
                        .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];
                    let filterDictionaryView = appConfig()
                        .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[1])[0];

                    return !(col.type === reportTypes.linkToDictionary &&
                        col.linkTo === filterDictionary.strictNameMany);
    
                    
                }else{
                    return true;
                }
            }else
                return true;
        })
        .filter(column => !column.hideInForm)
        .filter(column => {
            if(column.hideIf !== undefined && column.hideIf !== null && column.hideIf !== '') {
                var hideIf = column.hideIf;

                for (var i = 0;i < appConfig().enums.length; i++){
                    for (var j = 0;j < appConfig().enums[i].values.length; j++){
                        hideIf = hideIf.replaceAll(`"${appConfig().enums[i].nameSingle}"."${appConfig().enums[i].values[j].nameSingle}"`, `"${appConfig().enums[i].values[j].nameSingle}"`);
                    }
                }

                dictionaryItemMetaDescription.columns.map(col =>
                    {
                        var fdi = formData.filter(fd => fd.name === col.name)[0];
                        hideIf = hideIf
                            .replaceAll('"' + col.name + '"', '"' + (fdi?.value ?? 'null')  + '"')
                            //.replaceAll(col.name, fdi?.value ?? 'null')
                        ;
                    }
                );

                hideIf = hideIf.replaceAll('"Id"', data !== undefined && data.Id !== undefined ? ("'" + data.Id + "'") : 'null');

                /*for (var i = 0;i < formData.length; i++){
                    hideIf = hideIf.replaceAll(formData[i].name, formData[i].value);
                }*/

                let result = false;

                try {
                    result = eval(hideIf);
                }catch (e) {
                    console.log('hideIf ERROR');
                    console.log(e);
                }
                console.log(hideIf);
                return result !== true;
            }
            return true;
        });

    let [globalFilterColumn, setGlobalFilterColumn] = useState(null);

    let setCalculatedFields = (newFormData) => {
        dictionaryItemMetaDescription
            .columns
            .filter(x=>x.type === 'calculate-field:string' || x.type === 'calculate-field:enum')
            .map(column => {
                console.log("calculate-field: " + column.name);
                let calculatedExpression = '';
                if(column.expressionForCalculate !== undefined && column.expressionForCalculate !== null && column.expressionForCalculate !== '') {
                    calculatedExpression = calculateExpression(column.expressionForCalculate, dictionaryItemMetaDescription, newFormData, data);
                }
                let item = newFormData.filter(x=>x.name === column.name)[0];
                if(item === undefined){
                    item = {name: column.name, value: calculatedExpression};
                    newFormData.push(item);
                }else{
                    item.value = calculatedExpression;
                }
            });
    };
    
    useEffect(()=> {
        console.log('useEffect, isOpenProp: ' + isOpenProp.toString())
        if(isOpenProp)
            onOpen();
    }, []);

    useEffect(()=>{
        if(context.lastTimeResiveTostFromServer != null && 
            context.lastTimeResiveTostFromServer != lastTimeResiveTostFromServer &&
            data !== undefined && 
            data.Id !== undefined) {
            setLastTimeResiveTostFromServer(context.lastTimeResiveTostFromServer)
            if(isOpen){
                onOpen(changedFields)
            }
        }
    }, [context])
    
    let close = () => {
        setIsOpen(false);
        if(isOpenPropSetter !== null)
            isOpenPropSetter(false);
    }
    
    let save = (e) => {
        e.stopPropagation();
        let requiredColumns = columnsWithoutHiddenAndGlobalFiler
            .filter(x=>(x.isRequiredForCreate && (data == undefined || (data != undefined && data.Id == undefined))) || 
                (x.isRequiredForUpdate && (data != undefined || data.Id != undefined)));
        let requiredErrors = [];
        for (let i = 0; i < requiredColumns.length; i++){
            let requiredColumn = requiredColumns[i];
            let formDataItemForRequiredColumn = formData.filter(x=>x.name === requiredColumn.name)[0] ?? null;
            if(formDataItemForRequiredColumn == null || formDataItemForRequiredColumn.value == null || formDataItemForRequiredColumn.value == '')
                requiredErrors.push(requiredColumn);
        }
        if(requiredErrors.length > 0) {
            toast.error(<div>
                <p>{t('Fill_required_fields')}:</p>
                <ul>
                    {requiredErrors.map(col=><li><b>{(getCurrentLangValue() === 'ru' ? col.ruName : col.enName)}</b></li>)}
                </ul>
            </div>);
            setErrors(requiredErrors.map(x=>x.name));
            return;
        }
        let innerTableColumns = columns.filter(col=>col.type === reportTypes.innerTable);
        
        let dataForSave = formData.map(formDataItem => {
            var column = innerTableColumns.filter(col => col.name === formDataItem.name)[0] ?? null;
            if(column != null){
                return {name: formDataItem.name, value: formDataItem.value.map(formDataItemValue => {
                        let a = column.linkTo;
                        var innerTableMetaDescription = appConfig().dictionaries.filter(x=>x.strictNameMany === a)[0];
                        let linkToDictionaryColumns = innerTableMetaDescription.columns.filter(x=>x.type === reportTypes.linkToDictionary);
                        for(var i = 0; i < linkToDictionaryColumns.length; i++){
                            let linkToDictionaryColumn = linkToDictionaryColumns[i];
                            if(linkToDictionaryColumn.type === reportTypes.linkToDictionary){
                                
                            }
                            
                        }
                        
                        let resultFormDataItemValue = {};
                        
                        for (var prop in formDataItemValue) {
                            if(linkToDictionaryColumns.filter(x=>x.name === prop).length > 0 && formDataItemValue[prop]?.id !== undefined)
                                resultFormDataItemValue[prop] = formDataItemValue[prop].id;
                            else
                                resultFormDataItemValue[prop] = formDataItemValue[prop];
                        }

                        return resultFormDataItemValue;
                    })};
            }else{
                return formDataItem;   
            }
        });
        console.log('save:', dataForSave);
        console.log(dataForSave);
        if(overrideSave === null) {
            setSaveIsLoading(true);
            api()
                .saveOrCreate(dictionaryItemMetaDescription.strictNameMany, dataForSave)
                .then(resp => {
                    if(resp.errorMessage !== undefined && resp.errorMessage !== null && resp.errorMessage !== ''){
                        toast.error(<div>{getElementsFromFormattedText(resp.errorMessage)}</div>);
                    }else{
                        if(window.location.host === 'configurator.report.ms' && dictionaryItemMetaDescription.strictNameMany === "Projects")
                            window.ym(65148439,'reachGoal','create_project_in_configurator');

                        toast.success(resp.successMessage);
                        if(onSaveEvent !== undefined){
                            onSaveEvent(resp.id);
                        }
                        close();
                    }
                    setSaveIsLoading(false);
                });            
        } else {
            let targetObj = {};
            for (let i = 0; i < dataForSave.length; i++) {
                let col = dictionaryItemMetaDescription.columns.filter(col => col.name === dataForSave[i].name)[0] ?? {type: "error"};
                let options = dictionaryItemsForSelects.filter(z => z.dictionaryName === col.linkTo)[0]?.options ?? [];
                switch (col.type) {
                    case reportTypes.linkToDictionary:
                        let val = dataForSave[i].value;
                        if (val !== undefined && val !== null && val.id !== undefined && val.id !== null && val.id !== '')
                            val = val.id;
                        let filterElement = options.filter(o => o.value === val)[0];
                        if(filterElement === undefined)
                            continue;
                        targetObj[dataForSave[i].name] = {name: filterElement.text, id: val};
                        break;
                    case reportTypes.arrayLinkToDictionary:
                        if(dataForSave[i].value === undefined || dataForSave[i].value.map === undefined)
                            continue;
                        
                        targetObj[dataForSave[i].name] = dataForSave[i].value.map(x => {
                            let val = x;
                            if (val.id !== undefined && val.id !== null && val.id !== '')
                                val = val.id;
                            return {name: options.filter(o => o.value === val)[0].text, id: val};
                        });
                        break;
                    default:
                        targetObj[dataForSave[i].name] = dataForSave[i].value;
                        break;
                }
            }
            overrideSave(targetObj);
            close();
        }
    };

    let deleteThis = () => {
        api()
            .deleteDictionaryItem(dictionaryItemMetaDescription.strictNameMany, data.Id)
            .then(resp => {
                toast.success(resp.successMessage);
                close();
                if(onDeleteEvent !== undefined){
                    onDeleteEvent(resp.id);
                }
            });
    };

    let updateDictionaryItemsForSelectsForColumn = (dictionaryItemMetaDescription, column, newFormData, force) => {
        //if(column.linkTo === "DictionaryFields")
            //debugger;
        let overrideFilter = getForSelectRestriction(column.selectRestriction, dictionaryItemMetaDescription, column.linkTo, data ?? newFormData);
        let item = dictionaryItemsForSelects.filter(item => item.fieldName === column.name && item.dictionaryName === column.linkTo)[0];
        let filter = [];
        if(appConfig().info.globalFilter !== null) {
            if(localStorage.getItem('GlobalFilterValue') !== null){
                let filterDictionary = appConfig()
                    .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];

                let linkToDictionary = appConfig()
                    .dictionaries.filter(x=>x.strictNameMany === column.linkTo)[0];
                //debugger;
                let globalFilterColumn = linkToDictionary.columns.filter(column => column.type === reportTypes.linkToDictionary &&
                    column.linkTo === filterDictionary.strictNameMany)[0] ?? null;
                //setGlobalFilterColumn(globalFilterColumn);

                if(globalFilterColumn !== null)
                    filter.push({name: globalFilterColumn.name, value: localStorage.getItem('GlobalFilterValue')});
            }
        }

        if((item !== undefined && overrideFilter !== '' && overrideFilter !== null && overrideFilter !== item.filter) || (force)){
            api()
                .getListForSelect(column.linkTo, 0, overrideFilter === '' || overrideFilter === null ?  filter : [], overrideFilter)
                .then((data)=> {
                    let options = data.map(x=> { return {key: x.id, text: x.name, value: x.id};});
                    item.filter = overrideFilter;
                    item.options = options;
                    dictionaryItemsForSelects = dictionaryItemsForSelects.filter(item => !(item.fieldName === column.name && item.dictionaryName === column.linkTo));
                    dictionaryItemsForSelects.push(item);
                    setDictionaryItemsForSelects(dictionaryItemsForSelects);
                })
        }
    }
    
    let onChangeFieldValue = (name, value) => {
        let column = dictionaryItemMetaDescription.columns.filter(x => x.name === name)[0];
        //toast(name + ':' + value);
        let item = formData.filter(x=>x.name === name)[0];
        if(item === undefined){
            if(column.type !== 'innerTable')
                item = {name: name, value: value}
            else
                item = {name: name, value: []}
        }
        if(column.type !== 'innerTable')
            item.value = value;
        else
            item.value.push(value);
        
        let newFormData = [];
        
        let others = formData.filter(x=>x.name !== name)
        
        for (let a in others)
            newFormData.push({name: others[a].name, value: others[a].value});

        newFormData.push(item);

        setCalculatedFields(newFormData);
        
        dictionaryItemMetaDescription
            .columns
            .filter(column=> column.type === reportTypes.linkToDictionary || column.type === reportTypes.arrayLinkToDictionary)
            .map(column => {
                updateDictionaryItemsForSelectsForColumn(dictionaryItemMetaDescription, column, newFormData);
        });


        let itemBeforeEdit = formDataBeforeEdit.filter(x=>x.name === name)[0];
        let valueBeforeEdit = itemBeforeEdit?.value ?? '';
        if(valueBeforeEdit == value || ((value == '' || value == null) && (valueBeforeEdit == '' || valueBeforeEdit == null))){
            if(changedFields.includes(name)){
                setChangedFields(changedFields.filter(x=>x != name));
            }
        }else{
            if(!changedFields.includes(name)){
                let temp = changedFields.map(x=>x)
                temp.push(name)
                setChangedFields(temp);
            }
        }
        return setFormData(newFormData);
    };
    
    let onOpen = (saveOldValueFields) => {
        //debugger;
        //if(e !== undefined && e !== null)
        //    e.stopPropagation();
        setIsOpen(true);
        if(isOpenPropSetter !== null)
            isOpenPropSetter(true);
        
        let formDataTemp = initFormData;
        
        let setPrefillFields = (formDataTemp) => {
            if(prefillFields.length > 0){
                for (var i = 0; i < prefillFields.length; i++) {
                    if(formDataTemp.filter(x=>x.name === prefillFields[i].name).length === 0)
                        formDataTemp.push({name: prefillFields[i].name, value: prefillFields[i].value});
                }
            }

            let preselectedColumns = dictionaryItemMetaDescription
                .columns.filter(x=>x.preselected);
            if(preselectedColumns.length > 0){
                for (var i = 0; i < preselectedColumns.length; i++) {
                    if(formDataTemp.filter(x=>x.name === preselectedColumns[i].name).length === 0)
                        {
                            let newVar;
                            if(preselectedColumns[i].type === reportTypes.bool)
                                newVar = preselectedColumns[i].type === reportTypes.bool;
                            
                            if(preselectedColumns[i].type === reportTypes.linkToEnum){
                                let enumForCol = appConfig().enums.filter(x=>x.nameSingle === preselectedColumns[i].linkTo)[0];
                                newVar = enumForCol.values
                                    .filter(z => preselectedColumns[i].excludeValues == null || !preselectedColumns[i].excludeValues.includes(z.nameSingle))
                                    [0].nameSingle;
                            }
                            formDataTemp.push({name: preselectedColumns[i].name, value: newVar});
                        }
                }
            }

            if(appConfig().info.globalFilter !== null && (parentData == null || parentData === undefined)) {
                if(localStorage.getItem('GlobalFilterValue') !== null){
                    let filterDictionary = appConfig()
                        .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];

                    let globalFilterColumn = columns.filter(column => column.type === reportTypes.linkToDictionary &&
                        column.linkTo === filterDictionary.strictNameMany)[0] ?? null;
                    //setGlobalFilterColumn(globalFilterColumn);

                    if(globalFilterColumn !== null)
                        formDataTemp.push({name: globalFilterColumn.name, value: localStorage.getItem('GlobalFilterValue')});
                }
            }
            if(saveOldValueFields != undefined && saveOldValueFields.length != undefined && saveOldValueFields.length > 0){
                formDataTemp = formDataTemp.filter(x=>!saveOldValueFields.includes(x.name))
                saveOldValueFields.map(fieldName=>{
                    let oldValue = formData.filter(x=>x.name === fieldName)[0];
                    if(oldValue !== undefined)
                        formDataTemp.push(oldValue)
                })
            }
            setFormData(formDataTemp);
            //debugger
            setFormDataBeforeEdit(JSON.parse(JSON.stringify(formDataTemp)));
            //debugger;
            console.log('setFormData onOpen :');
            console.log(formDataTemp);
        };
        
        if(data !== undefined && data.Id !== undefined)
            api()
                .getById(dictionaryItemMetaDescription.strictNameMany, data.Id)
                .then(resp => {
                    for(var key in resp)
                        formDataTemp.push({name: key, value: resp[key]});

                    setPrefillFields(formDataTemp);
                    //setFormData(formDataTemp);
                })
                .then(()=>{
                    return api().getActionsForId(dictionaryItemMetaDescription.strictNameMany, data.Id);
                })
                .then(resp => {
                    setActions(resp.actions);
                    setCanDelete(resp.canDelete);
                });
        else{
            if(data !== undefined){
                for(var key in data)
                    formDataTemp.push({name: key, value: data[key]});
            }
            setPrefillFields(formDataTemp);
        }

        setCalculatedFields(formDataTemp);
        //setFormData(formDataTemp);
        columns.map(column => {
            if(column.type === reportTypes.linkToDictionary || column.type === reportTypes.arrayLinkToDictionary) {
                let filter = [];
                if(appConfig().info.globalFilter !== null) {
                    if(localStorage.getItem('GlobalFilterValue') !== null){
                        let filterDictionary = appConfig()
                            .dictionaries.filter(x=>x.nameSingle === appConfig().info.globalFilter.split('-')[0])[0];
                        
                        let linkToDictionary = appConfig()
                            .dictionaries.filter(x=>x.strictNameMany === column.linkTo)[0];
                        //debugger;
                        let globalFilterColumn = linkToDictionary.columns.filter(column => column.type === reportTypes.linkToDictionary &&
                            column.linkTo === filterDictionary.strictNameMany)[0] ?? null;
                        //setGlobalFilterColumn(globalFilterColumn);

                        if(globalFilterColumn !== null)
                            filter.push({name: globalFilterColumn.name, value: localStorage.getItem('GlobalFilterValue')});
                    }
                }

                let overrideFilter = getForSelectRestriction(column.selectRestriction, dictionaryItemMetaDescription, column.linkTo, data ?? formData);

                api()
                    .getListForSelect(column.linkTo, 0, overrideFilter === '' || overrideFilter === null ?  filter : [], overrideFilter)
                    .then((data)=> {
                        let options = data.map(x=> { return {key: x.id, text: x.name, value: x.id};});
                        dictionaryItemsForSelects.push({dictionaryName: column.linkTo, fieldName: column.name, filter: overrideFilter, options: options});
                        setDictionaryItemsForSelects(dictionaryItemsForSelects);
                        let dictionaryLinkFields = columnsWithoutHiddenAndGlobalFiler
                            .filter(col => col.type === reportTypes.linkToDictionary || col.type === reportTypes.arrayLinkToDictionary);
                        let dictionaryLinkFieldsWithselectsElement = dictionaryLinkFields
                            .filter(col => dictionaryItemsForSelects.filter(z=> z.fieldName === col.name && z.dictionaryName === col.linkTo).length  !== 0);
                        let newValForIsReadyForRender = dictionaryLinkFieldsWithselectsElement
                            .length === dictionaryLinkFields.length;
                        setIsReadyForRender(newValForIsReadyForRender);
                    })
            }
            if(column.type === reportTypes.linkToEnum || column.type === reportTypes.arrayLinkToEnum) {
                let enums = appConfig().enums;

                console.log(enums);
                console.log(column.linkTo);

                let options = enums
                    .filter(x => x.nameSingle === column.linkTo)[0].values
                    .map(x=> {
                        let newVar = {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};
                        if(x.color !== null)
                            newVar.label = { color: x.color, empty: true, circular: true };
                        if(x.imageUrlForLabel !== null)
                            newVar.image = { avatar: false, src: x.imageUrlForLabel };
                        return newVar;
                    });

                enumItemsForSelects.push({enumName: column.linkTo, options: options});
                setEnumItemsForSelects(enumItemsForSelects);
            }
            
            if(column.type === reportTypes.roleSelect) {
                let enums = appConfig().enums;

                console.log(enums);
                console.log(column.linkTo);

                let options = enums
                    .filter(x => x.nameSingle === 'Role')[0].values
                    .map(x=> { return {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};});

                enumItemsForSelects.push({enumName: 'Role', options: options});
                setEnumItemsForSelects(enumItemsForSelects);
            }
        });
        if(columnsWithoutHiddenAndGlobalFiler.filter(column => column.type === reportTypes.linkToDictionary || 
            column.type === reportTypes.arrayLinkToDictionary).length === 0)
            setIsReadyForRender(true);
    };

    let createInputFromColumn = (column, viewLabel = true, rowIsAllBool) => {
        let name = column.name;
        let item = formData.filter(x=>x.name === name)[0];
        let value = item?.value ?? '';
        //debugger;
        if(column === globalFilterColumn)
            return;
        let inputElement = null;
        let readOnly = false;
        
        if(column.readOnlyIf !== undefined && column.readOnlyIf !== null && column.readOnlyIf !== '') {
            readOnly = calculateExpression(column.readOnlyIf, dictionaryItemMetaDescription, formData, data)
        }
        
        if(column.type == reportTypes.innerTable) {
            let innerTableType = window
                .configuration
                .dictionaries
                .filter(x=>x.strictNameMany === column.linkTo)[0];
            let columns = innerTableType
                .columns
                .filter(column => !column.hideInList)
                .filter(x=>x.name !== column.innerTableInfo.byFieldName);
            let prefillFieldsForModal = [{name: column.innerTableInfo.byFieldName, value: formData.filter(x=>x.name === 'Id')[0]?.value ?? ''}];
            console.log('prefillFieldsForModal: ');
            console.log(prefillFieldsForModal);
            let canAdd = true
            let canEdit = true
            let canAction = true

            if(column.innerTableInfo !== undefined && column.innerTableInfo !== null && column.innerTableInfo.addingCondition !== '')
                canAdd = calculateExpression(column.innerTableInfo.addingCondition, dictionaryItemMetaDescription, formData, data)
            
            if(column.innerTableInfo !== undefined && column.innerTableInfo !== null && column.innerTableInfo.editingCondition !== '')
                canEdit = calculateExpression(column.innerTableInfo.editingCondition, dictionaryItemMetaDescription, formData, data)
            
            if(column.innerTableInfo !== undefined && column.innerTableInfo !== null && column.innerTableInfo.actionCondition !== '')
                canAction = calculateExpression(column.innerTableInfo.actionCondition, dictionaryItemMetaDescription, formData, data)
            
            if(value.map === undefined || value.length === 0) {
                let nameSingle = column.innerTableInfo != null && column.innerTableInfo.enName != null  ?'innerTable-' + dictionaryItemMetaDescription.strictNameMany + '.' + column.name + '-fieldName' : 'innerTable-' + dictionaryItemMetaDescription.strictNameMany + '.' + innerTableType.nameSingle + '-fieldName';
                
                inputElement = <><DictionaryItemEdit
                    parentData={{data: formData, parent: parentData}}
                    dictionaryItemMetaDescription={innerTableType}
                    view={{nameSingle}}
                    hiddenFields={[column.innerTableInfo.byFieldName]}
                    overrideSave={(data)=>{
                        onChangeFieldValue(column.name, data);
                    }}
                    /*overrideSave={(data)=>{
                        if(formData.filter(x => x.name === column.name).length > 0) {
                            let targetFormData = formData.map(x => {
                                if(x.name === column.name)
                                    x.value.push(data);
                                return x;
                            });
                            setFormData(targetFormData);
                        }else{
                            let targetFormData = formData.map(x => x);
                            targetFormData.push({name: column.name, value: [data]});
                            setFormData(targetFormData);
                        }
                    }}*/
                    prefillFields={prefillFieldsForModal}
                ><Button disabled={!canAdd} fluid ><Icon name='plus' />&nbsp;{t(nameSingle)}</Button></DictionaryItemEdit></>;
            }else{
                inputElement = <>
                    <div className={'innerTable-div-container'}>
                        <Table size='small' selectable>
                            <Table.Header>
                                <Table.Row key={column.name +'innerTable'}>
                                    {columns
                                        .map(col=><Table.HeaderCell
                                            key={column.linkTo + '-innerTableHead-'+col.name}>
                                            {t(innerTableType.nameSingle + '.' + col.name)}
                                        </Table.HeaderCell>)
                                    }
                                    {canAction ? <Table.HeaderCell></Table.HeaderCell> : ''}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {value.map !== undefined ? value.map((val, index) => {
                                    let row = <Table.Row key={hash(val)}
                                                         className={
                                                            (innerTableType.backlights.length > 0 && calculateExpression(innerTableType.backlights[0].condition, innerTableType, [], val) ? 'backgorund-for-row-' + innerTableType.backlights[0].color : '') + 
                                                             (canEdit ? ' can-edit' : '')}>
                                        {columns
                                            .map(col => <Table.Cell
                                                key={column.linkTo + '-innerTableCell-' + col.name + hash(val)}>
                                                {columnValueGetter(val, col)}
                                            </Table.Cell>)
                                        }
                                        {canAction ? <Table.Cell>
                                            {val["Id"] !== undefined ?
                                                <ActionInvokerForGrid id={val["Id"]} afterInvoke={() => {
                                                    onOpen();
                                                }} dictionaryStrictNameMany={innerTableType.strictNameMany}/> : ''}
                                        </Table.Cell> : ''}
                                    </Table.Row>;
                                    if(canEdit)
                                        return <DictionaryItemEdit
                                            parentData={{data: formData, parent: parentData}}
                                            key={'innerTable-' + innerTableType.strictNameMany + 'for-' + (val["Id"] ?? '')}
                                            dictionaryItemMetaDescription={innerTableType}
                                            data={val}
                                            onDeleteEvent={() => {
                                                onOpen();
                                            }}
                                            overrideSave={(data) => {
                                                //debugger;
                                                if (formData.filter(x => x.name === column.name).length > 0) {
                                                    let targetFormData = formData.map(x => {
                                                        if (x.name === column.name) {
                                                            x.value = x.value.map((z, valueIndex) => {
                                                                if (data["Id"] != undefined ? z["Id"] === data["Id"] : valueIndex === index)
                                                                    return data;
                                                                else
                                                                    return z;
                                                            });
                                                        }
                                                        return x;
                                                    });
                                                    setFormData(targetFormData);
                                                } else {
                                                    let targetFormData = formData.map(x => x);
                                                    targetFormData.push({name: column.name, value: [data]});
                                                    setFormData(targetFormData);
                                                }
                                                if (!changedFields.includes(column.name)) {
                                                    let temp = changedFields.map(x => x)
                                                    temp.push(column.name)
                                                    setChangedFields(temp);
                                                }
                                            }}
                                            hiddenFields={[column.innerTableInfo.byFieldName]}
                                        >{row}</DictionaryItemEdit>;
                                    return row
                                }): <></>}
                            </Table.Body>
                        </Table>
                        {canAdd ? <DictionaryItemEdit
                            parentData={{data: formData, parent: parentData}}
                            dictionaryItemMetaDescription={innerTableType}
                            hiddenFields={[column.innerTableInfo.byFieldName]}
                            overrideSave={(data)=>{
                                onChangeFieldValue(column.name, data);
                            }}
                            prefillFields={prefillFieldsForModal}
                        ><Button >{t("add")}</Button></DictionaryItemEdit> : ''}
                    </div>
                </>                
            }
        } else {
            [inputElement, viewLabel] = resolveColumnToElement(column, 
                readOnly, 
                value, 
                reportTypes, 
                onChangeFieldValue, 
                formData, 
                viewLabel,
                dictionaryItemsForSelects
                    .filter(z=> z.fieldName === name && z.dictionaryName === column.linkTo)[0]?.options ?? [], 
                enumItemsForSelects,
                data,
                parentData,
                dictionaryItemMetaDescription.nameSingle + '.' + name,
                //dictionaryItemMetaDescription.nameSingle + '.' + name);
                dictionaryItemMetaDescription.nameSingle + '.' + column.description,
                setFormData,
                updateDictionaryItemsForSelectsForColumn);
        }
        let accts = [];
        if(actions.length > 0){
            //debugger;
            accts = actions.filter(x=>x.viewOnField === name);
        }
        
        let requiredFlag = (column.isRequiredForCreate && (data?.Id == undefined)) || (column.isRequiredForUpdate && (data?.Id != undefined)) ? <span style={{color: 'red'}}>*</span> : '';
        let errorFlag = errors.indexOf(column.name) !== -1;
        let id = formData.filter(x=>x.name === 'Id')[0]?.value ?? '';
        let isChanged = id === '' ? false : changedFields.includes(column.name);
        return <Form.Field
            error={errorFlag}
            readOnly={readOnly}
            onFocus={()=>{
                if(errors.indexOf(column.name) !== -1) {
                    setErrors(errors.filter(x=>x !== column.name));
                }
                setFocusColumn(column.name)
            }}
            onBlur={() => {setFocusColumn(null)}}
            key={'form-field-' + name}
            width={column.size}
            className={((!rowIsAllBool && column.type === reportTypes.bool) ? 'padding-top-30' : '') + (isChanged ? " is-changed" : "") + (focusColumn == column.name ? ' focused' : '')}
        >
            {viewLabel ? (column.description !== '' ?    <><label>{t(dictionaryItemMetaDescription.nameSingle + '.' + name)} {requiredFlag}</label><Popup
                position={"top center"}
                trigger={<Icon name={"info circle"}/>}
                on='click'
                content={getElementsFromFormattedText(t(dictionaryItemMetaDescription.nameSingle + '.' + column.description))}

            /></> : <label>{t(dictionaryItemMetaDescription.nameSingle + '.' + name)} {requiredFlag}</label>) : ''}
            {inputElement}
            {accts.length > 0 ? accts.map(a => <ActionButton
                data={formData}
                for={'action'}
                as={'button'}
                style={{width: '100%', marginTop: '15px'}}
                action={a}
                compact={true}
                id={data.Id}
                dictionaryStrictNameMany={dictionaryItemMetaDescription.strictNameMany}
                afterInvoke={()=>{
                    if(onActionEvent !== undefined){
                        onActionEvent(a);
                    }
                    if(!a.stayAfterRun)
                        close();
                }}
            />) :''}
        </Form.Field>;
    };

    let getGroupedColumns = (columns) => {
        let groups = [];
        let currentGroupIndex = 0;

        columns.forEach(col=>{
            if(groups[currentGroupIndex] === undefined)
                groups[currentGroupIndex] = {
                    isNewLineExistBody: false,
                    columns: []
                };
            var totalSizeInRow = 0;
            for (var i = 0; i < groups[currentGroupIndex].columns.length; i++){
                totalSizeInRow += groups[currentGroupIndex].columns[i].size
            }
            if((totalSizeInRow + col.size) > 16 || col.isNewLine){
                currentGroupIndex++;
                groups[currentGroupIndex] = {
                    isNewLineExistBody: false,
                    columns: []
                };
                if(col.isNewLineExistBody) {
                    groups[currentGroupIndex].isNewLineExistBody = true;
                    groups[currentGroupIndex].isNewLineIcon = col.isNewLineIcon;
                    groups[currentGroupIndex].isNewLineRuTitle =  col.isNewLineRuTitle;
                    groups[currentGroupIndex].isNewLineEnTitle =  col.isNewLineEnTitle;
                    groups[currentGroupIndex].isNewLineRuText =  col.isNewLineRuText;
                    groups[currentGroupIndex].isNewLineEnText =  col.isNewLineEnText;
                }
            }
            groups[currentGroupIndex].columns.push(col);
        });
        groups.map(group=>{
            if(group.columns.every(col=>col.type === reportTypes.bool))
                group.isAllBool = true;
            else
                group.isAllBool = false;
        });
        //console.log('groups!!!!!!!!!!!!!!');
        //console.log(groups);
        return groups;
    };

    let columnsWithOutTabs = columnsWithoutHiddenAndGlobalFiler.filter(col => col.tabName === '');
    
    var getModalSize = (dictionaryItemMetaDescription) => {
        if(dictionaryItemMetaDescription.modalSize !== undefined &&
            dictionaryItemMetaDescription.modalSize !== null &&
            dictionaryItemMetaDescription.modalSize !== ""
        )
            return dictionaryItemMetaDescription.modalSize;
        return 'large';
    };
    let infoBlocks = dictionaryItemMetaDescription
        .infoBlocks
        .filter(x=> calculateExpression(x.condition, dictionaryItemMetaDescription, formData, data));

    const getSaveBtn = () => {
        const dataIsNull = data == null || data == undefined
        //debugger
        return overrideSave === null ? (changedFields.length > 0 || dataIsNull ?<Button
            onClick={save}
            positive
            icon='checkmark'
            labelPosition='right'
            loading={saveIsLoading}
            content={t(dataIsNull ? 'createInBase' : 'saveChanges')}
        /> : '') : (changedFields.length > 0 || dataIsNull ? <Button
            color='orange'
            onClick={save}
            loading={saveIsLoading}
            content={t(dataIsNull ? 'addItem' :'saveChanges')}
        /> : '')
    }

    let defaultFieldValue = '';
    if (data != null) {
        defaultFieldValue = data[dictionaryItemMetaDescription.fieldForDefaultNameOfLinkTo];
        if(defaultFieldValue == undefined || defaultFieldValue == null){
            console.log(formData)
            if(formData.filter(x=>x.name == dictionaryItemMetaDescription.fieldForDefaultNameOfLinkTo).length > 0) {
                defaultFieldValue = formData.filter(x=>x.name == dictionaryItemMetaDescription.fieldForDefaultNameOfLinkTo)[0].value
                if(defaultFieldValue == null)
                    defaultFieldValue = ''
            }else {
                defaultFieldValue = ''
            }
        }
        if(defaultFieldValue.length > 30) {
            defaultFieldValue = defaultFieldValue.substring(0, 20) + "..."
        }
    }
    return (
        <Modal size={!isOpenComments ? getModalSize(dictionaryItemMetaDescription) : 'fullscreen'}
               centered={(!(dictionaryItemMetaDescription.modalPosition === '' || dictionaryItemMetaDescription.modalPosition === 'Top'))}
               trigger={children}
               open={isOpen}
               onClick={e => e.stopPropagation()}
               onOpen={onOpen}
               closeIcon
               onClose={() => {
                   close();
               }}
               closeOnEscape={false}
               closeOnDimmerClick={false}
               className={'dictionary-item-edit-modal'}
        >
            <Modal.Header>
                {t(dictionaryItemSingleName) + (data == null ? ' ('+t('Creating')+'...)' : (defaultFieldValue === '' ? ' ('+t('Editing')+'...)' : ` "${defaultFieldValue}"`))}
                {dictionaryItemMetaDescription.commentsInfo != null && data !== undefined && data.Id !== undefined ? <a 
                    className={'comments-btn' + (isOpenComments ? ' is-hide' : '')}
                    onClick={()=>{setIsOpenComments(val => !val)}}
                >{isOpenComments ? t('entity_comments_hide_comments') : t('entity_comments_open_comments')}</a> : ''}
            </Modal.Header>
            <Modal.Content>
                <div style={{display: 'flex'}}>
                    <div style={{width: isOpenComments ? '50%' : '100%'}}>
                        {isReadyForRender ?  <>
                            {infoBlocks.map(infoBlock => {
                                return <Message
                                    icon={infoBlock.icon}
                                    color={infoBlock.color}
                                    header={t('InfoBlockName_' + infoBlock.name)}
                                    key={t('InfoBlockName_' + infoBlock.name)}
                                    content={<p>
                                        {getElementsFromFormattedText(t('InfoBlockDescription_' + infoBlock.description))}
                                    </p>}
                                />;
                            })}
                            <Form autoComplete="off">
                                {getGroupedColumns(columnsWithOutTabs).map((group, i) => <><>{group.isNewLineExistBody ? <div><Message
                                    compact
                                    icon={group.isNewLineIcon}
                                    header={(getCurrentLangValue() === 'ru' ? group.isNewLineRuTitle :  group.isNewLineEnTitle)}
                                    content={(getCurrentLangValue() === 'ru' ? group.isNewLineRuText :  group.isNewLineEnText)}
                                /><br/></div> : ''}</><Form.Group key={"group-for-out-tabs-row-" + i}>
                                    {group.columns.map(col => createInputFromColumn(col, true, group.isAllBool))}
                                </Form.Group></>)}

                                {dictionaryItemMetaDescription.tabs.length > 0 && dictionaryItemMetaDescription.tabs
                                    .filter(tab => columnsWithoutHiddenAndGlobalFiler.filter(col => col.tabName === tab.nameSingle).length > 0).length > 0 ?
                                    <Tab menu={{ secondary: true, pointing: true }} panes={dictionaryItemMetaDescription.tabs
                                        .filter(tab => columnsWithoutHiddenAndGlobalFiler.filter(col => col.tabName === tab.nameSingle).length > 0)
                                        .map(tab => {
                                            return {
                                                menuItem: getMenuItemName(tab, columnsWithoutHiddenAndGlobalFiler, formData),
                                                render: () => {
                                                    let columnsInTab = columnsWithoutHiddenAndGlobalFiler.filter(col => col.tabName === tab.nameSingle);

                                                    return <Tab.Pane>
                                                        {tab.description !== '' ?  <Message info>
                                                            <p>{getElementsFromFormattedText(t(tab.description))}</p>
                                                        </Message> : <></>}
                                                        {getGroupedColumns(columnsInTab).map((group, i) => <><>{group.isNewLineExistBody ? <div><Message
                                                            compact
                                                            icon={group.isNewLineIcon}
                                                            header={(getCurrentLangValue() === 'ru' ? group.isNewLineRuTitle :  group.isNewLineEnTitle)}
                                                            content={(getCurrentLangValue() === 'ru' ? group.isNewLineRuText :  group.isNewLineEnText)}
                                                        /><br/></div> : ''}</><Form.Group key={"group-for-in-tabs-row-" + i}>
                                                            {group.columns.map(col => createInputFromColumn(col,
                                                                defineIfOneInnerTableInColumn(tab, columnsWithoutHiddenAndGlobalFiler), group.isAllBool))}
                                                        </Form.Group></>)}
                                                    </Tab.Pane>;
                                                }
                                            };
                                        })}/> : ""}
                            </Form>
                        </> : ''}
                    </div>
                    {isOpenComments ? <div className={'comments-container'}>
                        <Comments 
                            dictionary={dictionaryItemMetaDescription} 
                            id={data.Id}
                        />
                    </div> : ''}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column textAlign={'left'} width={3}>
                            {actions.filter(x => x.viewOnField === '' && !x.hideInModalActions).length > 0 ? <Dropdown
                                icon='bars'
                                //text={'Действия'}
                                //floating
                                //labeled
                                button
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header content={t('available_actions_lbl_in_modal')} />
                                    <Dropdown.Divider />
                                    {actions.filter(x => x.viewOnField === '' && !x.hideInModalActions).map(action => <ActionButton
                                        for={'action'}
                                        data={formData}
                                        as={'dropdown'}
                                        action={action}
                                        compact={true}
                                        id={data.Id}
                                        dictionaryStrictNameMany={dictionaryItemMetaDescription.strictNameMany}
                                        afterInvoke={()=>{
                                            if(onActionEvent !== undefined){
                                                onActionEvent(action);
                                            }
                                            if(!action.stayAfterRun)
                                                close();
                                            else{
                                                onOpen();
                                            }
                                        }}
                                    />)}
                                </Dropdown.Menu>
                            </Dropdown>: ''}
                            
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {data !== undefined && data.Id !== undefined && canDelete ? (saveIsLoading ? <Button disabled={true} color='red' icon>
                                <Icon name='trash' />
                            </Button> : <DeleteDictionaryItem onConfirm={deleteThis}/>): ''}

                            <Button basic onClick={close}>{changedFields.length > 0 ? t('Cancel') : t('Close')}</Button>
                            {getSaveBtn()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Actions>
        </Modal>
    );
}