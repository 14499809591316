import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";

export default function PublicFile() {

    let {publicFileUrl} = useParams();
    
    useEffect(()=>{
        window.location = `/api/publicFiles?url=${publicFileUrl}`
    }, [publicFileUrl])
    return (<div></div>);
}