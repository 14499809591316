import React, {useEffect, useState} from 'react';
import {Loader, Table} from "semantic-ui-react";

let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){

        let fieldForValue = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryNameForValue = fieldForValue.value.split('"."')[0].substr(1);
        let fieldNameForValue = fieldForValue.value.split('"."')[1];

        fieldNameForValue = fieldNameForValue.substr(0, fieldNameForValue.length - 1);

        let fieldForLabel = JSON.parse(element.dictionaryFieldForLabel);
        let dictionaryNameForLabel = fieldForLabel.value.split('"."')[0].substr(1);
        let fieldNameForLabel = fieldForLabel.value.split('"."')[1];

        fieldNameForLabel = fieldNameForLabel.substr(0, fieldNameForLabel.length - 1);

        if(dictionaryNameForLabel === dictionaryNameForValue)
            return `${fieldNameForValue} по ${fieldNameForLabel} (${dictionaryNameForValue})`;
        return `${fieldNameForValue} из ${dictionaryNameForValue} по ${fieldNameForLabel} из ${dictionaryNameForLabel}`;
    }

    return element.ruTitle;
}

const TableReportElement = ({
                       getData,
                       pageIndex,
                       elementIndex,
                       element,
                       width,
                       height,
                       onePercentInPixelForWidth,
                       onePercentInPixelForHeight,
                       filtersValues
                   }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);

    useEffect(() => {
        getData(pageIndex, elementIndex, filtersValues)
            .then(values => {
                setLabels(values.map(x=>x[0]));
                setSeries(values.map(x=>parseFloat(x[1]) ));
                setIsLoading(false);
            });
        setIsLoading(false);
    }, []);

    return (<div style={{marginTop: '20px'}}>
        {!isLoading ? <div style={{textAlign: 'left', fontSize: (onePercentInPixelForWidth*1.5) + 'px', padding: (onePercentInPixelForWidth) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell>First</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>}
    </div>);
}

export default TableReportElement