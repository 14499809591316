import React, {useState} from 'react';
import './KnowledgeBasePageMarkdownView.scss'
import {
    Icon,
    Label
} from "semantic-ui-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ImageViewer from "../../../../shared/ImageViewer/ImageViewer";

export default function KnowledgeBasePageMarkdownView({content}) {
    const [imageViewerOpen, setImageViewerOpen] = useState(false)
    const [imageViewerSrc, setImageViewerSrc] = useState('')
    return <div className={'custom-html-style knowledge-base-page-markdown-view'}>
        <ReactMarkdown
            linkTarget={'_blank'}
            remarkPlugins={[remarkGfm]}
            components={{
                img({src}) {
                    return <div className={'image-container'}>
                        <img src={src} onClick={()=>{setImageViewerOpen(true); setImageViewerSrc(src)}}/>
                    </div>
                },
                a(props){
                    // debugger
                    if(props.children[0].endsWith('.pdf'))
                        return <Label color={'blue'} as={'a'} onClick={()=>{window.open(props.href, '_blank')}}><Icon name={'file pdf outline'} />{props.children}</Label>
                    if(props.children[0].endsWith('.docx'))
                        return <Label color={'blue'} as={'a'} onClick={()=>{window.open(props.href, '_blank')}}><Icon name={'file word outline'} />{props.children}</Label>
                    if(props.children[0].endsWith('.xlsx'))
                        return <Label color={'blue'} as={'a'} onClick={()=>{window.open(props.href, '_blank')}}><Icon name={'file excel outline'} />{props.children}</Label>
                    debugger
                    if(props.href.indexOf('/knowledgeBase/') !== -1)
                        return <Label color={'blue'} as={'a'} onClick={()=>{window.open(props.href, '_blank')}}><Icon name={'book'} />{props.children}</Label>
                    return <a href={props.href} target={'_blank'}>{props.children}</a>
                }
            }}
            className={'markdown-display-container'}>{content}</ReactMarkdown>
        <ImageViewer open={imageViewerOpen} setOpen={setImageViewerOpen} imageViewerSrc={imageViewerSrc} />
    </div>;
}