import React, {useEffect, useState} from 'react';
import {Popup} from "semantic-ui-react";

let RSSComponent = ({url}) => {
    let [result, setResult] = useState(null);
    useEffect(()=>{
        if(url != null && result == null)
        {
            let input = 'https://api.rss2json.com/v1/api.json?rss_url=' + url;
            
            fetch(input, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8"
                        }
                    })
                        .then((resp)=> {
                            if (resp.ok) {
                                return resp.json().then(function (json) {
                                    setResult(json);
                                });
                            } else {
                                throw new Error('Fetch error');
                            }
                        })
                .catch(error => {
                    console.error(error);
                });
        }
        
    }, []);
    return (<div>{(result != null && result.items != undefined) ? result.items.map(item=> <Popup position={'bottom right'} flowing basic content={<div style={{width: '100px'}}>{item.description}</div>} trigger={<div style={{textAlign: 'left', padding: '3px'}}><a target={'_blank'} href={item.link}>{item.title}</a>
    </div>} /> ) : ''}</div>);
};

export default RSSComponent;