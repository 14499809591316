import React from 'react';
import {Label} from 'semantic-ui-react';
import t from "../../../utils/translate";
import './licenseInfoAlert.css'

export default function LicenseIsExpired() {
    return <div className={'license-info-alert'}>
        <img src={'https://static.tildacdn.com/tild3930-6162-4566-b034-316564366631/companyLogo.svg'}/>
        <p>{t('LicenseIsExpired')}. <Label as='a' basic target={'_blank'} href={"https://report.ms/prices"}>
            {t('LicenseInfoBtn')}
        </Label></p>
    </div>;
    
}