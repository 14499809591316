import React, {useEffect, useState} from 'react';
import './Calendar.css'
import {
    Button, ButtonGroup,
    Grid, GridColumn,
    GridRow
} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../../utils/translate";
import DatePicker from "react-datepicker";
import CreateUpdateEventModal from "./components/CreateUpdateEventModal/CreateUpdateEventModal";
import api from "../../utils/api";
import moment from "moment";
import getBackedUrl from "../../utils/getBackedUrl";

export default function Calendar() {
    let [calendarViewType, setCalendarViewType] = useState('week');
    let [openCreateEditModel, setOpenCreateEditModel] = useState(false);
    let [currentEditEventId, setCurrentEditEventId] = useState(null);
    let [events, setEvents] = useState([{
        from: new Date(),
        to: new Date(new Date().getTime() + (60*60*1000)),
        title: 'test',
    }]);
    let [selectedDate, setSelectedDate] = useState(new Date());
    let currentLangValue = getCurrentLangValue();

    function fixDates(resp) {
        return resp.map(x => {
            x.from = moment(x.from, "DD.MM.YYYY hh:mm:ss").toDate();
            x.to = moment(x.to, "DD.MM.YYYY hh:mm:ss").toDate();
            return x;
        });
    }

    useEffect(()=>{
        api()
            .getEventsForWeek(selectedDate.getFullYear(), GetWeekNumber(selectedDate))
            .then(resp=>{
                setEvents(fixDates(resp));
            });
    }, []);

    const forTwoSymbols = h => h < 10 ? `0${h}` : h.toString();
    const getMonday = d => {
        d = new Date(d);
        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1);
        return new Date(d.setDate(diff));
    };

    const getWeekDayName = day => {
        switch (day){
            case 0:
                return 'Пн';
            case 1:
                return 'Вт';
            case 2:
                return 'Ср';
            case 3:
                return 'Чт';
            case 4:
                return 'Пт';
            case 5:
                return 'Сб';
            case 6:
                return 'Вс';
        }
    };

    const getWeekDayForSelectDay = day => {
        let date = getMonday(selectedDate);
        let ms = date.getTime() + 86400000 * day;
        date = new Date(ms);
        let isCurrent = new Date().toDateString() === date.toDateString();
        return <div className={isCurrent ? 'is-current' : ''}>{date.getDate()}</div>;
    };

    const getCurrentTimeIdentificator = day => {
        let date = getMonday(selectedDate);
        let ms = date.getTime() + 86400000 * day;
        date = new Date(ms);
        let isCurrent = new Date().toDateString() === date.toDateString();
        let top = new Date().getHours() * 40;
        top += new Date().getMinutes() * 40/60;
        return isCurrent ? <div className={'is-current-time'} style={{top: `${top}px`}}><div className={'circle-for-current-time'}></div></div> : '';
    };
    
    const GetWeekNumber = date => {
        let startDate = new Date(date.getFullYear(), 0, 1);
        let days = Math.floor((date - startDate) /
            (24 * 60 * 60 * 1000));

        return  Math.ceil(days / 7);
    };

    const renderEvents = day => {
        let date = getMonday(selectedDate);
        date.setHours(0,0,0,0);
        let ms = date.getTime() + 86400000 * day;
        let from = new Date(ms);
        let to = new Date(from.getTime() + 86400000);
        let eventsForRender = events.filter(e=>e.from > from && e.to < to);
        return eventsForRender.map(e => {
            let top = e.from.getHours() * 40;
            top += e.from.getMinutes() * 40/60;
            let height = ((e.to.getTime() - e.from.getTime())/(1000*60)) * 40/60;
            return <div className={'event-container'} 
                        style={{top: `${top}px`, height: `${height}px`}}
                        onClick={()=>{setCurrentEditEventId(e.id); setOpenCreateEditModel(true)}}
            >
                <div className={'title'}>{e.name}</div>
                <div className={'from-to-time'}>{forTwoSymbols(e.from.getHours())}:{forTwoSymbols(e.from.getMinutes())} - {forTwoSymbols(e.to.getHours())}:{forTwoSymbols(e.to.getMinutes())}</div>
                {e.members !== undefined && e.members.length > 0 && height > 70 ? <div className={'members'}>
                    {e.members.map(member => <div
                        className={'member-image'}
                        style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${member}")`}}/>)}
                </div> : ''}
            </div>;
        });
    };

    const setCalendarViewTypeDay = () => {
        setCalendarViewType('day');
        api()
            .getEventsForDay(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
            .then(resp=>{
                setEvents(fixDates(resp));
            });
    };

    const setCalendarViewTypeWeek = () => {
        setCalendarViewType('week');
        api()
            .getEventsForWeek(selectedDate.getFullYear(), GetWeekNumber(selectedDate))
            .then(resp=>{
                setEvents(fixDates(resp));
            });
    };

    const setCalendarViewTypeMonth = () => {
        setCalendarViewType('month');
        api()
            .getEventsForMonth(selectedDate.getFullYear(), selectedDate.getMonth())
            .then(resp=>{
                setEvents(fixDates(resp));
            });
    };

    let beforeCloseModal = (isOpen) => {
        if(!isOpen){
            switch (calendarViewType){
                case 'day':
                    setCalendarViewTypeDay()
                    break;
                case 'week':
                    setCalendarViewTypeWeek()
                    break;
                case 'month':
                    setCalendarViewTypeMonth()
                    break;
            }
        }
        setOpenCreateEditModel(isOpen);
    };
    
    const getEventsForDayInMonth = d => {

    };

    let daysInMonth = [];
    
    let selectedDateCopy = new Date(selectedDate);
    selectedDateCopy.setDate(1);
    selectedDateCopy = getMonday(selectedDateCopy);
    
    for(let j = 0; j < 6; j++){
        for(let i = 0; i < 7; i++){
            daysInMonth.push({
                isMonday: i === 0,
                isFirstWeek: j === 0,
                day: selectedDateCopy.getDate(),
                currentMonth: selectedDateCopy.getMonth() === selectedDate.getMonth()
            });
            selectedDateCopy.setDate(selectedDateCopy.getDate() + 1);
        }
    }
    let lastWeek = daysInMonth.slice(35, 41);
    if(lastWeek.filter(x=> x.currentMonth).length === 0)
        daysInMonth = daysInMonth.slice(0, 35);

    if(daysInMonth.length === 35){
        lastWeek = daysInMonth.slice(28, 34);
        if(lastWeek.filter(x=> x.currentMonth).length === 0)
            daysInMonth = daysInMonth.slice(0, 28);
    }
    return (<div className={'ui calendar-page-container'}>
        <Grid>
            <GridRow>
                <GridColumn width={3} style={{textAlign: 'center'}}>
                    <div style={{marginBottom: '30px'}}>
                        <Button onClick={()=>{setCurrentEditEventId(null);setOpenCreateEditModel(true)}}>Создать событие</Button>
                    </div>
                    <DatePicker
                        inline
                        isClearable
                        locale={currentLangValue}
                        selected={selectedDate}
                        onChange={date => {
                            setSelectedDate(date);
                        }}
                    />
                    {getMonday(selectedDate).toString()}
                </GridColumn>
                <GridColumn width={13} className={'events-viewer-container'}>
                    <div className={'view-type-switcher-container'}>
                        <ButtonGroup className={'view-type-switcher'}>
                            <Button active={calendarViewType === 'day'} onClick={setCalendarViewTypeDay}>{t('calendar_day_view_name')}</Button>
                            <Button active={calendarViewType === 'week'} onClick={setCalendarViewTypeWeek}>{t('calendar_week_view_name')}</Button>
                            <Button active={calendarViewType === 'month'} onClick={setCalendarViewTypeMonth}>{t('calendar_month_view_name')}</Button>
                        </ButtonGroup>
                    </div>
                    {calendarViewType === 'day' ? <div className={'day-view-container'}>
                        <div className={'calendar-header-container'}>
                            <div className={'day-container'}>
                                <div className={'day-header-name'}>{getWeekDayName(selectedDate.getDay())}</div>
                                <div className={'day-header'}>{getWeekDayForSelectDay(selectedDate.getDay())}</div>
                            </div>
                        </div>
                        <div className={'day-container'}>
                            <div className={'hours-container'}>
                                {[...Array(24).keys()].map(h=>{
                                    return <div className={'hour-container'}>
                                        <div className={'hour-description'}>
                                            <>{forTwoSymbols(h)}:00</>
                                        </div>
                                    </div>;
                                })}
                                {renderEvents(selectedDate.getDay())}
                                {getCurrentTimeIdentificator(selectedDate.getDay())}
                            </div>
                        </div>
                    </div> : ''}
                    
                    {calendarViewType === 'week' ? <div className={'week-container'}>
                        <div className={'calendar-header-container'}>
                            {[...Array(7).keys()].map(day=>{
                                return <div className={'day-container'}>
                                    <div className={'day-header-name'}>{getWeekDayName(day)}</div>
                                    <div className={'day-header'}>{getWeekDayForSelectDay(day)}</div>
                                </div>;
                            })}
                        </div>
                        <div className={'calendar-days-container'} style={{height: 'calc(100vh - 170px)'}}>
                            {[...Array(7).keys()].map(day=>{
                                return <div className={'day-container'}>
                                    <div className={'hours-container'}>
                                        {[...Array(24).keys()].map(h=>{
                                            return <div className={'hour-container'}>
                                                <div className={'hour-description'}>
                                                    {day === 0 && h !== 0 ? <>{forTwoSymbols(h)}:00</> : ''}
                                                </div>
                                            </div>;
                                        })}
                                        {renderEvents(day)}
                                        {getCurrentTimeIdentificator(day)}
                                    </div>
                                </div>;
                            })}
                        </div>
                    </div> : ''}
                    {calendarViewType === 'month' ? <div className={'month-container'}>
                        {daysInMonth.map(d => <div style={{height: `calc((100vh - 130px)/${daysInMonth.length/7})`}} className={'day-in-month-container' + (d.currentMonth ? ' current-month': '') + (d.isMonday ? ' is-monday': '') + (d.isFirstWeek ? ' is-first-week': '')}>
                            <div className={'day-number'}>{d.day}</div>
                            <div className={'events'}>{getEventsForDayInMonth(d)}</div>
                        </div>)}
                    </div> : ''}
                </GridColumn>
            </GridRow>
        </Grid>
        {openCreateEditModel ? <CreateUpdateEventModal 
            open={openCreateEditModel} 
            setOpen={beforeCloseModal} 
            id={currentEditEventId}
        /> : ''}
    </div>);
}