import React, {useEffect, useState} from 'react';
import './emailClientBody.css'
import {
    Button,
    Dropdown,
    DropdownDivider,
    DropdownItem,
    DropdownMenu,
    Grid, GridColumn,
    GridRow,
    Icon, Label,
    Search
} from "semantic-ui-react";
import api from "../../../../utils/api";
import NewLetterModal from "./components/newLetterModal/newLetterModal";
import moment from "moment";
import LetterPage from "./components/letterPage/letterPage";

export default function EmailClientBody({emails}) {
    const [currentAccount, setCurrentAccount] = useState(null)
    const [letters, setLetters] = useState([])
    const [unreadCount, setUnreadCount] = useState(0)
    const [newLetterModalOpen, setNewLetterModalOpen] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [currentFolder, setCurrentFolder] = useState(0)
    const [currentEmail, setCurrentEmail] = useState(null)
    const folders = [{name: 'Входящие', id: 0, icon: 'inbox'}, {name: 'Отправленные', id: 1, icon: 'send'}]
    useEffect(()=>{
        setCurrentAccount(emails[0])
    }, [])
    useEffect(()=>{
        if(currentAccount != null)
            getLetters()
    }, [currentAccount, currentFolder])
    useEffect(()=>{
        if(currentEmail == null && currentAccount != null)
            refresh()
    }, [currentEmail])
    
    const getLetters = ()=>{
        api()
            .getLetters(currentAccount.id, currentFolder)
            .then(resp => {
                setLetters(resp.items)
                setUnreadCount(resp.unreadCount)
                setIsRefreshing(false)
            })
    }
    const singOut = ()=>{
        api()
            .logoutFromEmailAccount(currentAccount.id)
            .then(resp => {
                window.location = window.location 
            })
    }
    
    const refresh = ()=>{
        setIsRefreshing(true)
        api()
            .refreshEmail(currentAccount.id)
            .then(resp => {
                getLetters() 
            })
    }
    
    if(currentAccount == null)
        return ''

    const renderDate = (date) => {
        let now = new Date();
        let momentDate = moment(date) 
        let isToDay = now.toLocaleDateString() === momentDate.toDate().toLocaleDateString();
        return momentDate.format(isToDay ? 'hh:mm' : 'DD.MM.yyyy')
    }

    return (<div>
        <Grid style={{paddingTop: '15px'}}>
            <GridRow>
                <GridColumn width={2}>
                    <Button 
                        onClick={()=>{setNewLetterModalOpen(true)}} 
                        icon={'pencil'}
                        labelPosition='left'
                        content="Написать"/>
                </GridColumn>
                {currentEmail == null ? <>
                    <GridColumn width={1}>
                        <Icon loading={isRefreshing} name={'refresh'} onClick={refresh}/>
                    </GridColumn>
                    <GridColumn width={9}>
                        <Search
                            loading={false}
                            onResultSelect={(e, data) => {}
                                //dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })
                            }
                            onSearchChange={()=>{}}
                            resultRenderer={()=>{}}
                            results={()=>{}}
                            value={''}
                        />
                    </GridColumn>
                </> : <>
                    <GridColumn width={1}>
                        <Icon name={'arrow left'} onClick={() => setCurrentEmail(null)}/>
                    </GridColumn>
                    <GridColumn width={9}>
                        <Icon name={'trash'} onClick={() => setCurrentEmail(null)}/>
                        <Icon name={'mail outline'} onClick={() => setCurrentEmail(null)}/>
                    </GridColumn>
                </>}
                <GridColumn width={4}>
                    <Icon name={'mail'} size={'large'} color={'black'}/>
                    <Dropdown text={currentAccount.email}>
                        <DropdownMenu>
                            {emails.map(x=> <DropdownItem text={x.email} />)}
                            <DropdownDivider />
                            <DropdownItem text='Добавить' />
                        </DropdownMenu>
                    </Dropdown>
                    <Icon name={'sign out'} size={'large'} onClick={singOut}/>
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn width={2}>
                    {folders.map(x=><div onClick={()=>{setCurrentFolder(x.id); setCurrentEmail(null)}} style={{fontWeight: x.id == currentFolder ? 'bold': 'normal'}}>
                        <Icon name={x.icon}/> {x.name} {x.id === 0 && unreadCount !== 0 ?  <Label circular color={'grey'}>
                        {unreadCount}
                    </Label> : ''}</div>)}
                </GridColumn>
                <GridColumn width={14}>
                    {currentEmail == null ?
                        <table>
                        <tbody>
                        {letters.map(x=><tr onClick={()=>{setCurrentEmail(x.id)}} style={{fontWeight: x.isRead || currentFolder !== 0 ? 'normal' : '600'}}>
                            <td>{x.email}</td>
                            <td>{x.title}</td>
                            <td>{renderDate(x.dateTime)}</td>
                        </tr>)}
                        </tbody>
                    </table> : <LetterPage id={currentEmail} setId={setCurrentEmail} currentAccount={currentAccount}/>}
                </GridColumn>
            </GridRow>
        </Grid>
        <NewLetterModal from={currentAccount} open={newLetterModalOpen} setOpen={setNewLetterModalOpen} onSend={refresh}/>
    </div>);
}