import React from 'react';
import t, {getCurrentLangValue} from "../../../utils/translate";
import DatePicker from "react-datepicker";
import {Icon} from "semantic-ui-react";

export default function DateFormInput({name, value, column, onChangeFieldValue, disabled}) {
    let numericFormater = (x)=>{ if(x<10){return '0' + x.toString();}else{return x.toString();}};
    let currentLangValue = getCurrentLangValue();

    
    let selectedDate = null;
    if(!(value === null || value === undefined || value === '')) {
        let date = value.split(' ')[0];
        let time = value.split(' ')[1];
        let hours = parseInt(time.split(':')[0]);
        let minutes = parseInt(time.split(':')[1]);
        let seconds = parseInt(time.split(':')[2]);
        let separator = date.includes('.') ? '.' : '/';
        let day = date.split(separator)[0];
        let month = date.split(separator)[1];
        let year = date.split(separator)[2];
//debugger;
        selectedDate = new Date(`${year}-${month}-${day}T${numericFormater(hours)}:${numericFormater(minutes)}:${numericFormater(seconds)}`);
    }
    return <div className={'date-picker-container'}>
        <DatePicker
        placeholderText={column.example}
        disabled={disabled}
        isClearable={!disabled}
        dateFormat={currentLangValue === 'ru' ? "dd.MM.yyyy" : "MMMM d, yyyy"}
        timeCaption={t('timeHeader')}
        locale={currentLangValue}
        selected={value === null || value === undefined || value === '' ?  null : selectedDate}
        onChange={date => {
            if(date == null){
                onChangeFieldValue(name, null);
            }
            else{
                let s = `${numericFormater(date.getDate())}.${numericFormater(date.getMonth()+1)}.${numericFormater(date.getFullYear())} ${numericFormater(date.getHours())}:${numericFormater(date.getMinutes())}:${numericFormater(date.getSeconds())}`;
                onChangeFieldValue(name, s);
            }
        }}
    />
        <Icon name={'calendar alternate outline'}/>
    </div>;
}