import React, {useContext, useEffect, useState} from 'react';
import {Button, Dropdown, Icon, Menu, Popup} from 'semantic-ui-react'
import {Link} from "react-router-dom";
import t, {getCurrentLangValue} from '../utils/translate'
import * as AuthHelper from '../utils/authHelper'
import './header.css'
import DictionaryItemEdit from "../shared/components/DictionaryItemEdit";
import api from "../utils/api";
import {useWindowSize} from "../utils/windowSize";
import {ActionButton} from "../shared/components/ActionButton";
import {calculateExpression} from "../utils/expressionHelper";
import langs from "../utils/langs";
import NotificationsModal from "./components/NotificationsModal/NotificationsModal";
import AdministratorMenuItem from "./components/AdministratorMenuItem/administratorMenuItem";
import MenuIndicator from "./components/MenuIndicator/menuIndicator";
import {ConfigurationContext} from "../utils/configurationContext";
import getBackedUrl from "../utils/getBackedUrl";
import appConfig from "../utils/appConfig";
import ViewRelativeTime from "../shared/components/ViewRelativeTime/ViewRelativeTime";
import AboutProgramModal from "./components/AboutProgramModal/AboutProgramModal";
import LeftMenu from "./components/LeftMenu/LeftMenu";

export default function Header({currentReload}) {
    const [backgroundTasks, setBackgroundTasks] = useState([]);
    const [backgroundTasksLastDateUpdate, setBackgroundTasksLastDateUpdate] = useState(new Date());
    const configuration = appConfig();
    const [width, height] = useWindowSize();
    let [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const context = useContext(ConfigurationContext);

    let location = window.location.pathname.replace('/', '');
    var langPaths = langs().map(x=> '/' + x.value);
    let publicPagesUrls = configuration.publicForms.map(x=>x.url);
    let publicPagesWithLangUrls = [];
    publicPagesUrls.map(x=> langPaths.map(lang=> { publicPagesWithLangUrls.push(x + lang); }) )
    
    let hideHeader = window.location.pathname === "/" ||
        window.location.pathname === "/Introduction" ||
        window.location.pathname.indexOf('/setNewPassword/') === 0 ||
        window.location.pathname === '/passwordIsSet' ||
        window.location.pathname.indexOf('/loginByToken/') === 0 ||
        window.location.pathname.indexOf('/swagger') === 0 ||
        langPaths.includes(window.location.pathname) ||
        publicPagesWithLangUrls.includes(window.location.pathname) ||
        publicPagesUrls.includes(window.location.pathname);

    let globalFilter = configuration.info.globalFilter == null ? null : configuration.info.globalFilter.split('-')[0];
    let globalFilterView = configuration.info.globalFilter == null ? null : configuration.info.globalFilter.split('-')[1];

    let helpResource = configuration.info.helpResource;
    let haveChat = configuration.info.haveChat;
    let haveCalendar = configuration.info.haveCalendar;
    let haveMeets = configuration.info.haveMeets;
    let haveKnowledgeBase = configuration.info.haveKnowledgeBase;
    let haveEmailClient = configuration.info.haveEmailClient;
    let viewProfileMenuItem = configuration.info.viewProfileMenuItem;

    let viewNotificationsMenuItem = configuration.info.viewNotificationsMenuItem;
    let haveAboutProgram = configuration.info.aboutProgramRu != undefined && configuration.info.aboutProgramRu != null && configuration.info.aboutProgramRu != '' 

    let userDictionary = configuration
        .dictionaries
        ?.filter(x => x.nameSingle === 'User')[0] ?? null;
    let globalFilterDictionary;
    if(globalFilter !== null) {
        globalFilterDictionary = configuration
            .dictionaries
            .filter(x => x.nameSingle === globalFilter)[0];

    }

    let userRole = AuthHelper.getRole();

    let dictionaries = (appConfig().dictionaries ?? [])
        .filter(x=> { return x.nameSingle !== globalFilter})
        //.filter(x=> permissions.indexOf(x.permissionForView) !== -1)
        .map(x => {
            return {
                isView: false,
                url: x.strictNameMany,
                strictNameMany: x.strictNameMany,
                name: x.nameMany,
                translateName: t('NameMany_' + x.nameMany)
            }
        });
    let views = (appConfig().views ?? [])
        .filter(x => { return (globalFilterDictionary !== null && globalFilterDictionary !== undefined) ? x.dictionaryStrictName !== globalFilterDictionary.strictNameMany : true })
        .filter(x => { return x.condition === '' || calculateExpression(x.condition);})
        //.filter(x => { return true;})
        .map(view => {
            var d = (appConfig().dictionaries ?? [])
                .filter(x=> { return x.strictNameMany === view.dictionaryStrictName})[0];

            return {
                isView: true,
                url: d.nameSingle + 'View' + view.viewName,
                icon: view.icon ?? d.icon ?? '',
                strictNameMany: view.dictionaryStrictName,
                name: view.viewName,
                translateName: t( view.nameMany !== null ? ('ViewNameMany_' +  view.nameMany) : ('NameMany_' + d.nameMany))
            }
        });
    //views.push(...dictionaries);

    dictionaries = views;
    let initialState = location ?? appConfig().dictionaries[0].strictNameMany;
    let [activeItem, setActiveItem] = useState(initialState);
    let [globalFilterValues, setGlobalFilterValues] = useState([]);
    let [actionsForGlobalFilterItem, setActionsForGlobalFilterItem] = useState([]);

    let [indicators, setIndicators] = useState([]);
    let [dictionaryItemEditModalOpen, setDictionaryItemEditModalOpen] = useState(false);

    let [dictionaryItemEditModalData, setDictionaryItemEditModalData] = useState(undefined);
    let [profileModalOpen, setProfileModalOpen] = useState(false);
    let [aboutProgramModalOpen, setAboutProgramModalOpen] = useState(false);

    let [profileModalData, setProfileModalData] = useState(false);


    let [notificationsModalOpen, setNotificationsModalOpen] = useState(false);

    const updateIndicators = () => {
        api()
            .getIndicators()
            .then(data => {setIndicators(data)});
    };

    const load = () => {
        if (hideHeader)
            return;
        if(!AuthHelper.isLogged())
            window.location = '/';
        
        if(globalFilter !== null) {
            let dictionary = configuration
                .dictionaries.filter(x => x.nameSingle === globalFilter)[0];
            let promise = api()
                .getListForView(dictionary.strictNameMany, 0, [], globalFilterView)
                .then((data)=> {
                    let options = data.rows.map(x=> { return {key: x.Id, text: x[dictionary.fieldForDefaultNameOfLinkTo], value: x.Id};});
                    if(localStorage.getItem('GlobalFilterValue') === null){
                        if(options[0] !== undefined) {
                            onChangeGlobalFilter(options[0].key);
                        }else {
                            setDictionaryItemEditModalData(undefined);
                            setDictionaryItemEditModalOpen(true);
                            return;
                        }
                    }



                    setGlobalFilterValues(options);
                });
            if(localStorage.getItem('GlobalFilterValue') !== null)
                promise.then(()=>{
                    return  api()
                        .getActionsForId(dictionary.strictNameMany, localStorage.getItem('GlobalFilterValue'));
                })
                    .then((data)=>{setActionsForGlobalFilterItem(data.actions)});
        }
        updateIndicators();
    }
    
    const loadBackgroundTasks = () => {
        api()
            .loadBackgroundTasks()
            .then(resp => {
                setBackgroundTasks(resp)
                setBackgroundTasksLastDateUpdate(new Date())
            })
    }
    useEffect(()=> {
        load()
    }, []);
    
    useEffect(()=>{
        load();
        if(context.backgroundTasksLastDateUpdate != null && context.backgroundTasksLastDateUpdate != backgroundTasksLastDateUpdate){
            loadBackgroundTasks();
        }
    },[context]);

    if (hideHeader)
        return null;
    
    let onChangeGlobalFilter = (globalFilter)=>{
        localStorage.setItem('GlobalFilterValue', globalFilter);
        window.location = window.location;
    };

    let logout = () => {
        AuthHelper.logout();
        if(localStorage.getItem('GlobalFilterValue') !== undefined)
            localStorage.removeItem('GlobalFilterValue');
        
        window.location = '/';
    };
    
    let dictionariesForViewInTop = [];
    let dictionariesForViewInSecond = [];
    let currentwidth = 460;
    let pages = appConfig().pages ?? [];
    
    pages = pages.filter(x=>x.name !== 'Notifications.ForAdministrator');
    for(var i = 0; i < pages.length; i++){
        let page1 = pages[i];
        
        currentwidth += (getCurrentLangValue() === 'ru' ? page1.ruName: page1.enName).length * 11 + 32;
        
        if(currentwidth >= width){
            for(var j = i; j < pages.length; j++)
                dictionariesForViewInSecond.push(pages[j]);
            break;
        }
            
        dictionariesForViewInTop.push(page1);
    }
    let globalFilterIsFillOrNotExist = (globalFilter !== null &&
        globalFilterValues.length > 0) || (globalFilter == null);
    let notSelectedGlobalFilterValues = globalFilterValues
        .filter(globalFilterValue => globalFilterValue.key !== localStorage.getItem('GlobalFilterValue'));
    let projectName = getCurrentLangValue() === 'ru' ? appConfig().info.nameRu : appConfig().info.nameEn;
    let getMenuItemForPage = (page, index) => {
        switch (page.type) {
            case 0:
            case 1:
            case 2:
                let view = views.filter(x=>(x.strictNameMany + '.' +x.name) === page.name)[0];
                if (view === undefined) return '';

                let filter = indicators.filter(x=>x.view === view.name && x.dictionary === view.strictNameMany);
                
                return <Menu.Item
                    className="large"
                    key={index}
                    as={Link}
                    to={`/${view.url}`}
                    name={view.name}
                    active={(activeItem + '/').indexOf(view.url + '/') == 0}
                    onClick={()=>{
                        setActiveItem(view.url);
                        setMobileMenuIsOpen(false);
                    }}>
                    {view.icon !== undefined && view.icon !== '' ? <Icon name={view.icon} /> : ''}
                    {view.translateName}
                    {filter.length > 0 ? 
                        <MenuIndicator indicators={filter} /> : ''}
                </Menu.Item>;
                break;
            case 3:
                return <Menu.Item
                    className="large"
                    key={'iframe' + index}
                    as={Link}
                    to={`/iframe-${page.name}`}
                    name={page.name}
                    active={(activeItem + '/').indexOf('iframe-' + page.name + '/') == 0}
                    onClick={()=>{
                        setActiveItem('iframe-' + page.name);
                        setMobileMenuIsOpen(false);
                    }}>
                    {page.icon !== undefined && page.icon !== '' ? <Icon name={page.icon} /> : ''}
                    {getCurrentLangValue() === 'ru' ? page.ruName : page.enName}
                </Menu.Item>;
                break;
            case 4:
                let report = (appConfig().reports ?? []).filter(x=>(x.strictName) === page.name)[0];

                return <Menu.Item
                    className="large"
                    key={'report-' + report.strictName}
                    as={Link}
                    to={`/report-${report.strictName}`}
                    name={report.strictName}
                    active={activeItem.indexOf(report.strictName) > -1}
                    onClick={()=>{
                        setActiveItem(report.strictName);
                        setMobileMenuIsOpen(false);
                    }}>
                    {page.icon !== undefined && page.icon !== '' ? <Icon name={page.icon} /> : ''}
                    {getCurrentLangValue() === 'ru' ? report.ruName : report.enName}
                </Menu.Item>;
                break;
            case 5:
                let powerBiReport = (appConfig().powerBiReports ?? []).filter(x=>(x.name) === page.name)[0];

                return <Menu.Item
                    className="large"
                    key={'report-' + powerBiReport.name}
                    as={Link}
                    to={`/powerBi-${powerBiReport.name}`}
                    name={powerBiReport.name}
                    active={activeItem.indexOf('powerBiReport-' +powerBiReport.name) > -1}
                    onClick={()=>{
                        setActiveItem('powerBiReport-' +powerBiReport.name);
                        setMobileMenuIsOpen(false);
                    }}>
                    {page.icon !== undefined && page.icon !== '' ? <Icon name={page.icon} /> : ''}
                    {getCurrentLangValue() == 'ru' ? powerBiReport.ruName : powerBiReport.enName}
                </Menu.Item>;
                break;
            case 6:
                return <Menu.Item
                    className="large"
                    key={'pdf' + index}
                    as={Link}
                    to={`/pdf-${page.name}`}
                    name={page.name}
                    active={(activeItem + '/').indexOf('pdf-' + page.name + '/') == 0}
                    onClick={()=>{
                        setActiveItem('pdf-' + page.name);
                        setMobileMenuIsOpen(false);
                    }}>
                    {page.icon !== undefined && page.icon !== '' ? <Icon name={page.icon} /> : ''}
                    {getCurrentLangValue() === 'ru' ? page.ruName : page.enName}
                </Menu.Item>;
                break;
        }
    };
    const maxNameLength = 25
    const getName = (short)=>{
        const candidate = globalFilterValues.filter(project => project.key === localStorage.getItem('GlobalFilterValue'))[0]
        if(candidate === undefined)
            return ''
        if(short){
            return <Popup 
                content={candidate.text} 
                trigger={<span>{candidate.text.split(' ').reduce((x, y)=> x.substring(0, 1) + y.substring(0, 1)).toUpperCase()}</span>}
                inverted
                position={'right center'}
                basic
            /> 
        }
        return candidate.text.length > maxNameLength ? candidate.text.substring(0, maxNameLength) : candidate.text 
    }
    
    const getShortString = (str) => {
        return str.length > maxNameLength ? str.substring(0, maxNameLength) + '...' : str
    }

    const avatarIsEmpty = configuration.avatar == null || configuration.avatar === '';
    const avatar = <div
        className={`avatar-image`}    
        style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${configuration.avatar}")`}}/>
    
    const userMenu = <Dropdown className={avatarIsEmpty ? '' : 'avatar-container'} icon={avatarIsEmpty ? 'user' : avatar} item>
        <Dropdown.Menu>
            <Dropdown.Header icon='user' content={`${AuthHelper.getName()}`} />
            <Dropdown.Header icon='group' content={`${t(AuthHelper.getRole())}`} />
            <Dropdown.Divider />
            {viewNotificationsMenuItem ? <Dropdown.Item key={'notifications'}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            setNotificationsModalOpen(true);
                                                        }}
            >{t("notifications")}</Dropdown.Item> : ''}
            {viewProfileMenuItem ? <Dropdown.Item key={'profile'}

                                                  onClick={e => {
                                                      e.stopPropagation();
                                                      let id = AuthHelper.getId();
                                                      setProfileModalData({Id: id});
                                                      setProfileModalOpen(true);
                                                  }}


            >{t("profile")}</Dropdown.Item> : ''}
            {haveAboutProgram ? <Dropdown.Item key={'about_program'}
                                               onClick={e => {
                                                   e.stopPropagation();
                                                   setAboutProgramModalOpen(true);
                                               }}
            >{t("about_program_menu_title")}</Dropdown.Item> : ''}
            <Dropdown.Item onClick={logout}>{t("exit")}</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>;
    
    const backgroundTasksElements = backgroundTasks.length > 0 ? <Popup trigger={<Menu.Item
        className="large background-tasks-menu-item"
        key={'background-tasks-menu-item'}
        name={'backgroundTasks'}
        onClick={()=>{
        }}>
        <Icon aria-label={'Background tasks'} name={'cloud'}/>
        <MenuIndicator indicators={[{color: 'blue', count: backgroundTasks.length}]} />
    </Menu.Item>} content={<div className={'background-tasks-information-list'}>
        <h5>Фоновые задачи</h5>
        <ul>
            {backgroundTasks.map(x=><li>{x.name} <span className={'create-at'}><ViewRelativeTime dateTime={x.createAt}/></span></li>)}
        </ul>
    </div>} basic /> : ''
    
    let globalFilterElement = globalFilter !== null &&
    globalFilterValues.length > 0 ? <>
        <Dropdown
            className={'global-filter-action-menu-item'}
            icon={'ellipsis vertical'} item>
            <Dropdown.Menu>
                {actionsForGlobalFilterItem.filter(x=>x.viewOnField === '').map(action =>
                    <ActionButton
                        for={'action'}
                        key={'global-filter-action-menu-item' + localStorage.getItem('GlobalFilterValue') + action.name}
                        as={'dropdown'}
                        action={action}
                        id={localStorage.getItem('GlobalFilterValue')}
                        dictionaryStrictNameMany={globalFilterDictionary.strictNameMany}
                        afterInvoke={()=>{
                            if(currentReload.load !== undefined && currentReload.load !== null)
                                currentReload.load();
                        }}
                    />)}
                <Dropdown.Divider />
                <Dropdown.Item
                    icon='edit'
                    onClick={e => {
                        e.stopPropagation();
                        setDictionaryItemEditModalData({Id: localStorage.getItem('GlobalFilterValue')});
                        setDictionaryItemEditModalOpen(true);
                    }}
                    //onKeyPress={e => e.stopPropagation()}
                    key={'edit'}  text={t('edit')} />

            </Dropdown.Menu>
        </Dropdown>

        <Menu.Item
            className={'global-filter-menu-item'}
            header>
            <Dropdown text={getName(configuration.info.leftMenu)}>
                <Dropdown.Menu>
                    {notSelectedGlobalFilterValues.length > 0 ? notSelectedGlobalFilterValues
                        .map(globalFilterValue =>
                            <Dropdown.Item
                                key={'GlobalFilterValueOption-' + globalFilterValue.key}
                                onClick={()=>{onChangeGlobalFilter(globalFilterValue.key);}}
                                text={getShortString(globalFilterValue.text)} />) : <Dropdown.Header icon='info' content={t('there are no others')} />}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={e => {
                        e.stopPropagation();
                        setDictionaryItemEditModalData(undefined);
                        setDictionaryItemEditModalOpen(true);
                    }} icon='plus' text={t('Create')} />

                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item></>: '';
    return <><header style={configuration.info.leftMenu ? {height: '0px'} : null}>
        <div className={'for-mobile'}>
            {mobileMenuIsOpen ? '' : <div className={'open-menu-btn'}><Icon size={'big'} onClick={()=>{setMobileMenuIsOpen(true)}} name={'bars'}/></div>}
            {mobileMenuIsOpen ? <div className={'placeholder-background'} style={{height: height + 'px'}} onClick={()=>{setMobileMenuIsOpen(false)}}/> : ''}
            {mobileMenuIsOpen ? <div className={'menu'}>
                <Menu pointing secondary vertical>
                    {configuration.info.logoUrl !== '' ? <Menu.Item as={Link} to={(dictionaries[0] !== undefined ? `/${dictionaries[0].url}` : "/")} onClick={()=>{
                            setActiveItem(dictionaries[0].url);
                            setMobileMenuIsOpen(false);
                        }} header className={'main-company-logo'}><img src={appConfig().info.logoUrl} title={projectName} alt={projectName} /></Menu.Item>:
                        <div className={'project-name-container'}><h2>{projectName}</h2></div>}

                    <Menu.Menu position='right'>
                        { userRole === 'Administrator' ? <AdministratorMenuItem
                            setActiveItem={(val) => {setActiveItem(val); setMobileMenuIsOpen(false);}}
                            isActive={activeItem === 'NotificationViewForAdministrator'}
                        /> : ''}

                        {haveKnowledgeBase ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'knowledgeBase-menu-item'}
                            as={Link}
                            to={`/knowledgeBase`}
                            name={'KnowledgeBase'}
                            active={activeItem.indexOf('knowledgeBase') > -1}
                            onClick={()=>{
                                setActiveItem('knowledgeBase');
                                setMobileMenuIsOpen(false);
                            }}>
                            <Icon aria-label={'KnowledgeBase'} name={'book'}/>
                        </Menu.Item>} content={t("knowledgebase_menu_link_title")} basic /> : ''}
                        {haveMeets ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'meets-menu-item'}
                            as={Link}
                            to={`/meets`}
                            name={'Meets'}
                            active={activeItem.indexOf('meets') > -1}
                            onClick={()=>{
                                setActiveItem('meets');
                                setMobileMenuIsOpen(false);
                            }}>
                            <Icon aria-label={'Meets'} name={'video camera'}/>
                        </Menu.Item>} content={t("meets_menu_link_title")} basic /> : ''}
                        {haveEmailClient ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'emailClient-menu-item'}
                            as={Link}
                            to={`/emailClient`}
                            name={'EmailClient'}
                            active={activeItem.indexOf('emailClient') > -1}
                            onClick={()=>{
                                setActiveItem('emailClient');
                                setMobileMenuIsOpen(false);
                            }}>
                            <Icon aria-label={'EmailClient'} name={'mail'}/>
                        </Menu.Item>} content={t("email_menu_link_title")} basic /> : ''}
                        {haveCalendar ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'calendar-menu-item'}
                            as={Link}
                            to={`/calendar`}
                            name={'Calendar'}
                            active={activeItem.indexOf('calendar') > -1}
                            onClick={()=>{
                                setActiveItem('calendar');
                                setMobileMenuIsOpen(false);
                            }}>
                            <Icon aria-label={'Calendar'} name={'calendar alternate outline'}/>
                            {indicators.filter(x=>x.isCalendar).length > 0 ?
                                <MenuIndicator indicators={indicators.filter(x=>x.isCalendar)} /> : ''}
                        </Menu.Item>} content={t("calendar_menu_link_title")} basic /> : ''}
                        {haveChat ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'chat-menu-item'}
                            as={Link}
                            to={`/chat`}
                            name={'Chat'}
                            active={activeItem.indexOf('chat') > -1}
                            onClick={()=>{
                                setActiveItem('chat');
                                setMobileMenuIsOpen(false);
                            }}>
                            <Icon aria-label={t("chat_menu_link_title")} name={'chat'}/>
                            {indicators.filter(x=>x.isChat).length > 0 ?
                                <MenuIndicator indicators={indicators.filter(x=>x.isChat)} /> : ''}
                        </Menu.Item>} content={t("chat_menu_link_title")} basic /> : ''}
                        
                        {backgroundTasksElements}
                        <Dropdown direction={'left'} icon={<Icon name={'user'} size={'big'} color={'black'}/>} item className={'open-user-menu-btn'}>
                            <Dropdown.Menu>
                                <Dropdown.Header content={`${AuthHelper.getName()}`} />
                                <Dropdown.Header content={`${t(AuthHelper.getRole())}`} />
                                <Dropdown.Divider />
                                {helpResource !== '' && helpResource !== undefined && helpResource !== null ? <Dropdown.Item key={'help'}
                                                                                                                             onClick={e => {
                                                                                                                                 e.stopPropagation();
                                                                                                                                 window.open(helpResource, '_blank');
                                                                                                                             }}
                                >{t("help_menu_link_title")}</Dropdown.Item> : ''}
                                {viewNotificationsMenuItem ? <Dropdown.Item key={'notifications'}
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                setNotificationsModalOpen(true);
                                                                                setMobileMenuIsOpen(false);
                                                                            }}
                                >{t("notifications")}</Dropdown.Item> : ''}
                                {viewProfileMenuItem ? <Dropdown.Item key={'profile'}

                                                                      onClick={e => {
                                                                          e.stopPropagation();
                                                                          let id = AuthHelper.getId();
                                                                          setProfileModalData({Id: id});
                                                                          setProfileModalOpen(true);
                                                                          setMobileMenuIsOpen(false);
                                                                      }}
                                >{t("profile")}</Dropdown.Item> : ''}
                                {haveAboutProgram ? <Dropdown.Item key={'about_program'}
                                                                   onClick={e => {
                                                                       e.stopPropagation();
                                                                       setAboutProgramModalOpen(true);
                                                                   }}
                                >{t("about_program_menu_title")}</Dropdown.Item> : ''}
                                <Dropdown.Item onClick={logout}>{t("exit")}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {globalFilter !== null &&
                        globalFilterValues.length > 0 ? <>
                            <Menu.Item
                                className={'global-filter-menu-item'}
                                header>
                                <Dropdown
                                    className={'global-filter-action-menu-item'}
                                    icon={<Icon name={'ellipsis vertical'} size={'big'} />}
                                    direction={'left'}
                                >
                                    <Dropdown.Menu>
                                        {actionsForGlobalFilterItem.filter(x=>x.viewOnField === '').map(action =>
                                            <ActionButton
                                                for={'action'}
                                                key={'global-filter-action-menu-item' + localStorage.getItem('GlobalFilterValue') + action.name}
                                                as={'dropdown'}
                                                action={action}
                                                id={localStorage.getItem('GlobalFilterValue')}
                                                dictionaryStrictNameMany={globalFilterDictionary.strictNameMany}
                                                afterInvoke={()=>{
                                                    currentReload.load();
                                                    setMobileMenuIsOpen(false);
                                                }}
                                            />)}
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                            icon='edit'
                                            onClick={e => {
                                                e.stopPropagation();
                                                setDictionaryItemEditModalData({Id: localStorage.getItem('GlobalFilterValue')});
                                                setDictionaryItemEditModalOpen(true);
                                                setMobileMenuIsOpen(false);
                                            }}
                                            //onKeyPress={e => e.stopPropagation()}
                                            key={'edit'}  text={t('edit')} />

                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown text={getName()}>
                                    <Dropdown.Menu>
                                        {notSelectedGlobalFilterValues.length > 0 ? notSelectedGlobalFilterValues
                                            .filter(x=>x !== undefined)
                                            .map(globalFilterValue =>
                                                <Dropdown.Item
                                                    key={'GlobalFilterValueOption-' + globalFilterValue.key}
                                                    onClick={()=>{onChangeGlobalFilter(globalFilterValue.key);setMobileMenuIsOpen(false);}}
                                                    text={getShortString(globalFilterValue.text)} />) : <Dropdown.Header icon='info' content={t('there are no others')} />}
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={e => {
                                            e.stopPropagation();
                                            setDictionaryItemEditModalData(undefined);
                                            setDictionaryItemEditModalOpen(true);
                                            setMobileMenuIsOpen(false);
                                        }} icon='plus' text={t('Create')} />

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Item></>: ''}
                        {globalFilter !== null &&
                        globalFilterValues.length === 0 ? <Button

                            compact
                            color='blue'
                            onClick={e => {
                                e.stopPropagation();
                                setDictionaryItemEditModalData(undefined);
                                setDictionaryItemEditModalOpen(true);
                                setMobileMenuIsOpen(false);
                            }}>{t('Create')} {t('NameSingle_' + globalFilterDictionary.nameSingle)}</Button>: ''}
                    </Menu.Menu>

                    {globalFilterIsFillOrNotExist ?
                        dictionariesForViewInTop.map(getMenuItemForPage): ''
                    }
                    {globalFilterIsFillOrNotExist ?
                        dictionariesForViewInSecond.map(getMenuItemForPage): ''
                    }
                </Menu>
            </div> : ''}
        </div>
        <div className={'for-desktop'}>
            {
                configuration.info.leftMenu ? <LeftMenu
                projectName={projectName}
                views={views}
                pages={pages}
                userMenu={userMenu}
                globalFilterElement={globalFilterElement}
                globalFilterValues={globalFilterValues}
                indicators={indicators}
                setDictionaryItemEditModalData={setDictionaryItemEditModalData}
                setDictionaryItemEditModalOpen={setDictionaryItemEditModalOpen}
                globalFilterIsFillOrNotExist={globalFilterIsFillOrNotExist}
                />: <Menu pointing secondary >
                    {configuration.info.logoUrl !== '' ? <Menu.Item as={Link} to={(dictionaries[0] !== undefined ? `/${dictionaries[0].url}` : "/")} onClick={()=>{
                            setActiveItem(dictionaries[0].url);
                        }} header className={'main-company-logo'}><img src={appConfig().info.logoUrl} title={projectName} alt={projectName} /></Menu.Item>:
                        <div className={'project-name-container'}><h2 style={{fontSize: '20px'}}>{projectName}</h2></div>}

                    {globalFilterIsFillOrNotExist ?
                        dictionariesForViewInTop.map(getMenuItemForPage): ''
                    }

                    {dictionariesForViewInSecond.length > 0 ? <Dropdown text={getCurrentLangValue() === 'ru' ? 'Ещё' : 'More'} item>
                        <Dropdown.Menu>
                            {dictionariesForViewInSecond.map((page, index) => {
                                return getMenuItemForPage(page, index + dictionariesForViewInTop.length);
                            })}
                        </Dropdown.Menu>
                    </Dropdown>: ''}

                    <Menu.Menu position='right'>

                        { userRole === 'Administrator' ? <AdministratorMenuItem
                            setActiveItem={setActiveItem}
                            isActive={activeItem === 'NotificationViewForAdministrator'}
                        /> : ''}
                        {appConfig().info.location === 'configurator.report.ms' &&
                        (globalFilterDictionary !== null &&
                            globalFilterDictionary !== undefined) && localStorage.getItem('GlobalFilterValue') !== null ? <Menu.Item
                            className="large"
                            key={'configuration-viwer-menu-item'}
                            as={Link}
                            to={`/ConfigurationViewer`}
                            name={'ConfigurationViewer'}
                            active={activeItem.indexOf('ConfigurationViewer') > -1}
                            onClick={()=>{
                                setActiveItem('ConfigurationViewer');
                            }}>
                            <Icon aria-label={'ConfigurationViewer'} name={'sitemap'}/>
                        </Menu.Item> : ''}

                        {haveKnowledgeBase ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'knowledgeBase-menu-item'}
                            as={Link}
                            to={`/knowledgeBase`}
                            name={'KnowledgeBase'}
                            active={activeItem.indexOf('knowledgeBase') > -1}
                            onClick={()=>{
                                setActiveItem('knowledgeBase');
                            }}>
                            <Icon aria-label={'KnowledgeBase'} name={'book'}/>
                        </Menu.Item>} content={t("knowledgebase_menu_link_title")} basic /> : ''}
                        {haveMeets ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'meets-menu-item'}
                            as={Link}
                            to={`/meets`}
                            name={'Meets'}
                            active={activeItem.indexOf('meets') > -1}
                            onClick={()=>{
                                setActiveItem('meets');
                            }}>
                            <Icon aria-label={'Meets'} name={'video camera'}/>
                        </Menu.Item>} content={t("meets_menu_link_title")} basic /> : ''}
                        {haveEmailClient ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'emailClient-menu-item'}
                            as={Link}
                            to={`/emailClient`}
                            name={'EmailClient'}
                            active={activeItem.indexOf('emailClient') > -1}
                            onClick={()=>{
                                setActiveItem('emailClient');
                            }}>
                            <Icon aria-label={'EmailClient'} name={'mail'}/>
                        </Menu.Item>} content={t("email_menu_link_title")} basic /> : ''}
                        {haveCalendar ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'calendar-menu-item'}
                            as={Link}
                            to={`/calendar`}
                            name={'Calendar'}
                            active={activeItem.indexOf('calendar') > -1}
                            onClick={()=>{
                                setActiveItem('calendar');
                            }}>
                            <Icon aria-label={'Calendar'} name={'calendar alternate outline'}/>
                            {indicators.filter(x=>x.isCalendar).length > 0 ?
                                <MenuIndicator indicators={indicators.filter(x=>x.isCalendar)} /> : ''}
                        </Menu.Item>} content={t("calendar_menu_link_title")} basic /> : ''}
                        {haveChat ? <Popup trigger={<Menu.Item
                            className="large"
                            key={'chat-menu-item'}
                            as={Link}
                            to={`/chat`}
                            name={'Chat'}
                            active={activeItem.indexOf('chat') > -1}
                            onClick={()=>{
                                setActiveItem('chat');
                            }}>
                            <Icon aria-label={t("chat_menu_link_title")} name={'chat'}/>
                            {indicators.filter(x=>x.isChat).length > 0 ?
                                <MenuIndicator indicators={indicators.filter(x=>x.isChat)} /> : ''}
                        </Menu.Item>} content={'Чат'} basic /> : ''}
                        {backgroundTasksElements}
                        {helpResource !== '' && helpResource !== undefined && helpResource !== null ? <a
                            href={helpResource}
                            target={"_blank"}
                            title={t('help_menu_link_title')}
                            className={'help-menu-link'}
                        >
                            <Icon
                                name={"question circle outline"}
                                size={"large"}
                            /></a> : ''}

                        {globalFilterElement}
                        {globalFilter !== null &&
                        globalFilterValues.length === 0 ? <Button
                            style={{marginLeft: '15px'}}
                            compact
                            color='blue'
                            onClick={e => {
                                e.stopPropagation();
                                setDictionaryItemEditModalData(undefined);
                                setDictionaryItemEditModalOpen(true);
                            }}>{t('Create')} {t('NameSingle_' + globalFilterDictionary.nameSingle)}</Button>: ''}


                        {userMenu}
                    </Menu.Menu>
                </Menu>
            }
            </div>
        </header>
        {dictionaryItemEditModalOpen ? <DictionaryItemEdit
            key={'dictionaryItemEditModalOpenFromGlobalFilter'}
            onSaveEvent={(id)=>{
                onChangeGlobalFilter(id);
            }}
            onDeleteEvent={(id)=>{
                localStorage.removeItem('GlobalFilterValue');
                window.location = window.location;
            }}
            data={dictionaryItemEditModalData}
            dictionaryItemMetaDescription={globalFilterDictionary}
            isOpenProp={dictionaryItemEditModalOpen}
            isOpenPropSetter={setDictionaryItemEditModalOpen}
        >
        </DictionaryItemEdit>: ""}

        {profileModalOpen ? <DictionaryItemEdit
            onSaveEvent={(id)=>{
                window.location = window.location;
            }}
            view={{nameSingle: 'profile'}}
            data={profileModalData}
            dictionaryItemMetaDescription={userDictionary}
            isOpenProp={profileModalOpen}
            isOpenPropSetter={setProfileModalOpen}
        >
        </DictionaryItemEdit>: ""}
        {notificationsModalOpen ? <NotificationsModal
            isOpenProp={notificationsModalOpen}
            isOpenPropSetter={setNotificationsModalOpen}
        >
        </NotificationsModal>: ""}
        {aboutProgramModalOpen ? <AboutProgramModal
            isOpen={aboutProgramModalOpen}
            setIsOpen={setAboutProgramModalOpen}
        >
        </AboutProgramModal>: ""}
    
    </>
    ;
}