import {getCurrentLangValue} from "./translate";
import appConfig from "./appConfig";

export default function getTitle (isEdit, element) {

    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){

        let field = JSON.parse(element.dictionaryFieldForValue);
        let dictionaryName = field.value.split('"."')[0].substr(1);
        let fieldName = field.value.split('"."')[1];
        fieldName = fieldName.substr(0, fieldName.length - 1);
        let currentLangValue = getCurrentLangValue();

        if(!isEdit && currentLangValue === 'ru'){
            let dictionary = appConfig().dictionaries.filter(x=>x.nameMany === dictionaryName)[0];
            dictionaryName = dictionary.ruNameMany;
            //debugger;
            fieldName = fieldName.replace(' ', '');
            switch (fieldName) {
                case 'Id':
                    fieldName =  "Идентификатор";
                    break;
                case 'CreateAt':
                    fieldName =  "Дата и время создания";
                    break;
                default:
                    fieldName =  dictionary.columns.filter(x=>x.name === fieldName)[0].ruName;
                    break;
            }
        }
        let funcName = field.func;
        let distinct = field.distinct;
        switch (funcName) {
            case 'COUNT':
                funcName = currentLangValue === 'ru' ? "Количество" : 'Count';
                break;
            case 'AVG':
                funcName = currentLangValue === 'ru' ? "Среднее" : 'Average';
                break;
            case 'SUM':
                funcName = currentLangValue === 'ru' ? "Сумма" : 'Sum';
                break;
            case 'FIRST':
                funcName = currentLangValue === 'ru' ? "Первое" : 'First';
                break;
            case 'LAST':
                funcName = currentLangValue === 'ru' ? "Последнее" : 'Last';
                break;
            case 'MAX':
                funcName = currentLangValue === 'ru' ? "Максимальное" : 'Maximum';
                break;
            case 'MIN':
                funcName = currentLangValue === 'ru' ? "Минимальное" : 'Minimum';
                break;
        }
        return `${funcName === fieldName && !distinct ? '' : funcName}${distinct ? ' среди уникальных ': ''} ${fieldName} ${(currentLangValue === 'ru' ? 'из' : 'from')} ${dictionaryName}`;
    }

    return element.ruTitle;
}