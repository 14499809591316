import React, {useEffect, useState} from 'react';
import {Checkbox, Dropdown, Grid, Loader} from "semantic-ui-react";
import DateFormInput from "../../../../shared/fieldTypes/date/DateFormInput";


let getTitle = (element)=>{
    if(element.viewTitle && (element.ruTitle === undefined || element.ruTitle === null || element.ruTitle === '')){
        let field = JSON.parse(element.dictionaryFieldForFilter);
        let dictionaryName = field.value.split('"."')[0].substr(1);
        let fieldName = field.value.split('"."')[1];
        fieldName = fieldName.substr(0, fieldName.length - 1);
        return `${fieldName} из ${dictionaryName}`;
    }

    return element.ruTitle;
}

function DateReportFilter({values, filtersValues, setFiltersValues}) {
    let [from, setFrom] = useState('');
    let [to, setTo] = useState('');
    useEffect(()=>{
        let valueElementForFrom = values[0][0];
        let valueElementForTo = values[0][1];
        //debugger;
        setFrom(valueElementForFrom);
        setTo(valueElementForTo);
    }, values);
    return <div className={'ui form'}>
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    От
                    <DateFormInput
                    name={'from'}
                    value={from}
                    column={{}}
                    onChangeFieldValue={(name, val)=>{setFrom(val);}}
                /></Grid.Column>
                <Grid.Column width={8}>
                    До
                    <DateFormInput
                    name={'to'}
                    value={to}
                    column={{}}
                    onChangeFieldValue={(name, val)=>{setTo(val);}}
                /></Grid.Column>
            </Grid.Row>
        </Grid>
        
    </div>;
}

const FilterReportElement = ({getData, 
                                 pageIndex, 
                                 elementIndex, 
                                 element, 
                                 width, 
                                 height, 
                                 onePercentInPixelForWidth, 
                                 onePercentInPixelForHeight,
                                 filtersValues,
                                 setFiltersValues
                             }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [values, setValues] = useState([]);

    useEffect(() => {
        if(values.length === 0)
            getData(pageIndex, elementIndex, filtersValues).then(values => {
                
                setValues(values);
                setIsLoading(false);
            });
    }, []);

    let valueFontHeight = 3.2 * onePercentInPixelForWidth;
    let labelFontHeight = onePercentInPixelForWidth;
    let paddingTop = element.isExpand ? 10 : (height - valueFontHeight*2)/2/* + 11*/;
    let getValueForSelectText = (x) => {
        return (x[0] === '' || x[0] === null || x[0] === undefined) ? '<Пусто>' : x[1];
    }
    
    let dictionaryFieldForFilter = JSON.parse(element.dictionaryFieldForFilter);
    
    return (<div>
        {!isLoading ? <div style={{paddingLeft: '10px' , textAlign: 'left', fontSize: labelFontHeight + 'px', paddingTop: (paddingTop) + 'px'}}>{getTitle(element)}</div> : ''}
        {isLoading ? <Loader active inline size='massive'></Loader> : (dictionaryFieldForFilter.fieldType == 'Date' || dictionaryFieldForFilter.fieldType == 'DateTime' ? <DateReportFilter values={values} filtersValues={filtersValues} setFiltersValues={setFiltersValues} /> : (element.isExpand ? <ul className={'filter-values'} style={{height: (height - 45) + 'px', overflowY:"auto", paddingLeft: '10px'}}>
            {values.map(value=> {
                let checked = filtersValues.filter(x => x.PageIndex === pageIndex && x.ElementIndex === elementIndex).length > 0 &&
                    filtersValues.filter(x => x.PageIndex === pageIndex && x.ElementIndex === elementIndex)[0].Values.filter(x => x === value[0]).length > 0;

                return <li style={{marginTop: '3px', textAlign: 'left', listStyleType: 'none'}}><Checkbox
                    checked={checked} onChange={(e, val) => {
                    let temp = filtersValues.map(x => x);
                    let filterValue;
                    //debugger;
                    if (temp.filter(x => x.PageIndex === pageIndex && x.ElementIndex === elementIndex).length === 0) {
                        //debugger;
                        filterValue = {
                            PageIndex: pageIndex,
                            ElementIndex: elementIndex,
                            DictionaryName: dictionaryFieldForFilter.dictionaryName,
                            FieldName: dictionaryFieldForFilter.value.split('"."')[1].replace('"', ''),
                            DictionaryFilterName: dictionaryFieldForFilter.value.split('"."')[0].replace('"', ''),
                            Values: []
                        };
                        temp.push(filterValue);
                    } else {
                        filterValue = temp.filter(x => x.PageIndex === pageIndex && x.ElementIndex === elementIndex)[0];
                    }

                    let currentValues = filterValue.Values;
                    if (currentValues.filter(x => x === value[0]).length === 0) {
                        currentValues.push(value[0]);
                    } else {
                        filterValue.Values = filterValue.Values.filter(x => x !== value[0]);
                    }
                    if(filterValue.Values.length === 0){
                        temp = temp.filter(x => !(x.PageIndex === pageIndex && x.ElementIndex === elementIndex));
                    }

                    setFiltersValues(temp);
                }} label={getValueForSelectText(value)}/></li>;
            })}</ul> : <Dropdown
            style={{minWidth: 'calc(100% - 10px)' , width:'calc(100% - 10px)', marginLeft: '10px', marginTop: '5px'}}
            placeholder='Все'
            multiple={element.isMultiSelect}
            selection
            options={values.map(x=> {
                let newVar = getValueForSelectText(x);

                return {key: x[0], text: newVar, value: x[0]};
            })}/>))}
    </div>);
}

export default FilterReportElement