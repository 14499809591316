import React from 'react';
import './ConnectToAnotherApplication.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const ConnectToAnotherApplication = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'connect-to-another-application'}>Связь с другими приложениями</Header>
        <p>
            Если вы разрабатываете систему состоящую из нескольких приложений, то вы можете иметь доступ к ним из фоновых задач.
        </p>
        <p>
            Создайте объект <b>another_app</b> класса <b>App</b>. Вызовете метод <b>set_token_by_login_password</b> с указанием логина и пароля пользователя с правами администратора в целевой системе.
        </p>
        <p>
            Используйте объект <b>another_app</b> так-же как описано выше. 
        </p>
        <CodeExample title={'Создание объекта приложения'}
                     code={'another_app = App(\'https://another.app.domain.com\')\nanother_app.set_token_by_login_password(\'adminLogin\', \'adminPassword\')'}/>
    </div>;
}

export default ConnectToAnotherApplication