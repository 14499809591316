import React, {useEffect, useState} from 'react';
import './PythonFormInput.scss'
import ReactJson from 'react-json-view'
import {Icon, Label} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import AceEditor from "react-ace";
import ace, {Ace} from 'ace-builds';
import PythonEditModal from "./components/PythonEditModal/PythonEditModal";

export default function PythonFormInput({name, value, placeholder, column, onChange, readOnly}) {
    const [openPythonEditModal, setOpenPythonEditModal] = useState(false)
    useEffect(() => {
        const langTools = ace.require('ace/ext/language_tools');
        const sqlTables = [
            { name: 'Dictionary', description: 'Получение справочника' },
            { name: 'context', description: 'Контекст выполнения действия' },
        ];

        const sqlTablesCompleter = {
                getCompletions: (
                    editor,
                    session,
                    pos,
                    prefix,
                    callback
                ) => {
                    let arr = [
                        { name: 'context', description: 'Контекст выполнения' },
                        { name: 'current_object', description: 'Текущий объект' },
                    ]
                    callback(
                        null,
                        arr.map((table) => ({
                            caption: `${table.name}: ${table.description}`,
                            value: table.name,
                            //meta: 'Table',
                        }))
                    );
            },
        };
        langTools.addCompleter(sqlTablesCompleter);
    }, []);
    
    return <div className={'python-editor-container'}>
        <AceEditor
            mode="python"
            enableBasicAutocompletion={true}
            setOptions={{ useWorker: false }}
            theme="solarized_dark"
            minLines={10}
            maxLines={50}
            width={'100%'}
            readOnly={readOnly}
            value={value}
            placeholder={placeholder}
            fontSize={16}
            onChange={x => {onChange(x)}}
        />
        <Icon 
            className={'full-screen-icon'} 
            name={'expand'}
            color={'grey'}
            onClick={()=>{setOpenPythonEditModal(true)}}
        />
        {openPythonEditModal ? <PythonEditModal 
            open={openPythonEditModal} 
            setOpen={setOpenPythonEditModal}
            code={value}
            setCode={onChange}
            readOnly={readOnly}
            placeholder={placeholder}
        /> : ''}
    </div>
}