import {Dropdown, Label} from "semantic-ui-react";
import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import api from "../../utils/api";
import './ReportExpressionEditor.css'
import RecognitionInput from "./RecognitionInput";

export function ReportExpressionEditor(props) {
    let variables = [];
    let parameters = [];

    let getRoot = (parentData) => {

        let stepsNames = ['Steps', 'ThenSteps', 'ElseSteps'];
        stepsNames.map(stepsName => {
            if(parentData !== undefined && parentData.data !== undefined && parentData.data !== null &&  parentData.data.filter(x=>x.name === stepsName).length === 1){
                let steps = parentData.data.filter(x=>x.name === stepsName)[0].value;
                steps = steps.filter(x=>x.ReportTaskStepType == 'DeclareVariable');
                for (var a in steps){
                    let name = steps[a].VariableName;
                    variables.push(name);
                }
            }
        });

        if(parentData !== undefined && parentData.parent != undefined)
            return getRoot(parentData.parent);
        else
            return parentData;
    };

    let rootParentData = getRoot(props.parentData);

    let [userFields, setUserFields] = useState([]);
    
    let [viewSuggestions, setViewSuggestions] = useState(false);
    let [isFocused, setIsFocused] = useState(false);
    let [isFocusedAfterClick, setIsFocusedAfterClick] = useState(false);
    let [suggestions, setSuggestions] = useState([]);
    
    let [value, setValue] = useState(props.value ?? '');
    let [position, setPosition] = useState({start: 0, end: 0});
    let [project, setProject] = useState(null);
    let [dictionaries, setDictionaries] = useState([]);
    let [roles, setRoles] = useState([]);
    let [systemParameters, setSystemParameters] = useState([]);
    let [enums, setEnums] = useState([]);
    let [enumValues, setEnumValues] = useState([]);
    let [fields, setFields] = useState([]);
    let [strBeforeCursor, setStrBeforeCursor] = useState(null);
    
    useEffect(()=>{

        api().getById("Projects", localStorage.getItem('GlobalFilterValue')).then(project=>{

            setUserFields(project.UserFields);
            
            return api().getListForView("Dictionaries", 0, [{
                name: "ProjectId",
                type: 'eq',
                value: project.Id
            }], 'ForAnalitic').then(resp => {
                //debugger;
                let dictionaries =resp.rows;
                /*
                * const promises = [];

                function doSomethingAsync(dictionaryForAction) {
                    return new Promise((resolve) => {
                        api().getList('DictionaryFields', 0, [{
                            name: "DictionaryId",
                            type: 'eq',
                            value: dictionaryForAction.Id
                        }])
                            .then((fields)=>{
                                resolve(fields);
                            })
                    });
                }
                for (let i = 0; i < dictionaries.length; ++i) {
                    promises.push(doSomethingAsync(dictionaries[i]));
                }
                * */

                //Promise.all(promises)
                dictionaries.map(dic=>dic.Fields.map((fields) => {
                    console.log("All done", fields);
                    var temp = [];
                    for (var i = 0; i< fields.length; i++)
                        for (var j = 0; j< fields[i].length; j++)
                            temp.push(fields[i][j]);
                    fields = temp;
                    return api().getListForView("ReportRoles", 0, [{
                        name: "ProjectId",
                        type: 'eq',
                        value: project.Id
                    }], 'ForAnalitic').then(roles => {
                        return api().getListForView("ReportEnums", 0, [{
                            name: "ProjectId",
                            type: 'eq',
                            value: project.Id
                        }], 'ForAnalitic').then(enums => {
                            const promises = [];

                            function doSomethingAsync(enum_) {
                                return new Promise((resolve) => {
                                    api().getListForView('ReportEnumValues', 0, [{
                                        name: "ReportEnumId",
                                        type: 'eq',
                                        value: enum_.Id
                                    }], 'ForAnalitic')
                                        .then((values)=>{
                                            resolve(values);
                                        })
                                });
                            }
                            for (let i = 0; i < enums.length; ++i) {
                                promises.push(doSomethingAsync(enums[i]));
                            }

                            Promise.all(promises)
                                .then((enumValues) => {



                                    /*                                        return api().getList("UserFields", 0, [{
                                                                                name: "ProjectId",
                                                                                type: 'eq',
                                                                                value: project.Id
                                                                            }]).then(userFields => {*/


                                    setProject(project);
                                    setDictionaries(dictionaries);
                                    setRoles(roles.rows.filter(x=>x.RuName !== 'Администратор'));
                                    setEnums(enums.rows);
                                    setFields(fields);
                                    var temp = [];
                                    for (var i = 0; i< enumValues.length; i++)
                                        for (var j = 0; j< enumValues[i].length; j++)
                                            temp.push(enumValues[i][j]);
                                    enumValues = temp;
                                    setEnumValues(enumValues);
                                });
                        });
                    });
                }));
            })
        });
    }, []);

    useEffect(()=>{
        if(value !== props.value)
            setValue(props.value);
    }, [props.value])
    if(props.single){
        let options = [];
        
        if(rootParentData.data.filter(x=>x.name === 'Fields').length === 1) {
            //debugger;
            let dicName = rootParentData.data.filter(x=>x.name === 'RuSingleName')[0].value;
            let parameters = rootParentData.data.filter(x=>x.name === 'Fields')[0].value;
            for (var a in parameters){
                let name = 'Объект действия ('+dicName+'): ' + parameters[a].DictionaryFieldName;
                options.push({ key: 'action-object--' + a, text: name, value:  '"' + name + '"'});
            }
        }


        if(props.parentData.data.filter(x=>x.name === 'Parameters').length === 1){
            let parameters = props.parentData.data.filter(x=>x.name === 'Parameters')[0].value;
            for (var a in parameters){
                let name = 'Параметр: ' + parameters[a].DictionaryFieldName;
                options.push({ key: 'params-' + a, text: name, value: '"' + name + '"'});
            }
        }
        
        let stepsNames = ['Steps', 'ThenSteps', 'ElseSteps'];
        stepsNames.map(stepsName => {
            if(props.parentData.data.filter(x=>x.name === stepsName).length === 1){
                let steps = props.parentData.data.filter(x=>x.name === stepsName)[0].value;
                steps = steps.filter(x=>x.ReportTaskStepType == 'DeclareVariable');
                for (var a in steps){
                    let name = 'Переменная: ' + steps[a].VariableName;
                    options.push({ key: 'variable-' + a, text: name, value: '"' + name + '"'});
                }
            }
        });

        
        for (var a in userFields){
            
            let name = 'Текущий пользователь: ' + userFields[a].Name;
            options.push({ key: 'current-user-' + a, text: name, value: '"' + name + '"'});
        }

        return <Dropdown
            clearable
            search
            options={options}
            onChange={function (e, { value }) {
                setValue(value);
                props.onChange({target: {value }});
            }}
            value={value}
            selection />
    }else{
        if(props.parentData !== undefined && props.parentData.data.filter(x=>x.name === 'Parameters').length === 1){
            let parametersData = props.parentData.data.filter(x=>x.name === 'Parameters')[0].value;
            for (var a in parametersData){
                parameters.push(parametersData[a].DictionaryFieldName);
            }
        }
    }
    
    
    let objectActionFields = [];
    let objectFilterFields = [];
    let objectSelectFields = [];
    let objectContextFields = [];
    
    let dicName;
    let contextDicName;
    if(!props.forSelectRestriction){
        if(rootParentData !== undefined && rootParentData.data.filter(x=>x.name === 'Fields').length > 0){
            //debugger;
            if(rootParentData.data.filter(x => x.name === 'RuSingleName').length > 0){
                dicName = rootParentData.data.filter(x => x.name === 'RuSingleName')[0].value;
                let parameters = rootParentData.data.filter(x=>x.name === 'Fields')[0].value;
                for (var a in parameters){
                    let name = parameters[a].DictionaryFieldName;
                    objectActionFields.push(name);
                }
            }
        }else{
            if(props.formData !== undefined && props.formData.filter(x=>x.name === 'DictionaryIdForReportFilter').length > 0){
                let dicId = props.formData.filter(x=>x.name === 'DictionaryIdForReportFilter')[0].value;
                let dic = dictionaries.filter(x=>x.Id === dicId)[0];
                if(dic !== undefined){
                    //debugger;
                    dicName = dic.RuSingleName;
                    for (var dicFieldsKey in dic.Fields){
                        objectFilterFields.push(dic.Fields[dicFieldsKey].name);
                    }
                }
            }
        }
    }else{
        if(props.formData !== undefined && props.formData.filter(x=>x.name === 'DictionaryForLinkTo').length > 0){
            let dicId = props.formData.filter(x=>x.name === 'DictionaryForLinkTo')[0].value;
            let dic = dictionaries.filter(x=>x.Id === dicId)[0];
            if(dic !== undefined){
                //debugger;
                dicName = dic.RuSingleName;
                for (var dicFieldsKey in dic.Fields){
                    objectSelectFields.push(dic.Fields[dicFieldsKey].name);
                }
            }
        }

        if(rootParentData !== undefined && rootParentData.data !== undefined && rootParentData.data.filter(x=>x.name === 'Id').length > 0){
            let dicId = rootParentData.data.filter(x=>x.name === 'Id')[0].value;
            let dic = dictionaries.filter(x=>x.Id === dicId)[0];
            if(dic !== undefined){
                //debugger;
                contextDicName = dic.RuSingleName;
                for (var dicFieldsKey in dic.Fields){
                    objectContextFields.push(dic.Fields[dicFieldsKey].name);
                }
            }
        }
    }



    function removeSelection() {
        let before = value.substring(0, position.start);
        let after = value.substring(position.end);
        //value
        setValue(before + after);
    }
    function addToInput(text) {
        //debugger;
        let startPosition = position.start;//input.current.selectionStart;
        let endPosition = position.end;//input.current.selectionEnd;
        let start = value.substring(0, startPosition);
        let end = value.substring( endPosition);
        if(start.endsWith !== undefined && start.endsWith(' ') && text.startsWith(' ')){
            text = text.trimLeft();
        }
        if(end.startsWith !== undefined && end.startsWith(' ') && text.endWith(' ')){
            text = text.trimRight();
        }
        let newString = start + text + end;
        setValue(newString);
        props.onChange({target: {value: newString}});
    }

    let operators = ['=', '!=', '+', '-', '*', '/', 'В (...)', 'НЕ В (...)', 'И', 'ИЛИ', '>','>=', '<', '<=', '(', ')'];

    function hide() {
        //if(input.current !== document.activeElement) 
            setViewSuggestions(false);
    }

    let dictionaryIdForAction;
    let dictionaryForAction;
    if(props.formData.filter(fd => fd.name === 'DictionaryId')[0] !== undefined && !props.forSelectRestriction){
        dictionaryIdForAction = props.formData.filter(fd => fd.name === 'DictionaryId')[0].value;
        dictionaryForAction = dictionaries.filter(x => x.Id === dictionaryIdForAction)[0];
    }

    let dictionaryIdForSelect;
    let dictionaryForSelect;
    if(props.formData.filter(fd => fd.name === 'DictionaryForLinkTo')[0] !== undefined && props.forSelectRestriction){
        dictionaryIdForSelect = props.formData.filter(fd => fd.name === 'DictionaryForLinkTo')[0].value;
        dictionaryForSelect = dictionaries.filter(x => x.Id === dictionaryIdForSelect)[0];
    }

    let contextDictionaryIdForSelect;
    let contextDictionaryForSelect;
    if(rootParentData?.data?.filter(fd => fd.name === 'Id')[0] !== undefined && 
        props.forSelectRestriction){
        contextDictionaryIdForSelect = rootParentData.data.filter(fd => fd.name === 'Id')[0].value;
        contextDictionaryForSelect = dictionaries.filter(x => x.Id === contextDictionaryIdForSelect)[0];
    }

    let dictionaryIdForFilter;
    let dictionaryForFilter;
    if(props.formData.filter(fd => fd.name === 'DictionaryIdForReportFilter')[0] !== undefined){
        //debugger;
        dictionaryIdForFilter = props.formData.filter(fd => fd.name === 'DictionaryIdForReportFilter')[0].value;
        dictionaryForFilter = dictionaries.filter(x => x.Id === dictionaryIdForFilter)[0];
    }
    return <>
            <RecognitionInput
                
                id={'current-expression-editor'}
                as={'textArea'}
                spellCheck={'false'}
                value={value}
                onChange={(e) =>{
                    setValue(e.target.value);
                    //debugger;
                    props.onChange(e);
                }}
                placeholder={props.placeholder}
                onChangePosition={(position) => {setPosition(position)}}
                onFocus={()=>{
                    setIsFocused(true);
                    setViewSuggestions(true)
                }}
                onBlur={() => {
                    if(window.setIsFocusedAfterClick === true) {
                        window.setIsFocusedAfterClick = false;
                        document.getElementById("current-expression-editor").focus();
                        //setTimeout(()=>{}, 100);
                    } else {
                        //debugger;
                        setIsFocused(false);

                        setTimeout(hide, 300)
                    }
                }}
            />
        
        {viewSuggestions ? <div className={'report-expression-suggestions-container'}>
            {position.start !== position.end ? <div><b>Действия:</b> <Label onClick={()=>{removeSelection();}}>Удалить выделенное</Label></div> : ''}
            
            {dictionaryForAction !== undefined ? <div><b>Объект действия ({dicName}):</b> 
                <Label onClick={()=>{addToInput(` "Объект действия (${dicName}): Идентификатор" `);}}>Идентификатор</Label>
                {objectActionFields.map(objectActionField => <Label onClick={()=>{addToInput(` "Объект действия (${dicName}): ${objectActionField}" `);}}>{objectActionField}</Label>)}</div> : ''}

            {contextDictionaryForSelect !== undefined ? <div><b>Контекстный объект ({contextDicName}):</b>
                <Label onClick={()=>{addToInput(` "Контекстный объект (${dicName}): Идентификатор" `);}}>Идентификатор</Label>
                {objectContextFields.map(field => <Label onClick={()=>{addToInput(` "Контекстный объект (${contextDicName}): ${field}" `);}}>{field}</Label>)}</div> : ''}

            {dictionaryForSelect !== undefined ? <div><b>Объект выборки ({dicName}):</b> 
                <Label onClick={()=>{addToInput(` "Объект выборки (${dicName}): Идентификатор" `);}}>Идентификатор</Label>
                {objectSelectFields.map(objectActionField => <Label onClick={()=>{addToInput(` "Объект выборки (${dicName}): ${objectActionField}" `);}}>{objectActionField}</Label>)}</div> : ''}
            
            {dictionaryForFilter !== undefined ? <div><b>Объект фильтра ({dicName}):</b> 
                <Label onClick={()=>{addToInput(` "Объект фильтра (${dicName}): Идентификатор" `);}}>Идентификатор</Label>
                {objectFilterFields.map(objectFilterField => <Label onClick={()=>{addToInput(` "Объект фильтра (${dicName}): ${objectFilterField}" `);}}>{objectFilterField}</Label>)}</div> : ''}
            
            <div><b>Операторы:</b> {operators.map(operator=>{return <Label onClick={()=>{addToInput(` ${operator} `);}}> {operator} </Label>})}
                <Label onClick={()=>{addToInput(` "" `);}}> "КАВЫЧКИ" </Label> <Label onClick={()=>{addToInput(` <ПУСТО> `);}}> ПУСТО </Label>
                 <Label onClick={()=>{addToInput(` <ИСТИНА> `);}}> ИСТИНА </Label>
                 <Label onClick={()=>{addToInput(` <ЛОЖЬ> `);}}> ЛОЖЬ </Label>
                <Label onClick={(e) => { window.setIsFocusedAfterClick = true; e.stopPropagation(); }}> Дата или время </Label>
            </div>
            <div><b>Текущий пользователь:</b> 
                <Label onClick={()=>{addToInput(' "Текущий пользователь: Идентификатор" ');}}>Идентификатор</Label>
                <Label onClick={()=>{addToInput(' "Текущий пользователь: Email" ');}}>Email</Label>
                <Label onClick={()=>{addToInput(' "Текущий пользователь: Имя" ');}}>Имя</Label>
                <Label onClick={()=>{addToInput(' "Текущий пользователь: Роль" ');}}>Роль</Label>
                <Label onClick={()=>{addToInput(' "Текущий пользователь: Хеш пароля" ');}}>Хеш пароля</Label>
                
                {userFields.map(x=><Label onClick={()=>{addToInput(` "Текущий пользователь: ${x.Name}" `);}}>{x.Name}</Label>)}</div>
            <div><b>Роли:</b> <Label onClick={()=>{addToInput(' "Роль: Администратор" ');}}>Администратор</Label> 
                {roles.map(role=><Label onClick={()=>{addToInput(` "Роль: ${role.RuName}" `);}}>{role.RuName}</Label>)}</div>
            {systemParameters.length > 0 ? <div><b>Системные параметры:</b> {systemParameters.map(role=><Label onClick={()=>{addToInput(` "Системный параметр"."${role.RuName}" `);}}>{role.RuName}</Label>)}</div>: ''}
            {parameters.length > 0 ? <div><b>Параметры:</b> {parameters.map(parameter=><Label onClick={()=>{addToInput(` "Параметр: ${parameter}" `);}}>{parameter}</Label>)}</div>: ''}
            {variables.length > 0 ? <div><b>Переменные:</b> {variables.map(variable=><Label onClick={()=>{addToInput(` "Переменная: ${variable}" `);}}>{variable}</Label>)}</div>: ''}
            {enums.map(enum_=> <div><b>Перечисление ({enum_.RuName}):</b> {enum_.Values.map(enumValue=><Label onClick={()=>{addToInput(` "${enum_.RuName}"."${enumValue.name}" `);}}>{enumValue.name}</Label>)}</div>)}
        </div>:''}
    </>;
}

ReportExpressionEditor.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    column: PropTypes.any,
    parentData: PropTypes.any,
    single: PropTypes.bool,
    forSelectRestriction: PropTypes.bool
};