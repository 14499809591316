import {Icon, Label} from "semantic-ui-react";
import t, {getCurrentLangValue} from "./translate";
import React from "react";
import reportColumnTypes from "./columnTypes";
import {getElementsFromFormattedText} from "./textHelper";
import moment from 'moment'
import getBackedUrl from "./getBackedUrl";
import appConfig from "./appConfig";
import customHumanizeDuration from "./customHumanizeDuration";

function tryBacklightForSearch(text, query) {
    if((typeof text) !== 'string')
        return text;
    if(text !== '' && query !== undefined && query !== null && query !== ''){
        if(text.toLowerCase().indexOf(query.toLowerCase()) !== -1){
            let start = text.toLowerCase().indexOf(query.toLowerCase());
            let first = text.substring(0, start);
            let queryPlace = text.substring(start, start + query.length);
            let end = text.substring(start + query.length, text.length);
            return <>{first}<span style={{backgroundColor: '#fbbd08'}}>{queryPlace}</span>{end}</>;
        }
    }
    return text;
}

export default function createColumnValueGetter(row, column, query) {
    let reportTypes = reportColumnTypes();
    console.log('!!!!!!!!!createColumnValueGetter');
    console.log(row);
    console.log(column);
    console.log(row[column.name]);
    let currentLangValue = getCurrentLangValue();
    switch (column.type) {
        case reportTypes.iconPicker:
            var iconName = row[column.name] ?? "";
            return <Icon name={iconName} />;
        case reportTypes.colorPicker:
            var color = row[column.name] ?? "";
            return <div style={{height: '30px', backgroundColor: color, overflow: 'hidden', borderRadius: '5px' , maxWidth: '70px' }}>{color === '' ? <img src={'https://static.tildacdn.com/tild6462-3132-4762-b361-383932363566/Empty.svg'} /> : ''}</div>;
        case reportTypes.calculateFieldString:
            var calculateFieldString = row[column.name] ?? ""
            return <>{getElementsFromFormattedText(calculateFieldString)}</>;
        case reportTypes.string:
            let newVar = column.name !== undefined && row !== undefined && row[column.name] !== undefined ? row[column.name] ?? null : null;
            if((typeof newVar) !== 'string')
                return '';
            newVar = tryBacklightForSearch(newVar, query);
            if(column.boldInList)
                return <span className={'nowrap'}><b>{newVar}</b></span>;
            return <span className={'nowrap'}>{newVar}</span>;
        case reportTypes.text:
            if(row[column.name] !== undefined && row[column.name] !== null && row[column.name] !== "")
                return <div style={{maxHeight: '100px', overflow: 'hidden'}}>{tryBacklightForSearch(row[column.name], query)}</div>;
            return '';
        case reportTypes.bool:
            let toString = (row[column.name])?.toString().toLowerCase() ?? '';
            return <Icon name={toString === 'true' ? "check" : "square outline"}/>;
        case reportTypes.linkToDictionary:
            let valueForLinkTo = row[column.name]?.name ?? "";
            if(column.linkTo === 'Users'){
                let userId = row[column.name]?.id ?? ""
                if(userId !== ''){
                    return <a><div className={'avatar-in-list-column'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${userId}")`}}/> {valueForLinkTo}</a>;
                }else{
                    return ''
                }
            }
            return <a>{valueForLinkTo}</a>;
        case reportTypes.arrayLinkToDictionary:
            if(row[column.name] === undefined)
                return <></>;
            return row[column.name].map(linkToDictionary => <Label key={`label-${column.name}-arrayLinkToDictionary-for-${linkToDictionary.name}`}>
                {linkToDictionary.name}
            </Label>);
        case reportTypes.innerTable:
            if(row[column.name] === undefined)
                return <></>;
            return row[column.name].map(linkToDictionary => <Label key={`label-${column.name}-innerTable-for-${linkToDictionary.name}`}>
                {linkToDictionary.name}
            </Label>);
        case reportTypes.calculateFieldEnum:
        case reportTypes.linkToEnum:
            let value = row[column.name];
            if (value == undefined || value == null || value == '') {
                return '';
            } else {
                let enumVal = appConfig().enums.filter(x=>x.nameSingle == column.linkTo)[0].values.filter(x=>x.nameSingle == value)[0];
                return <Label color={enumVal.color}>
                    {t(value)}
                </Label>;
            }
        case reportTypes.arrayLinkToEnum:
            let arrayLinkToEnumValue = row[column.name];
            if (arrayLinkToEnumValue == undefined || arrayLinkToEnumValue == null || arrayLinkToEnumValue == '' || arrayLinkToEnumValue == '[]') {
                return '';
            } else {
                arrayLinkToEnumValue = JSON.parse(arrayLinkToEnumValue)
                return arrayLinkToEnumValue
                    .map(arrayLinkToEnumValueItem=>appConfig().enums.filter(x=>x.nameSingle == column.linkTo)[0].values.filter(x=>x.nameSingle == arrayLinkToEnumValueItem)[0])
                    .map(x=> <Label color={x.color}>
                        {t(x.nameSingle)}
                    </Label>)
            }
        case reportTypes.roleSelect:
            return row[column.name] == undefined || row[column.name] == null ? '' : <Label>
                {t(row[column.name])}
            </Label>;
        case reportTypes.dateTime:
            var val = row[column.name];
            if(val === null || val === undefined || val === '' )
                return '';
            if(val.indexOf('.') !== -1 && val.indexOf('T') == -1){
                var dateFromVal = val.split(' ')[0];
                var timeFromVal = val.split(' ')[1];
                val = `${dateFromVal.split('.')[2]}-${dateFromVal.split('.')[1]}-${dateFromVal.split('.')[0]}T${timeFromVal}`;
            }
            var date = new Date(val);
            return row[column.name] == undefined || row[column.name] == null ? '' : <span  className={'nowrap'}>
                {moment(date).lang(currentLangValue).format(currentLangValue === 'ru' ? "DD.MM.yyyy HH:mm" : "MMMM D, yyyy h:mm a")}
            </span>;
        case reportTypes.date:
            var val = row[column.name];
            if(val === null || val === undefined || val === '' )
                return '';
            var date = new Date(val);
            return row[column.name] == undefined || row[column.name] == null ? '' : <span  className={'nowrap'}>
                {moment(date).lang(currentLangValue).format(currentLangValue === 'ru' ? "DD.MM.yyyy" : "MMMM D, yyyy")}
            </span>;
        case reportTypes.decimal:
            var val = row[column.name];
            if(val === null || val === undefined || val === '' )
                return '';
            var floatVal = parseFloat(val);
            return floatVal == undefined || floatVal == null ? '' : <span  className={'nowrap'}>
                {floatVal.toLocaleString(currentLangValue === 'ru' ? 'ru-RU' : 'en-US')} {column.postfix !== undefined && column.postfix !== null && column.postfix !== '' ? column.postfix :''}
            </span>;
        case reportTypes.int:
            var val = row[column.name];
            if(val === null || val === undefined || val === '' )
                return '';
            var intVal = parseInt(val);
            let localeString = intVal.toLocaleString(currentLangValue === 'ru' ? 'ru-RU' : 'en-US');
            if(column.boldInList)
                localeString =  <b>{localeString}</b>;
            return intVal == undefined || intVal == null ? '' : <span className={'nowrap'}>
                {localeString} {column.postfix !== undefined && column.postfix !== null && column.postfix !== '' ? (getCurrentLangValue() === 'ru' ? column.postfix : column.enPostfix) :''}
            </span>;
        case reportTypes.avatar:
            var val = row[column.name]
            var avatarIsEmpty = val === null || val === undefined || val === ''
            return <div className={'avatar' + (avatarIsEmpty ? ' default' : '')} style={{backgroundImage: avatarIsEmpty ?
                    `url("/img/avatar-placeholder.svg")` :
                    `url("${getBackedUrl()}/api/avatar/byFile/${JSON.parse(val).id}")`}}/>
        case reportTypes.image:
            var val = row[column.name]
            var imageIsEmpty = val === null || val === undefined || val === '' || val === '{}'
            return <div className={('image' + (imageIsEmpty ? ' default' : ''))} style={{backgroundImage: imageIsEmpty ?
                    `url("/img/image-placeholder.svg")` :
                    `url("${getBackedUrl()}/api/file/image/${JSON.parse(val).previewId}")`}} />
        case reportTypes.duration:
            var val = row[column.name]
            if(val === null || val === undefined || val === '' || val === '{}')
                return ''
            return customHumanizeDuration(val)
        case reportTypes.arrayImage:
            var val = row[column.name]
            var fileIsEmpty = val == null || val === '' || val === '[]' || JSON.parse(val).length === undefined || JSON.parse(val).length === 0
            return <div className={'images'}>
                {fileIsEmpty ? <div className={('image default')} style={{backgroundImage:
                        `url("/img/image-placeholder.svg")`}} /> : <div>
                    {JSON.parse(val).map(image => <div className={('image')} style={{backgroundImage: 
                            `url("${getBackedUrl()}/api/file/image/${image.previewId}")`}} />)}
                </div>}
            </div>
        case reportTypes.file:
            var val = row[column.name]
            if(val === null || val === undefined || val === '' || val === '{}')
                return ''
            return <Label color={'blue'}>{JSON.parse(val).name}</Label>
        default:
            if(row[column.name] !== undefined && row[column.name] !== null && row[column.name] !== "")
                return tryBacklightForSearch(row[column.name], query);
            return <></>
    }
}