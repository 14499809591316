import React from 'react';
import {getCurrentLangValue} from "../../utils/translate";
import {useWindowSize} from "../../utils/windowSize";
import getBackedUrl from "../../utils/getBackedUrl";
import appConfig from "../../utils/appConfig";
import './IFramePage.scss'

const IFramePage = ({page}) => {
    const [width, height] = useWindowSize();
    let resultHeight = appConfig().info.leftMenu ? height - 10 : height - 48;
    let resultWidth = appConfig().info.leftMenu ? width - 65 : width;
    let url = page.iframeUrl;
    if (page.iframeUseApi){
        url += `?serverUrl=${getBackedUrl()}&token=${JSON.parse(localStorage.getItem('Identity')).accessToken}`
        const globalFilterName = appConfig().info.globalFilter
        if(globalFilterName !== undefined && 
            globalFilterName !== '' && 
            globalFilterName != null){
            url += `&globalFilterValue=${localStorage.getItem('GlobalFilterValue')}`
        }
        url += `&lang=${getCurrentLangValue()}`
        if(window.user !== undefined && window.user.Id !== undefined && window.user.Id != null){
            url += `&userId=${window.user.Id}`
        }
    }
    return (<div style={{paddingTop: '3px'}}>
        <iframe src={url} className={'iframe' + (appConfig().info.leftMenu ? ' with-left-menu' : '')} style={{
            height: resultHeight + 'px',
            width: resultWidth + 'px' 
        }} allow="microphone *"/>
    </div>);
}

export default IFramePage