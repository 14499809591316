import {
    Button,
    Form,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader
} from "semantic-ui-react";
import t from "../../../../utils/translate";
import React, {useEffect, useState} from "react";
import './parametersModal.css'
import api from "../../../../utils/api";
import resolveColumnToElement from "../../../../utils/resolveColumnToElement";
import reportColumnTypes from "../../../../utils/columnTypes";
import {toast} from "react-toastify";
import appConfig from "../../../../utils/appConfig";

export default function ParametersModal({isOpen, setIsOpen}) {
    let [saveIsLoading, setSaveIsLoading] = useState(false);
    let [tasks, setTasks] = useState(null);
    let [parameters, setParameters] = useState(null);
    let [formData, setFormData] = useState([]);
    let [dictionaryItemsForSelects, setDictionaryItemsForSelects] = useState([]);
    let [enumItemsForSelects, setEnumItemsForSelects] = useState([]);
    let [isReadyForRender, setIsReadyForRender] = useState(false);
    let reportTypes = reportColumnTypes();

    function capitalize(s)
    {
        return s && s[0].toLowerCase() + s.slice(1);
    }
    
    useEffect(()=>{
        if(isOpen){
            api().getParameters().then(getParametersResp=> {
                //debugger;
                setParameters(getParametersResp.metadata);
                let arr = [];
                for(let i = 0; i < getParametersResp.metadata.length; i++){
                    arr.push({name: getParametersResp.metadata[i].name, value: getParametersResp.values[capitalize(getParametersResp.metadata[i].name)]});
                }
                setFormData(arr);

                getParametersResp.metadata.map(column => {
                    if(column.type === reportTypes.linkToDictionary || column.type === reportTypes.arrayLinkToDictionary) {
                        let filter = [];
                        api()
                            .getListForSelect(column.linkTo, 0, [], null)
                            .then((data)=> {
                                let options = data.map(x=> { return {key: x.id, text: x.name, value: x.id};});
                                dictionaryItemsForSelects.push({dictionaryName: column.linkTo, fieldName: column.name, filter: null, options: options});
                                setDictionaryItemsForSelects(dictionaryItemsForSelects);
                                let dictionaryLinkFields = getParametersResp.metadata
                                    .filter(col => col.type === reportTypes.linkToDictionary || col.type === reportTypes.arrayLinkToDictionary);
                                let dictionaryLinkFieldsWithselectsElement = dictionaryLinkFields
                                    .filter(col => dictionaryItemsForSelects.filter(z=> z.fieldName === col.name && z.dictionaryName === col.linkTo).length  !== 0);
                                let newValForIsReadyForRender = dictionaryLinkFieldsWithselectsElement
                                    .length === dictionaryLinkFields.length;
                                setIsReadyForRender(newValForIsReadyForRender);
                            })
                    }
                    if(column.type === reportTypes.linkToEnum) {
                        let enums = appConfig().enums;

                        console.log(enums);
                        console.log(column.linkTo);

                        let options = enums
                            .filter(x => x.nameSingle === column.linkTo)[0].values
                            .map(x=> {
                                let newVar = {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};
                                if(x.color !== null)
                                    newVar.label = { color: x.color, empty: true, circular: true };
                                if(x.imageUrlForLabel !== null)
                                    newVar.image = { avatar: false, src: x.imageUrlForLabel };
                                return newVar;
                            });

                        enumItemsForSelects.push({enumName: column.linkTo, options: options});
                        setEnumItemsForSelects(enumItemsForSelects);
                    }
                });
            });
        }

    }, [isOpen]);

    let save = () => {
        api().saveParameters(formData).then(()=>{
            close();
            toast.info('Параметры системы обновлены!');
        });
        
    };

    let onOpen = () => {
        setIsOpen(true);
    };
    let close = () => {
        setIsOpen(false);
    };

    let onChangeFieldValue = (name, value)=>{
        let item = formData.filter(x=>x.name === name)[0];
        if(item === undefined)
            item = {name: name, value: value}
        item.value = value === '' ? null : value;
        let newFormData = [];

        let others = formData.filter(x=>x.name !== name)

        for (let a in others)
            newFormData.push({name: others[a].name, value: others[a].value});

        newFormData.push(item);
        return setFormData(newFormData);
    };

    let getForField = (field) => {
        let value = formData.filter(x=>x.name === field.name)[0]?.value ?? '';
        let [inputElement, viewLabel] = resolveColumnToElement(field,
            false,
            value,
            reportTypes,
            onChangeFieldValue,
            formData,
            true,
            dictionaryItemsForSelects
                .filter(z=> z.fieldName === field.name && z.dictionaryName === field.linkTo)[0]?.options ?? [],
            enumItemsForSelects,
            null,
            null,
            null,
            null,//dictionaryItemMetaDescription.nameSingle + '.' + name);
            //dictionaryItemMetaDescription.nameSingle + '.' + column.description,
            setFormData);
        return inputElement;
    }

    return <Modal
        size={"tiny"}
        open={isOpen}
        onClick={e => e.stopPropagation()}
        onOpen={onOpen}
        closeIcon
        onClose={() => {
            close();
        }}
        centered={false}
    >
        <ModalHeader>Параметры</ModalHeader>
        <ModalContent>
            {isReadyForRender ? <>
                {parameters != null && parameters.length > 0 ? <Form>
                    {parameters.map(field => <Form.Field>
                        <label>{t('SystemParameters.' + field.name)}</label>
                        {getForField(field)}
                    </Form.Field>)}

                </Form> : <p>Нет доступных параметров</p>}
            </>: ''}
        </ModalContent>
        <ModalActions>
            {parameters != null && parameters.length > 0 ? <Button
                onClick={save}
                positive
                icon='checkmark'
                labelPosition='right'
                loading={saveIsLoading}
                content={t('saveChanges')}
            /> : ''}
        </ModalActions>
    </Modal>;
}