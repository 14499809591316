import React from 'react';
import resolveColor from "../../../../utils/colorHelper";
import {getElementsFromFormattedText} from "../../../../utils/textHelper";
import {getCurrentLangValue} from "../../../../utils/translate";

const TextReportElement = ({isEdit, getData, pageIndex, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight}) => {
    let color = (element.color !== undefined && element.color !== null && element.color !== '') ? element.color.toLowerCase() : 'black';
    color = resolveColor(color);
    let c = 1;
    
    switch (element.fontSize) {
        case 'Regular':
             c = 1;
             break;
        case 'SubTitle':
             c = 2;
             break;
        case 'Title':
             c = 3;
             break;
    }
    let fontHeight = onePercentInPixelForWidth * c;
    let text = '';
    if(isEdit){
        text = element.textForTextField;
    }else{
        text = (getCurrentLangValue() === 'ru' ? element.textForTextField : element.enTextForTextField);
    }
    return <div style={{fontSize:  fontHeight  + 'px', color: color, textAlign: (element.textFormat  ?? 'left').toLowerCase()}}>{getElementsFromFormattedText(text)}</div>;

}

export default TextReportElement