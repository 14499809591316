import React from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader
} from 'semantic-ui-react'
import t from '../../../utils/translate'
import './AboutProgramModal.css'
import {getElementsFromFormattedText} from "../../../utils/textHelper";

export default function AboutProgramModal({isOpen, setIsOpen}) {
    return <Modal
        size={"mini"}
        open={isOpen}
        onClick={e => e.stopPropagation()}
        onOpen={()=> setIsOpen(true)}
        closeIcon
        onClose={() => setIsOpen(false)}
        centered={true}
    >
        <ModalHeader>{t('about_program_menu_title')}</ModalHeader>
        <ModalContent>
            {getElementsFromFormattedText(t('about_program'))}
        </ModalContent>
    </Modal>;
}