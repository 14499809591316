import React from 'react';
import MindElixir from "mind-elixir";
import api from "../../utils/api";

export default class ConfigurationViewer extends React.Component {
    load() {
        let createNodeData = (project, dictionaries, roles, enums, fields) => {
            return {
                children: [{
                    id: 'roles',
                    topic: '👤 Роли',
                    children: roles.map(role => {
                        return {
                            topic: role.RuName,
                            id:  role.Id,
                            children: role.PageOrder.map(x=>{return {topic: x.name, id: x.id}})
                        };
                    })
                },{
                    id: 'dictionaries',
                    topic: '🕮 Справочники',
                    children: dictionaries.map(dictionary => {

                        return {
                            topic: dictionary.RuManyName,
                            id:  dictionary.Id,
                            children: [{
                                topic: '⸽ Поля',
                                id:  'fields_' + dictionary.Id,
                                children: dictionary.Fields.map(x=>{return {topic: x.name, id:  'dictionary_field_' + x.id};})
                            },
                                {
                                    topic: '🚀 Действия',
                                    id:  'actions_' + dictionary.Id,
                                    children: dictionary.Actions.map(x=>{return {topic: x.name, id:  'dictionary_action_' + x.Id};})
                                },
                                {
                                    topic: '👀 Представления',
                                    id:  'selects_' + dictionary.Id,
                                    children: dictionary.Views.map(x=>{return {topic: x.name, id:  'dictionary_select_' + x.Id};})
                                },
                                {
                                    topic: '📑 Вкладки',
                                    id:  'tabs_' + dictionary.Id,
                                    children: dictionary.Tabs.map(x=>{return {topic: x.name, id:  'dictionary_tab_' + x.Id};})
                                }]
                        };
                    })
                },{
                    id: 'enums',
                    topic: '⇶ Перечисления',
                    children: enums.map(enumName => {
                        return {
                            topic: enumName.RuName,
                            id:  enumName.Id,
                            children: enumName.Values.map(x=> { return {topic: x.name, id: x.id}})
                        };
                    })
                }],
                id: "root",
                root: true,
                topic: '💡 ' + project.ProjectNameRu
            };
        }        
        
        
        let initMap = (nodeData) => {

            this.ME = new MindElixir({
                el: "#map",
                direction: MindElixir.SIDE,
                data: {linkData: {},
                    nodeData:nodeData
                },
                draggable: false,
                contextMenu: false,
                toolBar: true,
                nodeMenu: false,
                keypress: false
            });
            this.ME.init();
            console.log("did mount");

        };


        api().getById("Projects", localStorage.getItem('GlobalFilterValue')).then(project=>{
            return api().getListForView("Dictionaries", 0, [{
                name: "ProjectId",
                type: 'eq',
                value: project.Id
            }], "ForAnalitic").then(dictionaries => {
                const promises = [];

                function doSomethingAsync(dictionary) {
                    return new Promise((resolve) => {
                        api().getList('DictionaryFields', 0, [{
                            name: "DictionaryId",
                            type: 'eq',
                            value: dictionary.Id
                        }])
                            .then((fields)=>{
                                resolve(fields);
                            })
                    });
                }
                for (let i = 0; i < dictionaries.rows.length; ++i) {
                    promises.push(doSomethingAsync(dictionaries.rows[i]));
                }

                Promise.all(promises)
                    .then((fields) => {
                        console.log("All done", fields);
                        var temp = [];
                        for (var i = 0; i< fields.length; i++)
                            for (var j = 0; j< fields[i].length; j++)
                                temp.push(fields[i][j]);
                        fields = temp;
                        return api().getListForView("ReportRoles", 0, [{
                            name: "ProjectId",
                            type: 'eq',
                            value: project.Id
                        }], "ForAnalitic").then(roles => {
                            return api().getListForView("ReportEnums", 0, [{
                                name: "ProjectId",
                                type: 'eq',
                                value: project.Id
                            }], "ForAnalitic").then(enums => {
                                initMap(createNodeData(project, dictionaries.rows, roles.rows, enums.rows, fields.rows));
                            });
                        });
                    });
            })
        });
    }
    
    componentDidMount() {
        this.load();
        this.props.currentReload.load = this.load; 
    }
    
    render() {
        return <div id="map" style={{ height: "calc(100% - 40px)", width: "100%" }} />;
    }
}