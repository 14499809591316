import React, {useEffect, useState} from 'react';
import './PythonEditModal.scss'
import ReactJson from 'react-json-view'
import {Button, Header, Icon, Label, Modal} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import AceEditor from "react-ace";
import ace, {Ace} from 'ace-builds';
import t from "../../../../../utils/translate";
import {useWindowSize} from "../../../../../utils/windowSize";

export default function PythonEditModal({open, setOpen, code, setCode, readOnly, placeholder}) {
    const [width, height] = useWindowSize();
    const [tempValue, setTempValue] = useState(code)
    
    const save = () => {
        setCode(tempValue)
        setOpen(false)
    }
    
    return <Modal
        className={'edit-python-code-fullscreen-modal'}
        open={open}
        onClose={() => setOpen(false)}
        size={'fullscreen'}
        centered={false}
        dimmer={{className: 'dimmer-edit-python-code-fullscreen-modal'}}
    >
        <Modal.Content>
            <AceEditor
                mode="python"
                enableBasicAutocompletion={true}
                setOptions={{ useWorker: false }}
                theme="solarized_dark"
                width={'100%'}
                readOnly={readOnly}
                value={tempValue}
                placeholder={placeholder}
                fontSize={16}
                onChange={x => {setTempValue(x)}}
                height={`${height}px`}
            />
            <div className={'actions'}>
                <Button
                    basic
                    onClick={(e) => {e.stopPropagation(); setOpen(false)}}>
                    {t(code != tempValue ? 'Cancel' : 'Close')}
                </Button>
                {code != tempValue ? <Button
                    icon={'check'}
                    primary
                    onClick={save}
                >
                    {t('saveChanges')}
                </Button> : ''}
            </div>
        </Modal.Content>
    </Modal>
}