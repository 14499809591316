import React, { useState } from 'react';
import {Icon, Input} from "semantic-ui-react";

export default function SaverPasswordFormInput({name, value, column, onChangeFieldValue}) {
    let [type, setType] = useState('password');
    return (<>
        <Input
            type={type}
            value={value}
            onChange={(e) => { return  onChangeFieldValue(name, e.target.value)}}
            placeholder={column.example} >
            <input autoComplete="new-password" />
            <Icon 
                style={{position: 'absolute', top: '8px', right: '5px'}} 
                onClick={()=>{setType(type === 'password' ? 'text' : 'password')}} 
                name={type === 'password' ? 'eye' : 'eye slash'}/>
        </Input>
    </>);
}