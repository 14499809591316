import React, {useEffect, useState} from 'react';
import './ScrollToTop.scss'

import AceEditor from "react-ace";
import {Header, Icon} from "semantic-ui-react";
import {toast} from "react-toastify";
import {getCurrentLangValue} from "../../../../utils/translate";
import {CopyToClipboard} from "react-copy-to-clipboard";

const ScrollToTop = ({title, code}) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return isVisible ? <Icon 
        className={'scroll-to-top'} 
        name={'chevron up'} 
        size={'huge'} 
        onClick={()=>{window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })}}/> : '';
}

export default ScrollToTop