import React, {useState} from 'react';
import './JsonFormInput.css'
import ReactJson from 'react-json-view'
import {Label} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import AceEditor from "react-ace";

export default function JsonFormInput({name, value, placeholder, column, onChange, readOnly}) {
    let val = value == undefined || value == null || value == '{}' || value == '' ? '{}' : value
    return readOnly ? <><ReactJson src={JSON.parse(val)} /></> : <AceEditor
        mode="json"
        setOptions={{ useWorker: false }}
        minLines={10}
        maxLines={50}
        width={'100%'}
        readOnly={readOnly}
        value={value}
        placeholder={placeholder}
        fontSize={16}
        onChange={x => {onChange(x)}}
    />
}