import {IconPicker} from "semantic-ui-react-icon-picker"
import React, { useState } from 'react'
import {Button, Icon, Modal, Popup} from "semantic-ui-react"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {getCurrentLangValue} from "../../../utils/translate";

export default function IconPickerFormInput({name, disabled, value, column, onChangeFieldValue, style={}}) {
    let [isOpen, setIsOpen] = useState(false);
    let [tempValue, setTempValue] = useState(value);
    let hasValue = (typeof value === 'string') && value !== '';
    let hasTempValue = (typeof tempValue === 'string') && tempValue !== '';
    disabled = disabled ?? false;
    return <div style={style}>
        {disabled ? <Button size={"big"} disabled icon={value} /> : hasValue ?
            <Button size={"big"} icon={value} onClick={() => {
                setTempValue(value);
                setIsOpen(true);
            }}/>   :
            <Button onClick={()=>{setTempValue(value); setIsOpen(true);}}>Установить</Button>   
        }
    <Modal
        size={"mini"}
        open={isOpen}
    >
        <Modal.Header>Установить иконку</Modal.Header>
        <Modal.Content>
            {hasTempValue ? <Popup 
                basic
                inverted
                trigger={<div 
                    onClick={()=>{}}
                    style={{textAlign: 'center', marginBottom: '20px', cursor: 'pointer'}}>
                    <CopyToClipboard onCopy={()=>{
                        toast.info(getCurrentLangValue() === 'ru' ? 'Скопировано в буфер обмена!' : 'Copied to the clipboard!');
                    }} text={tempValue}>
                        <Icon size={"massive"} name={tempValue} />
                    </CopyToClipboard></div>} 
                content={'Кликните чтобы скопировать название иконки'} /> : '' }
            <IconPicker value={(hasValue ? tempValue: '')}
                        placeholder={'Поиск по названию'}
                        onChange={(v) => { setTempValue(v);}} />
        </Modal.Content>
        <Modal.Actions>
            <Button size={"tiny"} color={"orange"} onClick={()=>{
                setIsOpen(false);
                return  onChangeFieldValue(name, null)
            }}>Сбросить</Button>
            <Button size={"tiny"} onClick={()=>{
                setIsOpen(false);
            }}>Отмена</Button>
            <Button size={"tiny"} primary onClick={()=>{
                setIsOpen(false); 
                return  onChangeFieldValue(name, tempValue)
            }}>Установить</Button>
        </Modal.Actions>
        
    </Modal>
    </div>;
}