//import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Map, Marker, MarkerLayout } from 'yandex-map-react';

import React, {useState} from 'react';

const ReportMap = ({
                      getData,
                      pageIndex,
                      elementIndex,
                      element,
                      width,
                      height,
                      onePercentInPixelForWidth,
                      onePercentInPixelForHeight
                  }) => {
    let [isLoading, setIsLoading] = useState(true);
    let [labels, setLabels] = useState([]);
    let [series, setSeries] = useState([]);

    let lat = 55.754734;
    let lon = 37.583314;
    return (
        <Map 
            width={width} 
            height={height}  
            onAPIAvailable={function () { console.log('API loaded'); }} 
            center={[55.754734, 37.583314]} 
            zoom={10}
        >
            <Marker  key={'marker_' + '1'} lat={lat} lon={lon}>
                <MarkerLayout>
                    <div style={{
                        width: '40px',
                        height: '40px',
                        overflow: 'hidden',
                        border: '1px solid orange',
                        background: '#FFF',
                        borderRadius: '50%'
                    }}>
                        <img src="http://loremflickr.com/40/40"/>
                    </div>
                </MarkerLayout>
            </Marker>
        </Map>
    );
}

export default ReportMap