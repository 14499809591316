import React, {useEffect, useState} from 'react';
import api from "../../utils/api";
import {Button, Form, Icon, Message, Popup, Tab, Table} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../../utils/translate";
import {useWindowSize} from "../../utils/windowSize";
import './PublicPage.css'

import LanguageSwitcher from "../../shared/components/LanguageSwitcher/LanguageSwitcher";
import {defineIfOneInnerTableInColumn, getMenuItemName} from "../../utils/TabHelper";
import {getElementsFromFormattedText, getForSelectRestriction} from "../../utils/textHelper";
import {calculateExpression} from "../../utils/expressionHelper";
import hash from "object-hash";
import columnValueGetter from "../../utils/columnValueGetter";
import resolveColumnToElement from "../../utils/resolveColumnToElement";
import reportColumnTypes from "../../utils/columnTypes";
import DictionaryItemEdit from "../../shared/components/DictionaryItemEdit";
import {toast} from "react-toastify";
import appConfig from "../../utils/appConfig";

const PublicPage = ({page}) => {
    let [formData, setFormData] = useState([]);
    let [isSend, setIsSend] = useState(false);
    let [dictionaryItemsForSelects, setDictionaryItemsForSelects] = useState([]);
    let reportTypes = reportColumnTypes();

    const [width, height] = useWindowSize();
    
    useEffect(()=>{
        let formDataTemp = [];
        let preselectedColumns = dictionary
            .columns.filter(x=>x.preselected);
        if(preselectedColumns.length > 0){
            for (var i = 0; i < preselectedColumns.length; i++) {
                if(formDataTemp.filter(x=>x.name === preselectedColumns[i].name).length === 0)
                {
                    let newVar;
                    if(preselectedColumns[i].type === reportTypes.bool)
                        newVar = preselectedColumns[i].type === reportTypes.bool;

                    if(preselectedColumns[i].type === reportTypes.linkToEnum){
                        let enumForCol = appConfig().enums.filter(x=>x.nameSingle === preselectedColumns[i].linkTo)[0];
                        newVar = enumForCol.values
                            .filter(z => preselectedColumns[i].excludeValues == null || !preselectedColumns[i].excludeValues.includes(z.nameSingle))
                            [0].nameSingle;
                    }
                    formDataTemp.push({name: preselectedColumns[i].name, value: newVar});
                }
            }
            setFormData(formDataTemp);
        }
    }, []);
    let resultHeight = height - 45;
    let configuration = appConfig();
    let currentLangValue = localStorage.getItem("currentLangValue") ?? 'ru';
    let projectName = currentLangValue === 'ru' ? configuration.info.nameRu : configuration.info.nameEn;
    let dictionary = configuration.dictionaries.filter(x=>x.strictNameMany === page.forDictionary)[0];
    let enumItemsForSelects = [];
    
    dictionary.columns.filter(x => x.type === reportTypes.linkToEnum).map(column => {
        let options = appConfig().enums
            .filter(x => x.nameSingle === column.linkTo)[0].values
            .map(x=> {
                let newVar = {key: x.nameSingle, text: t(x.nameSingle), value: x.nameSingle};
                if(x.color !== null)
                    newVar.label = { color: x.color, empty: true, circular: true };
                if(x.imageUrlForLabel !== null)
                    newVar.image = { avatar: false, src: x.imageUrlForLabel };
                return newVar;
            });
        enumItemsForSelects.push({enumName: column.linkTo, options: options});
    })
    let view = dictionary.views.filter(v => v.publicUrl === page.url)[0];


    let columnsWithoutHidden = dictionary.columns
        .filter(column => !column.hideInForm)
        .filter(column => {
            if(column.hideIf !== undefined && column.hideIf !== null && column.hideIf !== '') {
                var hideIf = column.hideIf;

                for (var i = 0;i < appConfig().enums.length; i++){
                    for (var j = 0;j < appConfig().enums[i].values.length; j++){
                        hideIf = hideIf.replaceAll(`"${appConfig().enums[i].nameSingle}"."${appConfig().enums[i].values[j].nameSingle}"`, `"${appConfig().enums[i].values[j].nameSingle}"`);
                    }
                }

                dictionary.columns.map(col =>
                    {
                        var fdi = formData.filter(fd => fd.name === col.name)[0];
                        hideIf = hideIf.replaceAll(col.name, fdi?.value ?? 'null');
                    }
                );

                hideIf = hideIf.replaceAll('"Id"', 'null');

                /*for (var i = 0;i < formData.length; i++){
                    hideIf = hideIf.replaceAll(formData[i].name, formData[i].value);
                }*/

                let result = false;

                try {
                    result = eval(hideIf);
                }catch (e) {
                    console.log('hideIf ERROR');
                    console.log(e);
                }
                console.log(hideIf);
                return result !== true;
            }
            return true;
        });

    let setCalculatedFields = (newFormData) => {
        dictionary
            .columns
            .filter(x=>x.type === 'calculate-field:string' || x.type === 'calculate-field:enum')
            .map(column => {
                console.log("calculate-field: " + column.name);
                let calculatedExpression = '';
                if(column.expressionForCalculate !== undefined && column.expressionForCalculate !== null && column.expressionForCalculate !== '') {
                    calculatedExpression = calculateExpression(column.expressionForCalculate, dictionary, newFormData, undefined);
                }
                let item = newFormData.filter(x=>x.name === column.name)[0];
                if(item === undefined){
                    item = {name: column.name, value: calculatedExpression};
                    newFormData.push(item);
                }else{
                    item.value = calculatedExpression;
                }
            });
    };

    let onChangeFieldValue = (name, value) => {
        let column = dictionary.columns.filter(x => x.name === name)[0];
        //toast(name + ':' + value);
        let item = formData.filter(x=>x.name === name)[0];
        if(item === undefined){
            if(column.type !== 'innerTable')
                item = {name: name, value: value}
            else
                item = {name: name, value: []}
        }
        if(column.type !== 'innerTable')
            item.value = value;
        else
            item.value.push(value);

        let newFormData = [];

        let others = formData.filter(x=>x.name !== name)

        for (let a in others)
            newFormData.push({name: others[a].name, value: others[a].value});

        newFormData.push(item);

        setCalculatedFields(newFormData);


        dictionary
            .columns
            .filter(column=> column.type === reportTypes.linkToDictionary || column.type === reportTypes.arrayLinkToDictionary)
            .map(column => {
                let overrideFilter = getForSelectRestriction(column.selectRestriction, dictionary, column.linkTo, newFormData);
                let item = dictionaryItemsForSelects.filter(item => item.fieldName === column.name && item.dictionaryName === column.linkTo)[0];

                if(item !== undefined && overrideFilter !== '' && overrideFilter !== null && overrideFilter !== item.filter){
                    api()
                        .getListForSelect(column.linkTo, 0, [], overrideFilter)
                        .then((data)=> {
                            let options = data.map(x=> { return {key: x.id, text: x.name, value: x.id};});
                            item.filter = overrideFilter;
                            item.options = options;
                            dictionaryItemsForSelects = dictionaryItemsForSelects.filter(item => !(item.fieldName === column.name && item.dictionaryName === column.linkTo));
                            dictionaryItemsForSelects.push(item);
                            setDictionaryItemsForSelects(dictionaryItemsForSelects);
                        })
                }
            });



        return setFormData(newFormData);
    };
    
    let createInputFromColumn = (column, viewLabel = true, rowIsAllBool) => {
        let name = column.name;
        let item = formData.filter(x=>x.name === name)[0];
        let value = item?.value ?? '';
        //debugger;

        let inputElement = null;
        let readOnly = false;

        if(column.readOnlyIf !== undefined && column.readOnlyIf !== null && column.readOnlyIf !== '') {
            readOnly = calculateExpression(column.readOnlyIf, dictionary, formData, undefined)
        }

        if(column.type === reportTypes.innerTable) {
            let innerTableType = window
                .configuration
                .dictionaries
                .filter(x=>x.strictNameMany === column.linkTo)[0];
            let columns = innerTableType
                .columns
                .filter(column => !column.hideInList)
                .filter(x=>x.name !== column.innerTableInfo.byFieldName);
            let prefillFieldsForModal = [{name: column.innerTableInfo.byFieldName, value: formData.filter(x=>x.name === 'Id')[0]?.value ?? ''}];
            console.log('prefillFieldsForModal: ');
            console.log(prefillFieldsForModal);
            if(value.map === undefined || value.length === 0) {
                let nameSingle = column.innerTableInfo != null && column.innerTableInfo.enName != null  ?'innerTable-' + dictionary.strictNameMany + '.' + column.name + '-fieldName' : 'innerTable-' + dictionary.strictNameMany + '.' + innerTableType.nameSingle + '-fieldName';

                inputElement = <><DictionaryItemEdit
                    parentData={{data: formData, parent: undefined}}
                    dictionaryItemMetaDescription={innerTableType}
                    view={{nameSingle}}
                    hiddenFields={[column.innerTableInfo.byFieldName]}
                    overrideSave={(data)=>{
                        onChangeFieldValue(column.name, data);
                    }}
                    /*overrideSave={(data)=>{
                        if(formData.filter(x => x.name === column.name).length > 0) {
                            let targetFormData = formData.map(x => {
                                if(x.name === column.name)
                                    x.value.push(data);
                                return x;
                            });
                            setFormData(targetFormData);
                        }else{
                            let targetFormData = formData.map(x => x);
                            targetFormData.push({name: column.name, value: [data]});
                            setFormData(targetFormData);
                        }
                    }}*/
                    prefillFields={prefillFieldsForModal}
                ><Button fluid ><Icon name='plus' />&nbsp;{t(nameSingle)}</Button></DictionaryItemEdit></>;
            }else{
                inputElement = <>
                    <div className={'innerTable-div-container'}>
                        <Table size='small' selectable>
                            <Table.Header>
                                <Table.Row key={column.name +'innerTable'}>
                                    {columns
                                        .map(col=><Table.HeaderCell
                                            key={column.linkTo + '-innerTableHead-'+col.name}>
                                            {t(innerTableType.nameSingle + '.' + col.name)}
                                        </Table.HeaderCell>)
                                    }
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {value.map !== undefined ? value.map(val => <DictionaryItemEdit
                                    parentData={{data: formData, parent: undefined}}
                                    key={'innerTable-' + innerTableType.strictNameMany + 'for-' + (val["Id"] ?? '')}
                                    dictionaryItemMetaDescription={innerTableType}
                                    data={val}
                                    overrideSave={(data)=>{
                                        //debugger;
                                        if(formData.filter(x => x.name === column.name).length > 0) {
                                            let targetFormData = formData.map(x => {
                                                if(x.name === column.name){
                                                    x.value = x.value.map(z=>{
                                                        if(z["Id"] === data["Id"])
                                                            return data;
                                                        else
                                                            return z;
                                                    });
                                                }
                                                return x;
                                            });
                                            setFormData(targetFormData);
                                        }else{
                                            let targetFormData = formData.map(x => x);
                                            targetFormData.push({name: column.name, value: [data]});
                                            setFormData(targetFormData);
                                        }
                                    }}
                                    hiddenFields={[column.innerTableInfo.byFieldName]}
                                ><Table.Row key={hash(val)} className={innerTableType.backlights.length > 0 && calculateExpression(innerTableType.backlights[0].condition, innerTableType, [], val) ? 'backgorund-for-row-' + innerTableType.backlights[0].color : ''}>
                                    {columns
                                        .map(col => <Table.Cell
                                            key={column.linkTo + '-innerTableCell-'+col.name + hash(val)}>
                                            {columnValueGetter(val, col)}
                                        </Table.Cell>)
                                    }
                                    <Table.Cell>
                                    </Table.Cell>
                                </Table.Row></DictionaryItemEdit>): <></>}
                            </Table.Body>
                        </Table>
                    </div>
                    <DictionaryItemEdit
                        parentData={{data: formData, parent: undefined}}
                        dictionaryItemMetaDescription={innerTableType}
                        hiddenFields={[column.innerTableInfo.byFieldName]}
                        overrideSave={(data)=>{
                            onChangeFieldValue(column.name, data);
                        }}
                        prefillFields={prefillFieldsForModal}
                    ><Button >{t("add")}</Button></DictionaryItemEdit>
                </>
            }
        } else {
            [inputElement, viewLabel] = resolveColumnToElement(column,
                readOnly,
                value,
                reportTypes,
                onChangeFieldValue,
                formData,
                viewLabel,
                dictionaryItemsForSelects,
                enumItemsForSelects,
                undefined,
                null,
                dictionary.nameSingle + '.' + name,
                //dictionaryItemMetaDescription.nameSingle + '.' + name);
                dictionary.nameSingle + '.' + column.description,
                setFormData);
        }

        return <Form.Field
            readOnly={readOnly}
            key={'form-field-' + name}
            width={column.size}
            className={(!rowIsAllBool && column.type === reportTypes.bool) ? 'padding-top-30' : ''}
        >
            {viewLabel ? (column.description !== '' ?    <><label>{t(dictionary.nameSingle + '.' + name)}</label><Popup
                position={"top center"}
                trigger={<Icon name={"info circle"}/>}
                on='click'
                content={getElementsFromFormattedText(t(dictionary.nameSingle + '.' + column.description))}

            /></> : <label>{t(dictionary.nameSingle + '.' + name)}</label>) : ''}
            {inputElement}
        </Form.Field>;
    };

    let getGroupedColumns = (columns) => {
        let groups = [];
        let currentGroupIndex = 0;

        columns.forEach(col=>{
            if(groups[currentGroupIndex] === undefined)
                groups[currentGroupIndex] = {
                    isNewLineExistBody: false,
                    columns: []
                };
            var totalSizeInRow = 0;
            for (var i = 0; i < groups[currentGroupIndex].columns.length; i++){
                totalSizeInRow += groups[currentGroupIndex].columns[i].size
            }
            if((totalSizeInRow + col.size) > 16 || col.isNewLine){
                currentGroupIndex++;
                groups[currentGroupIndex] = {
                    isNewLineExistBody: false,
                    columns: []
                };
                if(col.isNewLineExistBody) {
                    groups[currentGroupIndex].isNewLineExistBody = true;
                    groups[currentGroupIndex].isNewLineIcon = col.isNewLineIcon;
                    groups[currentGroupIndex].isNewLineRuTitle =  col.isNewLineRuTitle;
                    groups[currentGroupIndex].isNewLineEnTitle =  col.isNewLineEnTitle;
                    groups[currentGroupIndex].isNewLineRuText =  col.isNewLineRuText;
                    groups[currentGroupIndex].isNewLineEnText =  col.isNewLineEnText;
                }
            }
            groups[currentGroupIndex].columns.push(col);
        });
        groups.map(group=>{
            if(group.columns.every(col=>col.type === reportTypes.bool))
                group.isAllBool = true;
            else
                group.isAllBool = false;
        });
        //console.log('groups!!!!!!!!!!!!!!');
        //console.log(groups);
        return groups;
    };

    let columnsWithOutTabs = columnsWithoutHidden.filter(col => col.tabName === '');

    let formWidth = 345;
    switch (dictionary.modalSize){
        case 'fullscreen':
            formWidth = width - 100;
            break;
        case 'large':
            formWidth = 1140;
            break;
        case 'small':
            formWidth = 760;
            break;
        case 'tiny':
            formWidth = 570;
            break;
        case 'mini':
            formWidth = 345;
            break;
    }
    let sendData = ()=>{
        api()
            .sendDataFromPublicForm(page, currentLangValue, formData)
            .then(resp=>{
                if(resp.result !== 'ok')
                    toast.error(<div>{getElementsFromFormattedText(resp.message.errorMessage)}</div>);
                else
                    setIsSend(true);
            });
    };
    
    let createBtnName = (currentLangValue == 'ru' ? view.createButtonNameRu : view.createButtonNameEn);
    if(createBtnName == null || createBtnName == '')
        createBtnName = t('Create')
    let afterCreationMessage = (currentLangValue == 'ru' ? view.afterCreationTextRu : view.afterCreationTextEn) ?? t('Created');

    let infoBlocks = dictionary
        .infoBlocks
        .filter(x=> calculateExpression(x.condition, dictionary, formData, null));
    
    return (<div style={{height: (resultHeight) + 'px', width: '100%', padding: '0', margin: '0', position: 'relative'}}>
        <div className={'logo-container'}>
            {configuration.info.logoUrl !== '' ? <img src={configuration.info.logoUrl} title={projectName} alt={projectName} />:
                <h2>{projectName}</h2>}
        </div>
        <LanguageSwitcher />
        <div style={{textAlign: 'center', paddingTop: '70px'}}>
            <h2>{(currentLangValue === 'ru' ? page.nameRu: page.nameEn)}</h2>
        </div>
        {isSend ? '' : <div style={{width: formWidth + 'px', marginTop: '50px', marginLeft: ((width - formWidth)/2)}}>{infoBlocks.map(infoBlock => {
            return <Message
                icon={infoBlock.icon}
                color={infoBlock.color}
                header={t('InfoBlockName_' + infoBlock.name)}
                key={t('InfoBlockName_' + infoBlock.name)}
                content={<p>
                    {getElementsFromFormattedText(t('InfoBlockDescription_' + infoBlock.description))}
                </p>}
            />;
        })}</div>}
        {isSend ? '' : <Form style={{width: formWidth + 'px', marginTop: '50px', marginLeft: ((width - formWidth)/2) + 'px'}} autoComplete="off">
            {getGroupedColumns(columnsWithOutTabs).map((group, i) => <><>{group.isNewLineExistBody ? <div><Message
                compact
                icon={group.isNewLineIcon}
                header={(getCurrentLangValue() === 'ru' ? group.isNewLineRuTitle :  group.isNewLineEnTitle)}
                content={(getCurrentLangValue() === 'ru' ? group.isNewLineRuText :  group.isNewLineEnText)}
            /><br/></div> : ''}</><Form.Group key={"group-for-out-tabs-row-" + i}>
                {group.columns.map(col => createInputFromColumn(col, true, group.isAllBool))}
            </Form.Group></>)}
            {dictionary.tabs.length > 0 && dictionary.tabs
                .filter(tab => columnsWithoutHidden.filter(col => col.tabName === tab.nameSingle).length > 0).length > 0 ?
                <Tab menu={{ secondary: true, pointing: true }} panes={dictionary.tabs
                    .filter(tab => columnsWithoutHidden.filter(col => col.tabName === tab.nameSingle).length > 0)
                    .map(tab => {
                        return {
                            menuItem: getMenuItemName(tab, columnsWithoutHidden, formData),
                            render: () => {
                                let columnsInTab = columnsWithoutHidden.filter(col => col.tabName === tab.nameSingle);

                                return <Tab.Pane>
                                    {tab.description !== '' ?  <Message info>
                                        <p>{getElementsFromFormattedText(t(tab.description))}</p>
                                    </Message> : <></>}
                                    {getGroupedColumns(columnsInTab).map((group, i) => <><>{group.isNewLineExistBody ? <div><Message
                                        compact
                                        icon={group.isNewLineIcon}
                                        header={(getCurrentLangValue() === 'ru' ? group.isNewLineRuTitle :  group.isNewLineEnTitle)}
                                        content={(getCurrentLangValue() === 'ru' ? group.isNewLineRuText :  group.isNewLineEnText)}
                                    /><br/></div> : ''}</><Form.Group key={"group-for-in-tabs-row-" + i}>
                                        {group.columns.map(col => createInputFromColumn(col,
                                            defineIfOneInnerTableInColumn(tab, columnsWithoutHidden), group.isAllBool))}
                                    </Form.Group></>)}
                                </Tab.Pane>;
                            }
                        };
                    })}/> : ""}
        </Form>}
        {isSend ? '' : <div style={{textAlign: 'center', width: '100%', marginTop: '50px'}}>
            <Button onClick={sendData}>{createBtnName}</Button>
        </div>}
        {isSend ? <Message style={{width: formWidth + 'px', marginTop: '50px', marginLeft: ((width - formWidth)/2) + 'px'}} content={<div>{getElementsFromFormattedText(afterCreationMessage)}</div>}/> : ''}
    </div>);
}

export default PublicPage