import React from 'react';
import {Icon} from "semantic-ui-react";
import resolveColor from "../../../../utils/colorHelper";

const ShapeReportElement = ({getData, pageIndex, elementIndex, element, width, height, onePercentInPixelForWidth, onePercentInPixelForHeight}) => {
    let color = (element.color !== undefined && element.color !== null && element.color !== '') ? element.color.toLowerCase() : 'black';
    color = resolveColor(color);
    let opacity = (element.transparent === 't0p' || element.transparent === '' || element.transparent === undefined ? '1' : '0.' + (10 - parseInt(element.transparent.replace('0p', '').replace('t', ''))));
    switch (element.shapeType) {
        case 'Rectangle':
            return (<div style={{backgroundColor: color, width: width + 'px', height: height + 'px', opacity: opacity}}>
            </div>);
        case 'Triangle':
            return (<div style={{backgroundColor: color, width: width + 'px', height: height + 'px', opacity: opacity}}>
            </div>);
        case 'Ellipse':
            return (<div style={{backgroundColor: element.color, width: width + 'px', height: height + 'px', opacity: opacity}}>
            </div>);
        case 'Icon':
            let iconSize = width > height ? height : width;
            iconSize = iconSize * 0.7;
            
            return (<div style={{width: width + 'px', height: height + 'px', opacity: opacity, color: color}}>
                <Icon style={{fontSize: iconSize + 'px'}} name={element.iconForShape} />
            </div>);
    }
    
    return <></>;

}

export default ShapeReportElement