import React, {useEffect, useState} from 'react';
import './KnowledgeBase.scss'
import {
    Button,
    Grid, GridColumn,
    GridRow, Icon, Loader
} from "semantic-ui-react";
import FolderViewer from "./components/FolderViewer/FolderViewer";
import {useHistory, useParams} from "react-router-dom";
import KnowledgeBasePage from "./components/KnowledgeBasePage/KnowledgeBasePage";
import EmptyPage from "./components/EmptyPage/EmptyPage";
import api from "../../utils/api";
import NewsModal from "./components/NewsModal/NewsModal";
import SearchModal from "./components/SearchModal/SearchModal";
import appConfig from "../../utils/appConfig";

export default function KnowledgeBase() {
    const [isLoading, setIsLoading] = useState(false)
    let [unreadNewsCount, setUnreadNewsCount] = useState(0);
    const [openNewsModal, setOpenNewsModal] = useState(false)
    const [openSearchModal, setOpenSearchModal] = useState(false)
    const [lastPageModifyTime, setLastPageModifyTime] = useState(null)
    const history = useHistory();
    let {pageName} = useParams();
    
    useEffect(()=>{
        if(pageName == null){
            setIsLoading(true)
            api()
                .getStartPageForKnowledgeBase()
                .then(resp => {
                    setIsLoading(false)
                    if(resp.data != null){
                        api()
                            .getUnreadNewsCountForKnowledgeBase()
                            .then(settingsResp => {
                                setUnreadNewsCount(settingsResp.data)
                            })
                        history.push(`/knowledgeBase/${resp.data}`)
                    }
                })
        }
    }, [pageName])

    const updateUnreadNewsCount = ()=>{
        api()
            .getUnreadNewsCountForKnowledgeBase()
            .then(settingsResp => {
                setUnreadNewsCount(settingsResp.data)
            })
    };
    useEffect(updateUnreadNewsCount, [])
    useEffect(updateUnreadNewsCount, [openNewsModal])
    
    if(isLoading) return <Loader active />
    
    return (<div className={'knowledgeBase-page-container' + (appConfig().info.leftMenu ? ' with-left-menu' : '')}>
        {pageName == null ? <EmptyPage/> :
            <Grid>
                <GridRow>
                    <GridColumn width={3}>
                        <Grid>
                            <GridRow>
                                <GridColumn width={13} style={{paddingRight: '3px'}}>
                                    <Button
                                        onClick={()=>{setOpenNewsModal(true)}}
                                        icon
                                        size={'mini'}
                                        style={{marginBottom: '10px'}}
                                        fluid
                                        primary
                                    ><Icon name={'rss'}/> Новости {unreadNewsCount != 0 ? `(${unreadNewsCount})` : ''}</Button>
                                </GridColumn>
                                <GridColumn width={3} style={{paddingLeft: '3px'}}>
                                    <Button
                                        onClick={()=>{setOpenSearchModal(true)}}
                                        icon
                                        size={'mini'}
                                        style={{marginBottom: '10px'}}
                                        fluid
                                        primary
                                    ><Icon name={'search'}/></Button>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                        <FolderViewer
                            currentPageName={pageName}
                            directory={'/'}
                            lastPageModifyTime={lastPageModifyTime}
                            setLastPageModifyTime={setLastPageModifyTime}
                        />
                    </GridColumn>
                    <GridColumn width={13}>
                        <KnowledgeBasePage
                            pageName={pageName}
                            setLastPageModifyTime={setLastPageModifyTime}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>}
        <NewsModal open={openNewsModal} setOpen={setOpenNewsModal} />
        <SearchModal open={openSearchModal} setOpen={setOpenSearchModal} />
    </div>);
}