import React, {useEffect, useRef, useState} from 'react';
import './CreatePageOrFolderModal.css'
import {
    Button, ButtonGroup,
    Form,
    Icon, Message,
    Modal
} from "semantic-ui-react";
import api from "../../../../utils/api";
import {toast} from "react-toastify";

export default function CreatePageOrFolderModal({directory, open, setOpen, onCreate}) {
    let [name, setName] = useState('');
    let [directoryName, setDirectoryName] = useState('');
    let [folderOrPage, setFolderOrPage] = useState('page');
    let [isLoading, setIsLoading] = useState(false);
    
    useEffect(()=> {
        if(open){
            setName('')
            setFolderOrPage('page')
            if(directory != '/'){
                api()
                    .resolveKnowledgeBaseFolderNameByUrl(directory)
                    .then(resp => {
                        setDirectoryName(resp.data)
                    })
            }
        }
    }, [open])
    
    const create = () => {
        setIsLoading(true)
        api()
            .createPageOrFolderInKnowledgeBase(folderOrPage, name, directory)
            .then(resp => {
                setIsLoading(false)
                if(resp.error === '' || resp.error == null){
                    toast.info(`${folderOrPage == 'folder' ? 'Папка' : 'Страница'} "${name}" успешно создана!`);
                    setOpen(false)
                    onCreate(resp.data)
                }else{
                    toast.error(resp.error);
                }
            })
    }
    
    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        size={"mini"}
        onClick={e=>{
            e.preventDefault()
            e.stopPropagation()
        }}
        closeOnDimmerClick={false}
        closeIcon={true}
    >
        <Modal.Header>Новая {folderOrPage === 'folder' ? 'папка' : 'страница'}</Modal.Header>
        <Modal.Content>
            <Form>
                <ButtonGroup size={'mini'} fluid style={{marginBottom: '15px'}}>
                    <Button primary={folderOrPage==='page'} onClick={()=>{setFolderOrPage('page');}}><Icon name={'file'}/> Страница</Button>
                    <Button primary={folderOrPage==='folder'} onClick={()=>{setFolderOrPage('folder');}}><Icon name={'folder open'}/> Папка</Button>
                </ButtonGroup>
                <Form.Input required label='Название' placeholder='Введите название...'
                            value={name} onChange={e=>{setName(e.target.value)}}
                            onKeyDown={(event)=>{
                                if(event.ctrlKey === false && event.key === 'Enter'){
                                    event.preventDefault();
                                    if(name !== '')
                                        create();
                                }
                            }}
                />
                <Message 
                    icon={'folder open outline'}
                    header={'Расположение'}
                    content={<>{folderOrPage === 'folder' ? 'Папка' : 'Страница'} <b>{name == '' ? '' : `"${name}"`}</b> будет создана в {directory == '/' ? <a>корневой папке</a> : <>папке <a>{directoryName}</a></>}</>} />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)} basic>
                Отмена
            </Button>
            <Button
                loading={isLoading}
                content={"Создать"}
                disabled={name === ''}
                labelPosition='right'
                icon='checkmark'
                onClick={create}
                positive
            />
        </Modal.Actions>
    </Modal>);
}