import React from 'react';
import './DeleteChannelModal.css'
import {
    Button,
    Modal
} from "semantic-ui-react";
import api from "../../../../utils/api";

export default function DeleteChannelModal({id, open, setOpen, onDelete}) {
    return (<Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        size={"mini"}
    >
        <Modal.Header>Удалить чат</Modal.Header>
        <Modal.Content>
            Вы уверены что хотите удалить чат?
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)}>
                Отмена
            </Button>
            <Button
                content={"Удалить"}
                labelPosition='right'
                icon='trash'
                onClick={()=>{
                    api()
                        .deleteChannel(id)
                        .then(()=>{
                            onDelete();
                            setOpen(false)
                        });
                }}
                negative
            />
        </Modal.Actions>
    </Modal>);
}