import React, {useState} from 'react';
import './Structure.scss'

import {Divider, Grid, Header, Icon, Input, Message, Popup} from "semantic-ui-react";
import {toast} from "react-toastify";
import {getCurrentLangValue} from "../../../../utils/translate";
import {CopyToClipboard} from "react-copy-to-clipboard";
import DictionaryModalDescription from "../DictionaryModalDescription/DictionaryModalDescription";

const Structure = ({configuration}) => {
    const [searchByDictionary, setSearchByDictionary] = useState('')
    const [currentDictionary, setCurrentDictionary] = useState(null)
    const dictionariesWithFilter = configuration.dictionaries.filter(x=> !(['FileDownloadTokens',
        'LoginTokens',
        'Уведомления',
        'PasswordRestoreTokens',
        'Фоновые задачи',
        'События в календарях',
        'Каналы в чатах',
        'Файлы сообщений в чатах',
        'Сообщения в чатах',
        'Папки в почтах',
        'Пункты меню баз знаний',
        'Комментарии страниц баз знаний',
        'Истории страниц баз знаний',
        'Новост баз знаний',
        'Страницы баз знаний',
        'Письма',
        'Учётные данные почт',
        'Встречи',
        'Правила попадания в папки',
    ].includes(x.ruNameMany)))
        .filter(x=> {
            if(searchByDictionary == '') return true
            return x.ruNameMany.toLowerCase().indexOf(searchByDictionary.toLowerCase()) != -1
        })
    
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'structure'}>Справочники вашей системы</Header>
        <p>
            <Input 
                icon={'search'}
                iconPosition={'left'}
                style={{width: '100%'}}
                placeholder={'Поиск по справочникам'} 
                value={searchByDictionary} 
                onChange={e=> setSearchByDictionary(e.target.value)}/>
            {searchByDictionary != '' && dictionariesWithFilter.length == 0 ? <Message>Справочники содаржащие <b>{searchByDictionary}</b> не найдены.</Message> : ''}
            <ul>
                {dictionariesWithFilter
                    .map(x=><li className={'dictionary-name'}><a onClick={() => {setCurrentDictionary(x)}}>{x.ruNameMany}</a> <Popup content={<div>
                        <div>Копировать код доступа к справочнику:</div>
                        <div style={{whiteSpace: 'nowrap'}}><b>app.dictionary('{x.ruNameMany}')</b></div>
                    </div>}
                                                                                                                                     basic
                                                                                                                                     inverted
                                                                                                                                     trigger={<CopyToClipboard onCopy={()=>{
                        toast.info(getCurrentLangValue() === 'ru' ? 'Скопировано в буфер обмена!' : 'Copied to the clipboard!');
                    }} text={`app.dictionary('${x.ruNameMany}')`}>
                        <Icon
                            className={'copy-icon'}
                            name={'copy'}
                            onClick={(e)=>{
                                e.stopPropagation();
                            }}/>
                    </CopyToClipboard>}/></li>)}
            </ul>
        </p>
        <DictionaryModalDescription
            configuration={configuration}
            currentDictionary={currentDictionary} 
            setCurrentDictionary={setCurrentDictionary}/>
    </div>;
}

export default Structure