import React from 'react';
import './GanttDiagramComponent.scss'
import moment from "moment";
import {Popup} from "semantic-ui-react";

export default function GanttDiagramComponent({value, width, height}) {
    let arr = [];
    value.map(x=> x.y).map(x=> x.map(z=>arr.push(z.start)))
    value.map(x=> x.y).map(x=> x.map(z=>arr.push(z.end)))
    arr = arr.filter(x=>x != null)
    const max = Math.max(...arr)
    const min = Math.min(...arr)
    const diff = max - min
    const widthPlate = width * 2
    let stepCount = 30;
    const stepWidthInTime = diff / stepCount
    const stepWidthInPixels = widthPlate / stepCount
    return (<div className={'gantt-diagram-component'}>
        <div className={'column-header'}>
            {value.map(x => <div className={'row'}>
                <div className={'row-header'}>{x.x}</div>
            </div>)}
        </div>
        <div className={'column-data'}>
            <div className={'column-data-inner'} style={{width: `${widthPlate}px`}}>
                {value.map(x => <div className={'row'} style={{width: `${widthPlate}px`}}>
                    <div className={'lines-container'}>
                        {x.y.map(val => <Popup
                            basic
                            inverted
                            position={"top center"}
                            content={<div>
                                <div>Старт: {moment(new Date(val.start)).format('DD.MM.yyyy hh:mm')}</div>
                                <div>Завершение: {moment(new Date(val.end)).format('DD.MM.yyyy hh:mm')}</div>
                            </div>} trigger={<div
                            className={'line'}
                            style={{
                                width: `${(stepWidthInPixels * ((val.end - val.start)/stepWidthInTime))}px`,
                                left: `${(stepWidthInPixels * ((val.start - min)/stepWidthInTime))}px`
                            }}>
                            {val.title}
                        </div>}/>)}
                    </div>
                </div>)}
                <div className={'scale'}>
                    {Array.from({ length: stepCount }).map((x, i)=><div 
                        className={'item'}
                        style={{width: `${widthPlate/stepCount}px`}}
                    >
                        {i % 3 == 0 ? moment(new Date(min + i*stepWidthInTime)).format('DD.MM.yyyy hh:mm') : ''}
                    </div>)}
                </div>
            </div>
        </div>
    </div>)
}
