import React, {useRef, useState} from 'react';
import {
    Button,
    Grid
} from "semantic-ui-react";
import api from "../../../utils/api";
import t from "../../../utils/translate";
import './ImageFormInput.scss'
import getBackedUrl from "../../../utils/getBackedUrl";
import ImageViewer from "../../ImageViewer/ImageViewer";

export default function ImageFormInput({name, value, column, onChangeFieldValue, readOnly}) {
    let fileInputRef = useRef();
    const [imageViewerOpen, setImageViewerOpen] = useState(false)
    const [imageViewerSrc, setImageViewerSrc] = useState('')
    const fileIsEmpty = value == null || value === '' || JSON.parse(value).id === undefined || JSON.parse(value).previewId === undefined;
    const element = <div className={'preview-container'}>
        <div 
            className={'image'}
            onClick={() => {
                if(!fileIsEmpty){
                    setImageViewerSrc(`${getBackedUrl()}/api/file/image/${JSON.parse(value).id}`)
                    setImageViewerOpen(true)
                }
            }}
            style={{backgroundImage: fileIsEmpty ? 
                `url("/img/image-placeholder.svg")` : 
                `url("${getBackedUrl()}/api/file/image/${JSON.parse(value).previewId}")`,
            cursor: fileIsEmpty ? 'default' : 'zoom-in'
        }}/>
    </div>

    return <div className={'image-input-container'}>
        {readOnly ? element : <>
            {element}
            <Grid>
                <Grid.Row columns={fileIsEmpty ? 1 : 2}>
                    <Grid.Column><Button
                        style={{width: '100%'}}
                        content={(fileIsEmpty ? t('select_image_input_choose_an_photo') : t('select_image_input_change_the_photo'))}
                        labelPosition="left"
                        icon="photo"
                        onClick={() => fileInputRef.current.click()}
                    /></Grid.Column>
                    {fileIsEmpty ? '' : <Grid.Column><Button
                        style={{width: '100%'}}
                        content={t('select_image_input_delete_an_photo')}
                        labelPosition="left"
                        icon="remove"
                        color={'red'}
                        onClick={()=>{onChangeFieldValue(name, null)}}
                    /></Grid.Column>}
                </Grid.Row>
            </Grid>
            <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={(e) => { return api().uploadImage(e.target.files[0]).then((resp)=> {
                    onChangeFieldValue(name, JSON.stringify(resp));
                })}} />
        </>}
        <ImageViewer open={imageViewerOpen} setOpen={setImageViewerOpen} imageViewerSrc={imageViewerSrc} />
    </div>
}