import React from 'react';
import './KnowledgeNewsItemView.scss'
import getBackedUrl from "../../../../utils/getBackedUrl";

export default function KnowledgeNewsItemView({data}) {
    const getContent = (data) => {
        switch (data.type){
            case 'Comment':
                return <>
                    <b>{data.userName}</b> оставил комментарий на странице <a onClick={()=>{window.open(`/knowledgeBase/${data.pageUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${data.pageUrl}`}>{data.pageName}</a>:
                    <div>{JSON.parse(data.data).comment}</div>
                </>
            case 'Update':
                return <>
                    <b>{data.userName}</b> обновил страницу <a onClick={()=>{window.open(`/knowledgeBase/${data.pageUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${data.pageUrl}`}>{data.pageName}</a>
                </>
            case 'Create':
                let folderName = JSON.parse(data.data).folderName
                let folderUrl = JSON.parse(data.data).folderUrl
                return <>
                    <b>{data.userName}</b> создал страницу <a onClick={()=>{window.open(`/knowledgeBase/${data.pageUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${data.pageUrl}`}>{data.pageName}</a> {folderName != '' ? <>в папке <a onClick={()=>{window.open(`/knowledgeBase/${folderUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${folderUrl}`}>{folderName}</a></> : 'в корневой папке'} 
                </>
            case 'Replace':
                let oldFolderName = JSON.parse(data.data).oldFolderName
                let oldFolderUrl = JSON.parse(data.data).oldFolderName
                let newFolderName = JSON.parse(data.data).newFolderName
                let newFolderUrl = JSON.parse(data.data).newFolderName
                return <>
                    <b>{data.userName}</b> переместил страницу <a onClick={()=>{window.open(`/knowledgeBase/${data.pageUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${data.pageUrl}`}>{data.pageName}</a> из {oldFolderName != '' ? <>папки <a onClick={()=>{window.open(`/knowledgeBase/${oldFolderUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${oldFolderUrl}`}>{oldFolderName}</a></> : 'корневой папки'} в {newFolderName != '' ? <>папки <a onClick={()=>{window.open(`/knowledgeBase/${newFolderUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${newFolderUrl}`}>{newFolderName}</a></> : 'корневую папку'}  
                </>
            case 'Delete':
                let folderNameDeletedPage = JSON.parse(data.data).folderName
                let folderUrlDeletedPage = JSON.parse(data.data).folderUrl
                return <>
                    <b>{data.userName}</b> удалил страницу <a onClick={()=>{window.open(`/knowledgeBase/${data.pageUrl}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${data.pageUrl}`}>{data.pageName}</a> из {folderNameDeletedPage != '' ? <>папки <a onClick={()=>{window.open(`/knowledgeBase/${folderUrlDeletedPage}`, '_blank')}} target={'_blank'} href={`/knowledgeBase/${folderUrlDeletedPage}`}>{folderNameDeletedPage}</a></> : 'корневой папки'}
                </>
        }
        return ''
    }
    return <div className={'knowledge-news-item-view'}>
        <div className={'avatar'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${data.userId}")`}}/> 
        <div className={'content'}>{getContent(data)}</div>
    </div>
}