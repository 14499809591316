import {Button, Dropdown, Header, Icon, Modal} from "semantic-ui-react";
import t, {getCurrentLangValue} from "../../utils/translate";
import * as PropTypes from "prop-types";
import React from "react";

export function DeleteDictionaryItem(props) {
    const [open, setOpen] = React.useState(false)
    const [isAwaliableDelete, setIsAwaliableDelete] = React.useState(false)

    let trigger = props.as === 'dropdown' ? <Dropdown.Item color={'red'}>
        <Icon name={'trash'} color={'red'} />{t('Remove_it')}
    </Dropdown.Item> : <Button color='red' icon>
        <Icon name='trash' />
    </Button>;
    return <Modal
        //basic
        onClose={() => setOpen(false)}
        onOpen={() => {
            setIsAwaliableDelete(false);
            setTimeout(()=>{setIsAwaliableDelete(true)}, 2000);
            setOpen(true);
        }}
        open={open}
        size='mini'
        trigger={trigger}
    >
        <Header icon>
            <Icon name='remove' />
            {getCurrentLangValue() === 'ru' ? 'Подтвердите удаление' : 'Confirm the deletion'}
        </Header>
        <Modal.Content>
            <p>
                {getCurrentLangValue() === 'ru' ? 'Последствия удаления будут необратимы. Вы уверены что хотите удалить этот элемент справочника?' : 'The consequences of removal will be irreversible. Are you sure you want to delete this directory item?'}
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button color='green' onClick={() => setOpen(false)}>
                {getCurrentLangValue() === 'ru' ? 'Не удалять' : 'Do not delete'}
            </Button>            
            <Button 
                disabled={(!isAwaliableDelete || props.disabled)}
                loading={!isAwaliableDelete}
                color='red' 
                onClick={() => 
                {
                    props.onConfirm();
                    setOpen(false);}
                }
            >
                <Icon name='remove' /> {getCurrentLangValue() === 'ru' ? 'Удалить' : 'Delete'}
            </Button>
        </Modal.Actions>
    </Modal>;
}

DeleteDictionaryItem.propTypes = {
    onConfirm: PropTypes.func,
    as: PropTypes.any,
};