import {Button, Header, Icon, Modal, Popup} from "semantic-ui-react";
import {getCurrentLangValue} from "../../../../utils/translate";
import * as PropTypes from "prop-types";
import React from "react";

export function DeleteKnowledgeBasePageModal(props) {
    const [open, setOpen] = React.useState(false)
    const [isAwaliableDelete, setIsAwaliableDelete] = React.useState(false)

    const onClick = e => {
        e.stopPropagation()
        e.preventDefault()
        setIsAwaliableDelete(false);
        setTimeout(()=>{setIsAwaliableDelete(true)}, 2000);
        setOpen(true);
    }
    let trigger = props.as === 'icon' ? <Icon
        className={'delete-folder'}
        name={'trash alternate outline'}
        color={'red'}
        onClick={onClick}
    /> : <Button
        icon color={'red'}
        size={'mini'}
        onClick={onClick}
    ><Icon name={'trash alternate outline'}/></Button>;
    return <>
        <Popup
            basic
            inverted
            content={'Удалить'}
            trigger={trigger} />
        <Modal
        onClick={e=>{
          e.stopPropagation()
          e.preventDefault()  
        }}
        onClose={() => setOpen(false)}
        open={open}
        size='mini'
    >
        <Header icon>
            <Icon name='remove' />
            {getCurrentLangValue() === 'ru' ? 'Подтвердите удаление' : 'Confirm the deletion'}
        </Header>
        <Modal.Content>
            <p>
                {getCurrentLangValue() === 'ru' ? 'Последствия удаления будут необратимы. Вы уверены что хотите удалить этот элемент базы знаний?' : 'The consequences of removal will be irreversible. Are you sure you want to delete this knpwledge base item?'}
            </p>
        </Modal.Content>
        <Modal.Actions>
            <Button color='green' onClick={() => setOpen(false)}>
                {getCurrentLangValue() === 'ru' ? 'Не удалять' : 'Do not delete'}
            </Button>            
            <Button 
                disabled={(!isAwaliableDelete || props.disabled)}
                loading={!isAwaliableDelete}
                color='red' 
                onClick={() => 
                {
                    props.onConfirm();
                    setOpen(false);}
                }
            >
                <Icon name='remove' /> {getCurrentLangValue() === 'ru' ? 'Удалить' : 'Delete'}
            </Button>
        </Modal.Actions>
    </Modal></>;
}

DeleteKnowledgeBasePageModal.propTypes = {
    onConfirm: PropTypes.func,
    as: PropTypes.any,
};