import React, {useEffect, useState} from 'react';
import './FolderViewer.scss'
import {
    Button, Icon
} from "semantic-ui-react";
import api from "../../../../utils/api";
import { useHistory } from "react-router-dom";
import CreatePageOrFolderModal from "../CreatePageOrFolderModal/CreatePageOrFolderModal";
import InnerAdd from "./components/InnerAdd/InnerAdd";
import {DeleteKnowledgeBasePageModal} from "../DeleteKnowledgeBasePageModal/DeleteKnowledgeBasePageModal";
import {toast} from "react-toastify";
import PageMover from "./components/PageMover/PageMover";
import FolderChanger from "./components/FolderChanger/FolderChanger";
import PermissionChanger from "./components/PermissionChanger/PermissionChanger";
import appConfig from "../../../../utils/appConfig";

export default function FolderViewer({directory, currentPageName, lastPageModifyTime, setLastPageModifyTime}) {
    let [openedIndex, setOpenedIndex] = useState([]);
    let [content, setContent] = useState([]);
    const [openCreatePageOrFolderModal, setOpenCreatePageOrFolderModal] = useState(false)
    const history = useHistory();

    const load = (url)=>{
        api()
            .getKnowledgeBaseMenuItems(directory)
            .then(resp => {
                setContent(resp)
                if(url !== undefined && url != null)
                    history.push(`/knowledgeBase/${url}`)
            })
    }
    useEffect(load, [])
    useEffect(load, [lastPageModifyTime])
    
    const deletePageOrFolder = (pageName) => {
        api()
            .deleteKnowledgePage(pageName)
            .then(resp=>{
                if(resp.errorMessage === '' || resp.errorMessage == null){
                    history.push('/knowledgeBase')
                    setLastPageModifyTime(new Date())
                }
                else{
                    toast.error(resp.error)
                }
            })
    }
    
    return <>
        {content.length == 0 && directory == '/' ? '' : 
            <div className={'folder-viewer-container'} key={'folder-viewer-for-' + directory}>
                {content.length == 0 ? <div>
                    <p>В этой папке ничего нет</p>
                </div> : ''}
                {content.map((contentItem, index)=> <><div
                    className={'item' + (!contentItem.isFolder && currentPageName == contentItem.url ? ' current' : '')}
                    onClick={()=>{
                        if(contentItem.isFolder)
                            setOpenedIndex(oldOpened => {
                                if(openedIndex.includes(index)) return openedIndex.filter(x=>x !== index);
                                return [...openedIndex, index];
                            })
                        else{
                            history.push(`/knowledgeBase/${contentItem.url}`)
                        }
                    }}
                >{contentItem.isFolder ? <Icon name={'folder outline ' + (openedIndex.includes(index) ? 'open' : '')}/> : (contentItem.icon == null || contentItem.icon == '' ? <Icon name={'file ' + (currentPageName == contentItem.url ? '' : 'outline')}/>: <Icon name={contentItem.icon}/>)} {contentItem.name}
                    {appConfig().info.isKnowledgeBaseAdministrator ? <>
                        {contentItem.isFolder && openedIndex.includes(index) ? <><InnerAdd 
                            directory={contentItem.url} 
                            onCreate={url=>{
                                let oldIndex = openedIndex.map(x=>x)
                                setOpenedIndex([])
                                setTimeout(()=>{
                                    setOpenedIndex(oldIndex)
                                    history.push(`/knowledgeBase/${url}`)
                                }, 100)
                            }}
                        />
                            <DeleteKnowledgeBasePageModal as={'icon'} onConfirm={()=>{deletePageOrFolder(contentItem.url)}}/>
                        </> : ''}
                        <PermissionChanger 
                            page={contentItem} 
                            onChange={()=>{setLastPageModifyTime(new Date())}} maxIndex={Math.max(...(content.filter(x=> x.isFolder === contentItem.isFolder)).map(x=>x.index))} />
                        <FolderChanger 
                            page={contentItem} 
                            onChange={()=>{setLastPageModifyTime(new Date())}} maxIndex={Math.max(...(content.filter(x=> x.isFolder === contentItem.isFolder)).map(x=>x.index))} />
                        <PageMover 
                            page={contentItem} 
                            onMove={()=>{setLastPageModifyTime(new Date())}}
                            maxIndex={Math.max(...(content.filter(x=> x.isFolder === contentItem.isFolder)).map(x=>x.index))} />
                    </> : ''}
                </div>
                {contentItem.isFolder && openedIndex.includes(index) ? <div className={'inner-folders'}>
                    <FolderViewer currentPageName={currentPageName} directory={contentItem.url} lastPageModifyTime={lastPageModifyTime}/>
                </div> : ''}
            </>)}
        </div>}
        {appConfig().info.isKnowledgeBaseAdministrator ? <>
            {directory == '/' ? <Button
                icon
                primary
                size='tiny'
                fluid
                style={{marginTop: '10px'}}
                onClick={()=>{setOpenCreatePageOrFolderModal(true)}}
            ><Icon name='plus' /> Создать</Button> : ''}
            <CreatePageOrFolderModal
                directory={directory}
                open={openCreatePageOrFolderModal}
                setOpen={setOpenCreatePageOrFolderModal}
                onCreate={load}
            />
        </> : ''}
    </>;
}