import React, {useEffect, useState} from 'react';
import './DictionaryModalDescription.scss'

import {
    Button,
    Icon,
    Input,
    Message,
    Modal, Tab,
    Table, TableBody, TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow, TabPane
} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";
import EnumModalDescription from "../EnumModalDescription/EnumModalDescription";

const getPythonNameFromCamelCase = (name) => {
    return name
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase()
        .replace(/^_/, '');
}

const DictionaryModalDescription = ({configuration, currentDictionary, setCurrentDictionary}) => {
    const [searchByFields, setSearchByFields] = useState('')
    const [secondCurrentDictionary, setSecondCurrentDictionary] = useState(null)
    const [secondCurrentEnum, setSecondCurrentEnum] = useState(null)
    const fieldsWithFilter = currentDictionary == null ? [] : currentDictionary.columns.filter(x=> {
        if(searchByFields == '') return true
        return x.ruName.toLowerCase().indexOf(searchByFields.toLowerCase()) != -1
    })

    const getTypeName = (name, field, configuration) => {
        let dict = {
            string: "Строка",
            text: "Текст",
            linkToDictionary: "Элемент справочника",
            arrayLinkToDictionary: "Массив элементов справочника",
            arrayString: "Массив строк",
            arrayFile: "Массив файлов",
            arrayImage: "Массив картинок",
            linkToEnum: "Элемент перечисления",
            innerTable: "Таблица элементов",
            'password-hash': "Хеш пароля",
            calculateFieldEnum: "Вычисляемое поле перечисление",
            calculateFieldString: "Вычисляемое поле строка",
            roleSelect: "Выбор роли",
            dateTime: "Дата время",
            date: "Дата",
            time: "Время",//todo: need resolve
            duration: "Продолжительность",
            number: "Целое число",//todo: need resolve
            decimal: "Число с точкой",
            int: "Целое число",
            bool: "Булево значение",
            'code:sql': "Код sql",
            'code:python': "Код python",
            'code:json': "Код json",
            iconPicker: "Выбор иконки",
            file: "Файл",
            avatar: "Аватар",
            image: "Картинка",
            'saved-password': "Сохранённый пароль",
            progress: "Прогресс",

            'markdownd-editor': "Редактор markdownd",
            templateEditor: "Редактор шаблонов",//todo: need resolve
            fieldSelectorForReport: "Выбор поля для отчёта",
            fieldSelectorForReportWithoutFunctor: "Выбор поля для отчёта без функтора",
            fieldSelectorForReportDateTimeOnly: "Выбор поля для отчёта только типа дата время",
            reportExpression: "Вычисляемое значение",
            reportExpressionForSelectRestriction: "Вычисляемое значение для ограничения выборки",
            variableSelect: "Выбор переменных",
            'image-selector': "Выбор картинок",
            colorPicker: "Выбор цветов",
            reportEditor: "Редактор отчётов",
        }
        let forLinkToDictionariesTypes = ['linkToDictionary', 'arrayLinkToDictionary', 'innerTable']
        let linkTo = ''
        let result = name
        if(forLinkToDictionariesTypes.filter(x=>name.indexOf(x) != -1).length > 0){
            let dictionary = configuration.dictionaries.filter(x=>x.strictNameMany == field.linkTo)[0]
            linkTo = <>: <a onClick={()=> {setSecondCurrentDictionary(dictionary)}}>{dictionary.ruNameMany}</a></>
        }
        let forLinkToEnumsTypes = ['linkToEnum']
        if(forLinkToEnumsTypes.filter(x=>name.indexOf(x) != -1).length > 0){
            let enum_ = configuration.enums.filter(x=>x.nameSingle == field.linkTo)[0]
            linkTo = <>: <a onClick={()=> {setSecondCurrentEnum(enum_)}}>{enum_.ruNameSingle}</a></>
        }
        if(dict[name] != undefined) {
            result = dict[name]   
        }
        else {
            result = name
        }
        return <>{`${result}`}{linkTo != '' ? linkTo : ''}</>
    }
    
    useEffect(() => {
        setSearchByFields('')
    }, [currentDictionary])
    let fieldsForCreate = []
    if(currentDictionary != null)
        for (let field in currentDictionary.columns){
            fieldsForCreate.push(`\t'${currentDictionary.columns[field].ruName}': ''`)
        }
    fieldsForCreate = `{\n${fieldsForCreate.join(',\n')}\n}`
    return currentDictionary ? <><Modal
            open={currentDictionary != null}
            closeIcon
            onClose={() => {setCurrentDictionary(null)}}
            centered={false}
        >
            <Modal.Header>Детали справочника "{currentDictionary.ruNameMany}"</Modal.Header>
            <Modal.Content>
                <Tab menu={{ secondary: true, pointing: true }} panes={[
                    {
                        menuItem: 'Общее',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Получить доступ к справочнику'}
                                code={`app.dictionary(\'${currentDictionary.ruNameMany}\').count()`}/>
                        </TabPane>,
                    },
                    {
                        menuItem: 'Поля',
                        render: () => <TabPane>
                            <Input
                                style={{width: '100%'}}
                                placeholder={'Поиск по полям'}
                                value={searchByFields}
                                onChange={e=> setSearchByFields(e.target.value)}
                                icon={<Icon name={'search'}/>}
                                iconPosition={'left'}
                            />
                            {searchByFields != '' && fieldsWithFilter.length == 0 ? <Message>Справочники содаржащие <b>{searchByFields}</b> не найдены.</Message> : ''}
                            {fieldsWithFilter.length == 0 ? '' :<Table celled>
                                <TableHeader>
                                    <TableRow>
                                        <TableHeaderCell>Поле</TableHeaderCell>
                                        <TableHeaderCell>Тип</TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {fieldsWithFilter.map(x=><TableRow>
                                        <TableCell>{x.ruName}</TableCell>
                                        <TableCell>{getTypeName(x.type, x, configuration)}</TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>}
                        </TabPane>,
                    },
                    {
                        menuItem: 'Создание',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Создание'}
                                code={`${getPythonNameFromCamelCase(currentDictionary.nameSingle)} = app.dictionary(\'${currentDictionary.ruNameMany}\').create(${fieldsForCreate})`}/>
                        </TabPane>,
                    },
                    {
                        menuItem: 'Поиск',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Создание'}
                                code={`found_${getPythonNameFromCamelCase(currentDictionary.strictNameMany)} = app.dictionary(\'${currentDictionary.ruNameMany}\').find(${fieldsForCreate})`}/>
                        </TabPane>,
                    },
                    {
                        menuItem: 'Обновление',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Создание'}
                                code={`app.dictionary(\'${currentDictionary.ruNameMany}\').create(${fieldsForCreate})`}/>
                        </TabPane>,
                    },
                    {
                        menuItem: 'Удаление',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Создание'}
                                code={`app.dictionary(\'${currentDictionary.ruNameMany}\').create(${fieldsForCreate})`}/>
                        </TabPane>,
                    },
                    {
                        menuItem: 'Действия',
                        render: () => <TabPane>
                            <CodeExample
                                title={'Создание'}
                                code={`app.dictionary(\'${currentDictionary.ruNameMany}\').create(${fieldsForCreate})`}/>
                        </TabPane>,
                    },
                ]} />
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={() => {setCurrentDictionary(null)}}>Закрыть</Button>
            </Modal.Actions>
        </Modal>
        {secondCurrentDictionary == null ? '' : <DictionaryModalDescription
            configuration={configuration}
            currentDictionary={secondCurrentDictionary}
            setCurrentDictionary={setSecondCurrentDictionary}/>}
        {secondCurrentEnum == null ? '' : <EnumModalDescription
            configuration={configuration}
            currentEnum={secondCurrentEnum}
            setCurrentEnum={setSecondCurrentEnum}/>}
    </> : ''
}

export default DictionaryModalDescription