import React, {useState} from 'react';
import './FolderChanger.scss'
import {
    Icon, Popup
} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import KnowledgeBaseFolderSelector from "./components/KnowledgeBaseFolderSelector/KnowledgeBaseFolderSelector";
import {toast} from "react-toastify";

export default function FolderChanger({page, onChange, maxIndex}) {
    const [open, setOpen] = useState(false)
    
    const onSelect = (newFolder) => {
        api()
            .setNewParentForKnowledgePage(page.id, newFolder.url)
            .then(resp => {
                setOpen(false)
                toast.info('Новая папка установлена!')
                onChange()
            })
    }
    
    const getPadding = (page, maxIndex) => {
        if(maxIndex === 0)
            return ' min-padding'
        if(page.index !== 0 && page.index !== maxIndex)
            return ' max-padding'
        return ''
    }
    
    return <>
        <Popup
            basic
            inverted
            content={'Переместить в папку'}
            trigger={<Icon
                className={'folder-changer-icon' + getPadding(page, maxIndex)}
                onClick={e=>{setOpen(true)}}
                name={'folder'}
            />} />
        <KnowledgeBaseFolderSelector 
            page={page} 
            open={open} 
            setOpen={setOpen}
            onSelect={onSelect}
        />
    </>;
}