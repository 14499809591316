import {Button, Dropdown, Icon, Popup} from "semantic-ui-react";
import React, {useEffect} from "react";
import './FieldInputForReport.css'


let funcTypes = [
    {name: 'AVG', ruName: 'Средний', img: 'https://static.tildacdn.com/tild6330-6664-4061-b965-303262346438/AVG.svg', canDistinct: true, forFieldTypes: ['Number']},
    {name: 'COUNT', ruName: 'Количество', img: 'https://static.tildacdn.com/tild6162-6332-4336-b163-373036646639/COUNT.svg', canDistinct: true, forFieldTypes: ['String', 'RoleSelect', 'id', 'Number', 'Date', 'DateTime']},
    {name: 'SUM', ruName: 'Сумма', img: 'https://static.tildacdn.com/tild3337-3434-4763-a638-356637616161/SUM.svg', canDistinct: true, forFieldTypes: ['Number']},
    {name: 'MAX', ruName: 'Максимальный', img: 'https://static.tildacdn.com/tild6631-6235-4330-b461-626132376166/MAX.svg', canDistinct: false, forFieldTypes: ['Number', 'Date', 'DateTime']},
    {name: 'MIN', ruName: 'Минимальный', img: 'https://static.tildacdn.com/tild3630-3632-4065-b031-663765326130/MIN.svg', canDistinct: false, forFieldTypes: ['Number', 'Date', 'DateTime']},
    {name: 'FIRST', ruName: 'Первый', img: 'https://static.tildacdn.com/tild3436-3730-4139-b837-626262336634/FIRST.svg', canDistinct: false, forFieldTypes: ['String', 'RoleSelect', 'id', 'Number', 'Date', 'DateTime']},
    {name: 'LAST', ruName: 'Последний', img: 'https://static.tildacdn.com/tild6233-3639-4666-b464-303334396533/LAST.svg', canDistinct: false, forFieldTypes: ['String', 'RoleSelect', 'id', 'Number', 'Date', 'DateTime']},
]; 
    
export function FieldInputForReport({withFunctor, value, state, onStartFocus, onCancel, onSetFunc, onRemoveData, onHover, onLeave}) {
    //let [state, setState] = useState('new');
    
    useEffect(()=>{
    }, []);

    let getValueRow = (value)=>{
        var obj = JSON.parse(value);
        //debugger;
        let availableFuncs = funcTypes.filter(type => {return type.forFieldTypes.filter(z=>z === obj.fieldType).length > 0;});
        //debugger;
        return <Popup
            on='click'
            content={<Button onClick={()=>{onRemoveData()}}>Сбросить</Button>}
            trigger={<div className={'value-container'}><span 
                onMouseEnter={()=>{onHover(obj.value);}} 
                onMouseLeave={()=>{onLeave();}}>{obj.value}</span>
                {withFunctor ? <Dropdown className={obj.distinct ? 'distinct' : ''} icon={''}
                                         trigger={<>
                                             <img src={funcTypes.filter(x=>x.name === obj.func)[0].img}/>
                                             {obj.distinct ? <Icon name={'star outline'}/>: ''}</>}>
                    <Dropdown.Menu>
                        {availableFuncs.map(type => <Dropdown.Item
                            onClick={(e)=>{onSetFunc(type, false);}}>
                            <img src={type.img}/>
                            {type.ruName}
                            {type.canDistinct ? <Popup
                                content={'Уникальные'}
                                trigger={<Icon
                                    onClick={(e)=>{onSetFunc(type, true);e.stopPropagation()}}
                                    name={'star outline'}/>} />: ''}
                        </Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown> : ''}</div>} />;
    };
    
    //let value1 = JSON.parse(value);
    return <div className={'field-input-for-report'}>
        {state === 'new' && value === '' ? <div 
            className={'placeholder'}
            onClick={()=>{
                onStartFocus();
                //setState('focusSelectField');
            }}
        >Кликните для выбора поля</div> : ''}
        {state === 'focused' ? <div>Выберите поле <a onClick={()=>{onCancel();}}>Отменить</a></div> : ''}
        {value !== null && value !== '' && value !== undefined ? getValueRow(value) : ''}
    </div>;
}