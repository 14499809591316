import React, {useEffect, useState} from 'react';
import './Comments.scss'
import {
    Button,
    Comment, CommentAuthor,
    CommentAvatar,
    CommentContent,
    CommentGroup, CommentMetadata, CommentText, Form, Header,
    Icon, Input,
    Loader, Popup
} from "semantic-ui-react";
import api from "../../../../../../utils/api";
import {toast} from "react-toastify";
import ViewRelativeTime from "../../../../../../shared/components/ViewRelativeTime/ViewRelativeTime";
import getBackedUrl from "../../../../../../utils/getBackedUrl";


export default function Comments({pageId}) {
    const [comments, setComments] = useState([])
    const [subscribers, setSubscribers] = useState([])
    const [isCommentSubscribers, setIsCommentSubscribers] = useState(false)
    const [comment, setComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const load = () => {
        setIsLoading(true)
        api()
            .getKnowledgeBasePageComments(pageId)
            .then(resp=>{
                setComments(resp.data.comments)
                setSubscribers(resp.data.commentSubscribers)
                setIsCommentSubscribers(resp.data.userIsCommentSubscribers)
                setIsLoading(false)
            })
    };
    useEffect(load, [pageId])
    const send = () => {
        api()
            .leaveCommentOnKnowledgeBasePage(pageId, comment)
            .then(resp => {
                load()
                setComment('')
            })
    }

    if(isLoading) return <Loader active />

    const addToCommentSubscribers = () => {
        api()
            .addToSubscribers(pageId, 'comment')
            .then(resp=>{
                load()
                toast.info('Вы подписались на уведомления о новых комментариях к этой старнице')
            })
    }

    const removeFromCommentSubscribers = () => {
        api()
            .removeFromSubscribers(pageId, 'comment')
            .then(resp=>{
                load()
                toast.info('Вы отписались от уведомлений о новых комментариях к этой старнице')
            })
    }

    return <CommentGroup>
        <div style={{position: 'relative'}}>
            <Header as='h3'>
                Комментарии
            </Header>
            {subscribers.length > 0 ? <Popup
                basic
                inverted
                content={<div className={'popup-subscribers-container'}>
                    На комментарии к этой страницы подписаны:
                    <ul>
                        {subscribers.map(subscriber => <li className={'subscriber-item'}>{subscriber.name}</li>)}
                    </ul>
                </div>}
                trigger={<div className={'subscribers-container comment'}>
                    {subscribers.map(subscriber => <div className={'subscriber-item'} style={{backgroundImage: `url("${getBackedUrl()}/api/avatar/byUser/${subscriber.id}")`}}></div>)}
                    {isCommentSubscribers ? <Button onClick={removeFromCommentSubscribers} primary icon circular size={'mini'}>Отписаться</Button> : <Button onClick={addToCommentSubscribers} primary icon circular size={'mini'}><Icon name={'plus'}/></Button>}
                </div>} /> : <Button onClick={addToCommentSubscribers} primary icon circular size={'mini'}>Подписаться</Button>}
        </div>
        {comments.length > 4 ? <a><Icon name={'arrows alternate vertical'}/> Развернуть предыдуще комментарии</a> : ''}
        {comments.filter((x, i)=> i > comments.length - 5).map(comment => <Comment>
            <CommentAvatar src={`${getBackedUrl()}/api/avatar/byUser/${comment.fromId}`} />
            <CommentContent>
                <CommentAuthor as='a'>{comment.fromName}</CommentAuthor>
                <CommentMetadata>
                    <div><ViewRelativeTime dateTime={comment.createAt}/></div>
                </CommentMetadata>
                <CommentText>{comment.comment}</CommentText>
            </CommentContent>
        </Comment>)}

        <Form reply>
            <Input 
                value={comment} 
                onChange={e=>{setComment(e.target.value)}}
                style={{width: '100%'}}
                size={'large'}
                placeholder={'Введите комментарий...'}
            />
            <Button
                disabled={comment == ''}
                style={{position: 'absolute', right: '3px', top: '3px'}}
                circular
                icon='paper plane outline'
                primary
                onClick={send}
            />
        </Form>
    </CommentGroup>;
}