import React from 'react';
import './Dictionaries.scss'

import {Divider, Header} from "semantic-ui-react";
import CodeExample from "../CodeExample/CodeExample";

const Dictionaries = () => {
    return <div className={'block'}>
        <Divider />
        <Header as='h2' id={'dictionaries'}>Справочники</Header>
        <p>
            Справочники представляют собой структурированные таблицы в базе данных, содержащие ключевые данные, используемые в информационной системе. Они служат основным источником информации для различных процессов и операций, обеспечивая целостность и согласованность данных.
        </p>
        <p>
            Каждый справочник включает в себя набор атрибутов, которые описывают сущности, такие как пользователи, товары, категории и другие элементы, важные для функционирования системы.
        </p>
        <p>
            Справочники позволяют централизованно управлять данными, упрощают их обновление и обеспечивают возможность быстрого доступа к необходимой информации для пользователей и приложений.
        </p>
        <CodeExample title={'Получить количество элементов справочника'}
                     code={'users_count = app.dictionary(\'Пользователи\').count()\nprint(f\'users: {users_count}\')'}/>
        <Header as='h3' id={'dictionaries-create'}>Справочники: Создание</Header>
        <p>
            Для создания справочников в интегрированном интерпретаторе информационной системы используется специальный класс <b>Dictionary</b>. С его помощью можно легко и быстро добавлять новые записи в справочники, определяя необходимые атрибуты и их значения. Пример кода ниже демонстрирует процесс создания справочника "Пользователи" с указанием имени, электронной почты и роли пользователя.
        </p>
        <CodeExample title={'Создание нового элемента справочника'}
                     code={'new_user = app.dictionary(\'Пользователи\').create({\n\t\'Имя\': \'Имя пользователя\', \n\t\'Email\': \'example@test.com\', \n\t\'Роль\': app.role(\'Администратор\')\n})\nprint(new_user)'}/>
        <Header as='h3' id={'dictionaries-search'}>Справочники: Поиск</Header>
        <p>
            Для поиска записей в справочниках информационной системы используется метод <b>find</b> класса <b>Dictionary</b>. Этот метод позволяет находить записи по заданным критериям, таким как имя пользователя. Пример кода ниже демонстрирует процесс поиска пользователя в справочнике "Пользователи" по имени и выводит первую найденную запись.
        </p>
        <CodeExample title={'Поиск элементов справочника'}
                     code={'user = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\nprint(user)'}/>

        <Header as='h3' id={'dictionaries-action'}>Справочники: Действие</Header>
        <p>
            Для выполнения действий над записями в справочниках информационной системы можно использовать метод <b>action</b> класса <b>Dictionary</b>. Этот метод позволяет производить различные операции с конкретной записью, такие как установка пароля. Пример кода ниже демонстрирует процесс поиска пользователя в справочнике "Пользователи" по имени и выполнения действия по установке нового пароля.
        </p>
        <CodeExample title={'Выполнить действие над элементом справочника'}
                     code={'user = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\nuser.action(\'Установить пароль\', {\'Новый пароль\': \'123456\'})'}/>

        <Header as='h3' id={'dictionaries-delete'}>Справочники: Удаление</Header>
        <p>
            Для удаления записей из справочников информационной системы используется метод <b>delete</b> класса <b>Dictionary</b>. Этот метод позволяет удалять конкретные записи, найденные по заданным критериям. Пример кода ниже демонстрирует процесс поиска пользователя в справочнике "Пользователи" по имени и удаления найденной записи.
        </p>
        <CodeExample title={'Удалить элемент справочника'}
                     code={'user = app.dictionary(\'Пользователи\').find({\'Имя\': \'Имя пользователя\'})[0]\nuser.delete()'}/>
    </div>;
}

export default Dictionaries